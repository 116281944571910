<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <p class="h_title">90-{{ no }}. 법률 수집 문서</p>
        <div class="" style="text-align: center;">
          <div class="" style="display: inline-block; width:60%;">
            <h2 class="h_title" style="text-align: left;">법류 문서(한국어)</h2>

            <!-- <span>문서 기본정보</span>
            <textarea class="textareabox" v-model="doc_basic1"></textarea> -->


            <!-- table_type -->
            <div class="table_type3 mgT20">
              <table>
                <colgroup>
                  <col width="12%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_ko" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div style="width:100%">
                        <input v-if="meta.style_class == 'inputbox'" class="inputbox" v-model="meta.poi_value" readonly>
                        <textarea v-else class="textareabox2" v-model="meta.poi_value" readonly></textarea>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
          <!-- <div class="list_box">
            <h2 class="h_title" style="text-align: center;">정제</h2>
            <span>문서 기본정보</span>
            <textarea class="textareabox" v-model="doc_basic1"></textarea>
            <div class="table_type3 mgT20">
              <table>
                <colgroup>
                  <col width="12%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">판시사항</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox"
                          value="[1] 의사능력의 의미 및 의사능력 유무는 구체적인 법률행위와 관련하여 개별적으로 판단해야 하는지 여부(적극) / 의사능력이 인정되기 위해서는 법률행위의 일상적 의미뿐만 아니라 법률적 의미나 효과에 대해서도 이해할 수 있어야 하는지 여부(적극) ">
                        <input class="inputbox" value="[2] 지적장애를 가진 사람에게 의사능력이 있는지 판단하는 기준 ">
                        <input class="inputbox"
                          value="[3] 지적장애 3급의 장애인인 甲이 乙 주식회사와 체결한 굴삭기 구입자금 대출약정에 기한 대출금채무를 연체하자 乙 회사가 甲을 상대로 대출원리금의 지급을 구하는 소를 제기하였고, 이에 甲이 대출약정 당시 의사능력이 없었으므로 대출약정이 무효라고 주장한 사안에서, 제반 사정에 비추어 지적장애인인 甲이 대출약정의 법률적인 의미나 효과를 이해할 수 있었다고 보기 어려우므로, 甲은 대출약정 당시 의사능력이 없다는 이유로 대출약정은 무효라고 볼 여지가 많은데도, 이와 달리 본 원심판결에 법리오해 등의 잘못이 있다고 한 사례">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">판결요지</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox"
                          value="[1] 의사능력이란 자기 행위의 의미나 결과를 정상적인 인식력과 예기력을 바탕으로 합리적으로 판단할 수 있는 정신적 능력이나 지능을 말한다. 의사능력 유무는 구체적인 법률행위와 관련하여 개별적으로 판단해야 하고, 특히 어떤 법률행위가 일상적인 의미만을 이해해서는 알기 어려운 특별한 법률적 의미나 효과가 부여되어 있는 경우 의사능력이 인정되기 위해서는 그 행위의 일상적인 의미뿐만 아니라 법률적인 의미나 효과에 대해서도 이해할 수 있어야 한다. ">
                        <input class="inputbox" value="[2] 장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호, 장애인복지법 시행규칙 제2조 제1항 ">
                        <input class="inputbox"
                          value="[별표 1] 제6호에 따르면, 특별한 사정이 없는 한 지능지수가 70 이하인 사람은 교육을 통한 사회적·직업적 재활이 가능하더라도 지적장애인으로서 위 법령에 따른 보호의 대상이 된다. 지적장애인에 해당하는 경우에도 의학적 질병이나 신체적 이상이 드러나지 않아 사회 일반인이 보았을 때 아무런 장애가 없는 것처럼 보이는 경우가 있다. 반면 지적장애를 가진 사람이 장애인복지법령에 따라 지적장애인 등록을 하지 않았다거나 등록 기준을 충족하지 못하였다고 해서 반드시 의사능력이 있다고 단정할 수 없다. 이러한 사정을 고려하면, 지적장애를 가진 사람에게 의사능력이 있는지를 판단할 때 단순히 그 외관이나 피상적인 언행만을 근거로 의사능력을 쉽게 인정해서는 안 되고, 의학적 진단이나 감정 등을 통해 확인되는 지적장애의 정도를 고려해서 법률행위의 구체적인 내용과 난이도, 그에 따라 부과되는 책임의 중대성 등에 비추어 볼 때 지적장애를 가진 사람이 과연 법률행위의 일상적 의미뿐만 아니라 법률적인 의미나 효과를 이해할 수 있는지, 법률행위가 이루어지게 된 동기나 경위 등에 비추어 합리적인 의사결정이라고 보기 어려운 사정이 존재하는지 등을 세심하게 살펴보아야 한다. ">
                        <input class="inputbox"
                          value="[3] 지적장애 3급의 장애인인 甲이 乙 주식회사와 체결한 굴삭기 구입자금 대출약정에 기한 대출금채무를 연체하자 乙 회사가 甲을 상대로 대출원리금의 지급을 구하는 소를 제기하였고, 이에 甲이 대출약정 당시 의사능력이 없었으므로 대출약정이 무효라고 주장한 사안에서, 대출약정 이후 甲에 대해 한정후견이 개시되었고, 그 심판 절차에서 이루어진 甲에 대한 정신상태 감정 결과의 내용과 감정 시기 등에 비추어 대출약정 당시 甲의 지능지수와 사회적 성숙도 역시 감정 당시와 비슷한 정도였을 것으로 보이는 점, 대출금액이 소액이라고 볼 수 없고, 위 대출약정은 굴삭기 구입자금을 마련하기 위한 것으로서 굴삭기는 실질적으로 대출금채무의 담보가 되고 대출금은 굴삭기 매도인에게 직접 지급되는데, 이와 같은 대출 구조와 내용은 甲의 당시 지적능력으로는 이해하기 어려운 정도라고 볼 수 있는 점, 대출약정 당시 甲이 제출한 굴삭기운전자격증은 위조된 것이었고, 甲의 지적능력에 비추어 굴삭기를 운전할 능력이 있었다고 보기도 어려우며, 甲이 자격증을 위조하면서까지 대출약정을 할 동기를 찾기 어려운 등 대출약정의 체결 경위에 합리적인 의사결정이라고 보기 어려운 사정이 있고, 오히려 제3자가 대출금을 실제로 사용하기 위해서 甲을 이용한 것은 아닌지 의심이 드는 점 등에 비추어, 지적장애인인 甲이 대출약정의 법률적인 의미나 효과를 이해할 수 있었다고 보기 어려우므로, 甲은 대출약정 당시 의사능력이 없다는 이유로 대출약정은 무효라고 볼 여지가 많은데도, 이와 달리 본 원심판결에 법리오해 등의 잘못이 있다고 한 사례.">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">참조조문</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="[1] 민법 제9조 제1항, 제12조 제1항, 제14조의2 제1항  ">
                        <input class="inputbox"
                          value="[2] 민법 제9조 제1항, 제12조 제1항, 제14조의2 제1항, 장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호, 제2항, 장애인복지법 시행규칙 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호  ">
                        <input class="inputbox" value="[3] 민법 제12조 제1항, 장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호, 제2항, 장애인복지법 시행규칙 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">참조판례</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="[1] 대법원 2002. 10. 11. 선고 2001다10113 판결(공2002하, 2675), 대법원 2006. 9. 22. 선고 2006다29358 판결, 대법원 2009. 1. 15. 선고 2008다58367 판결(공2009상, 155)">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">원고</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="비엔케이캐피탈 주식회사">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">피고</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="피고(법정대리인 한정후견인 ○○○) (소송대리인 변호사 민선향)">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">원심판결</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="부산지법 2019. 1. 18. 선고 2018나41754 판결">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">주문</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="원심판결을 파기하고, 사건을 부산지방법원에 환송한다.">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">이유</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="상고이유를 판단한다.">
                        <input class="inputbox" value="1. 사안 개요">
                        <input class="inputbox" value="원심판결 이유에 따르면 다음 사실을 알 수 있다.">
                        <input class="inputbox"
                          value="가. 원고는 2015. 7. 6.경 피고에게 굴삭기(‘굴착기’라고도 한다) 구입자금으로 8,800만 원을 대출하고(이하 ‘이 사건 대출약정’이라 한다), 대출금 중 인지대를 공제한 8,796만 5,000원을 굴삭기 공급자에게 직접 지급하였다.">
                        <input class="inputbox"
                          value="나. 피고가 대출금채무를 연체하자, 원고는 피고를 상대로 대출원리금의 지급을 구하는 이 사건 소를 제기하였다. 이에 대해 피고는 이 사건 대출약정 당시 의사능력이 없었으므로 이 사건 대출약정은 무효라고 다투었다.">
                        <input class="inputbox" value="2. 원심판단">
                        <input class="inputbox"
                          value="원심은 이 사건 대출약정 당시 피고가 인지·판단능력이 현저히 결여되어 독자적으로 자기 의사를 결정할 수 없는 의사무능력 상태에 있었다고 인정하기 어렵다는 이유로, 피고의 의사무능력 주장을 배척하였다.">
                        <input class="inputbox" value="3. 대법원 판단">
                        <input class="inputbox"
                          value="가. 의사능력이란 자기 행위의 의미나 결과를 정상적인 인식력과 예기력을 바탕으로 합리적으로 판단할 수 있는 정신적 능력이나 지능을 말한다. 의사능력 유무는 구체적인 법률행위와 관련하여 개별적으로 판단해야 하고(대법원 2002. 10. 11. 선고 2001다10113 판결 참조), 특히 어떤 법률행위가 그 일상적인 의미만을 이해해서는 알기 어려운 특별한 법률적 의미나 효과가 부여되어 있는 경우 의사능력이 인정되기 위해서는 그 행위의 일상적인 의미뿐만 아니라 법률적인 의미나 효과에 대해서도 이해할 수 있어야 한다(대법원 2006. 9. 22. 선고 2006다29358 판결, 대법원 2009. 1. 15. 선고 2008다58367 판결 등 참조).">
                        <input class="inputbox"
                          value="장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 [별표 1] 제6호, 장애인복지법 시행규칙 제2조 제1항 [별표 1] 제6호에 따르면, 특별한 사정이 없는 한 지능지수가 70 이하인 사람은 교육을 통한 사회적·직업적 재활이 가능하더라도 지적장애인으로서 위 법령에 따른 보호의 대상이 된다. 지적장애인에 해당하는 경우에도 의학적 질병이나 신체적 이상이 드러나지 않아 사회 일반인이 보았을 때 아무런 장애가 없는 것처럼 보이는 경우가 있다. 반면 지적장애를 가진 사람이 장애인복지법령에 따라 지적장애인 등록을 하지 않았다거나 등록 기준을 충족하지 못하였다고 해서 반드시 의사능력이 있다고 단정할 수 없다.">
                        <input class="inputbox"
                          value="이러한 사정을 고려하면, 지적장애를 가진 사람에게 의사능력이 있는지를 판단할 때 단순히 그 외관이나 피상적인 언행만을 근거로 의사능력을 쉽게 인정해서는 안 되고, 의학적 진단이나 감정 등을 통해 확인되는 지적장애의 정도를 고려해서 법률행위의 구체적인 내용과 난이도, 그에 따라 부과되는 책임의 중대성 등에 비추어 볼 때 지적장애를 가진 사람이 과연 법률행위의 일상적 의미뿐만 아니라 법률적인 의미나 효과를 이해할 수 있는지, 법률행위가 이루어지게 된 동기나 경위 등에 비추어 합리적인 의사결정이라고 보기 어려운 사정이 존재하는지 등을 세심하게 살펴보아야 한다.">
                        <input class="inputbox" value="나. 원심판결 이유와 원심이 적법하게 채택한 증거에 따르면 다음과 같은 사정을 알 수 있다.">
                        <input class="inputbox"
                          value="1) 피고는 2005. 10. 12. 지적장애 3급의 장애인으로 등록하였다. 피고는 2013. 5. 20. ‘지능지수 70, 사회발달연령 7세 8개월, 사회성숙지수 43’의 장애진단을 받았다.">
                        <input class="inputbox"
                          value="2) 이 사건 대출약정 이후 피고에 대한 성년후견 개시가 청구되어(사건번호 생략) 2017. 1. 18. 피고에 대해 한정후견이 개시되었다. 그 심판 절차에서 2016. 10. 31.부터 2016. 11. 24.까지 이루어진 피고에 대한 정신상태 감정 결과 ‘지능지수 52, 사회지수 50(사회연령 9세)’이라는 진단을 받았고, ‘학습이나 문제해결을 위한 기본적인 지적능력뿐만 아니라 일상생활에서의 사회 적응 수준이 해당 연령에 비해 매우 부족하고, 사회적 규범에 대한 이해가 부족하며, 비합리적 방식의 의사결정 가능성이 높아 정신적 제약으로 사무를 처리할 능력이 매우 부족하다.’는 평가를 받았다. 위 감정 결과의 내용과 그 감정 시기 등에 비추어 볼 때, 이 사건 대출약정 당시 피고의 지능지수와 사회적 성숙도 역시 위 감정 당시와 비슷한 정도였을 것으로 볼 수 있다.">
                        <input class="inputbox"
                          value="3) 이 사건 대출약정의 대출금은 8,800만 원으로서 결코 소액이라고 볼 수 없다. 이 사건 대출약정은 굴삭기 구입자금을 마련하기 위한 것으로서 굴삭기는 실질적으로 대출금채무의 담보가 되고 대출금은 굴삭기 매도인에게 직접 지급되는데, 이와 같은 대출 구조와 내용은 피고의 당시 지적능력으로는 이해하기 어려운 정도라고 볼 수 있다.">
                        <input class="inputbox"
                          value="4) 원고는 피고가 굴삭기의 실수요자라고 보아 이 사건 대출을 한 것이고, 증빙자료로서 피고의 굴삭기운전자격증을 제출받았으나, 굴삭기운전자격증은 이후 위조된 것으로 판명되었다. 이 사건 대출약정 당시 피고의 지적능력에 비추어 피고가 굴삭기를 운전할 능력이 있었다고 보기도 어렵다. 이 사건 대출금은 굴삭기 공급자에게 직접 지급되어 피고가 이를 받은 적이 없는데도, 피고가 굴삭기운전자격증을 위조하면서까지 이 사건 대출약정을 할 동기를 찾기 어렵다. 이와 같이 이 사건 대출약정의 체결 경위에는 합리적인 의사결정이라고 보기 어려운 사정이 있고, 오히려 제3자가 대출금을 실제로 사용하기 위해서 피고를 이용한 것은 아닌지 의심이 든다.">
                        <input class="inputbox"
                          value="다. 이러한 사정을 위에서 본 법리에 비추어 보면, 지적장애인인 피고가 이 사건 대출약정의 법률적인 의미나 효과를 이해할 수 있었다고 보기 어렵다. 따라서 피고는 이 사건 대출약정 당시 의사능력이 없다는 이유로 이 사건 대출약정은 무효라고 볼 여지가 많다.">
                        <input class="inputbox"
                          value="그런데 원심은 피고가 이 사건 대출약정 당시 인지·판단능력이 현저히 결여되어 독자적으로 자기 의사를 결정할 수 없는 상태에 있었다고 보기 어렵다는 이유로 피고의 의사무능력 주장을 배척하였다. 원심판결은 지적장애인의 의사능력에 관한 법리를 오해하여 필요한 심리를 다하지 않아 판결에 영향을 미친 잘못이 있다. 이를 지적하는 상고이유 주장은 정당하다.">
                        <input class="inputbox" value="4. 결론">
                        <input class="inputbox"
                          value="나머지 상고이유에 대한 판단을 생략한 채 원심판결을 파기하고 사건을 다시 심리·판단하도록 원심법원에 환송하기로 하여, 대법관의 일치된 의견으로 주문과 같이 판결한다.">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <router-link class="btn bg1" :to="{ path: '/m_list' }">확인</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import Header from "../../components/Header.vue";

export default {
  name: 'MView1',
  data() {
    return {
      no: 1,
      metas_info: {
        metas_ko: [
          {
            poi_key: "판시사항", poi_value: `[1] 국세징수법 제85조의2 제1항 각호에서 정한 체납처분 유예의 사유가 인정되는 경우, 세무서장이 직권으로 체납처분 유예 및 압류해제를 할 수 있는지 여부(적극) / 세무서장이 직권으로 체납처분 유예 및 압류해제를 하면서 납세담보 제공자로부터 납세보증서를 제출받은 경우, 이는 세법에 근거하여 적법하게 제공받은 납세담보인지 여부(적극)
[2] 과세관청이 부가가치세 등 조세체납을 이유로 甲 주식회사 소유의 부동산을 압류하였는데, 乙 주식회사와 乙 회사의 설립 자본금을 출자한 丙이 甲 회사를 인수하기로 하여 甲 회사와 위 부동산에 관한 매매계약 및 영업양수도계약을 체결하면서 丙이 과세관청에 ‘甲 회사가 체납세액을 완납하지 아니할 때에는 丙의 책임으로 체납세액을 납부할 것을 보증한다’는 취지의 납세보증서를 제출하였고, 이에 과세관청이 납세보증서를 담보로 체납처분을 유예하고 부동산 압류를 해제하였으며, 乙 회사는 위 부동산을 담보로 대출을 받아 甲 회사의 체납세액 일부를 납부하였으나, 그 후 甲 회사 등이 체납처분 유예기간까지 나머지 체납세액을 납부하지 아니하자 과세관청이 丙에 대하여 부가가치세 등 부과처분을 한 사안에서, 위 납세보증은 세법에 근거하여 적법하게 제공받은 납세담보라고 한 사례`, style_class: "textareabox",
          },
          {
            poi_key: "판결요지", poi_value: `[1] 국세징수법상 체납처분 유예와 압류해제, 납세담보에 관한 규정의 체계와 문언 내용, 특히 국세징수법 제85조의2 제1항은 세무서장이 일정한 사유가 있는 경우 체납처분을 유예할 수 있다고 규정하고 있을 뿐 체납자의 신청이 있는 경우에만 체납처분 유예를 할 수 있다고 규정하지 않고 있고, 국세징수법 제15조와 제17조에 규정된 징수유예도 세무서장이 직권으로 할 수 있는 점과의 균형 등에 비추어 보면, 세무서장은 국세징수법 제85조의2 제1항 각호에서 정한 체납처분 유예의 사유가 인정되는 경우에는 체납자의 신청이 없더라도 같은 조 제1항, 제2항에 따라 직권으로 체납처분에 의한 재산의 압류나 압류재산의 매각을 유예하고 이미 압류한 재산의 압류를 해제할 수 있다고 봄이 타당하다. 그리고 세무서장이 직권으로 체납처분 유예 및 압류해제를 하면서 국세징수법 제85조의2 제3항 본문, 국세기본법 제31조 제2항에 따라 납세담보 제공자로부터 체납액에 상당하는 납세보증서를 제출받았다면 이는 세법에 근거하여 적법하게 제공받은 납세담보이다.
[2] 과세관청이 부가가치세 등 조세체납을 이유로 甲 주식회사 소유의 부동산을 압류하였는데, 乙 주식회사와 乙 회사의 설립 자본금을 출자한 丙이 甲 회사를 인수하기로 하여 甲 회사와 위 부동산에 관한 매매계약 및 영업양수도계약을 체결하면서 丙이 과세관청에 ‘甲 회사가 체납세액을 완납하지 아니할 때에는 丙의 책임으로 체납세액을 납부할 것을 보증한다’는 취지의 납세보증서를 제출하였고, 이에 과세관청이 납세보증서를 담보로 체납처분을 유예하고 부동산 압류를 해제하였으며, 乙 회사는 위 부동산을 담보로 대출을 받아 甲 회사의 체납세액 일부를 납부하였으나, 그 후 甲 회사 등이 체납처분 유예기간까지 나머지 체납세액을 납부하지 아니하자 과세관청이 丙에 대하여 부가가치세 등 부과처분을 한 사안에서, 위 납세보증은 丙이 체납처분 유예 및 압류해제를 받기 위해 국세기본법 제31조 제2항 및 국세기본법 시행규칙 제9조 제2항
[별지 제11호 서식]에 따라 작성하여 과세관청에 제출한 것이고, 과세관청은 국세징수법 제85조의2에 따라 납세보증을 제출받으면서 직권으로 체납처분을 유예하고 부동산 압류를 해제하였으므로, 위 납세보증은 과세관청이 국세기본법 및 국세징수법 규정에 근거하여 제공받은 납세담보에 해당하고, 한편 체납자인 甲 회사는 丙 등과 체결한 부동산매매계약 및 영업양수도계약의 이행을 위해 잔금지급일 전에 미리 丙 등에게 위 부동산에 관한 소유권이전등기를 마쳐주었으며, 丙 등은 과세관청의 압류해제 및 압류등기 말소가 이루어지자 위 부동산을 담보로 대출받은 돈으로 체납세액 일부를 대납하였고, 그 일련의 과정에서 甲 회사가 이의를 제기한 사정이 없으므로, 위 납세보증이 체납자를 배제한 채 체납자의 의사에 반하여 이루어졌다고 단정하기도 어려운데도, 이와 달리 본 원심판단에 법리오해의 잘못이 있다고 한 사례.`, style_class: "textareabox",
          },
          {
            poi_key: "참조조문", poi_value: `[1] 국세징수법 제15조, 제17조, 제85조의2 제1항, 제2항, 제3항, 국세기본법 제31조 제2항
[2] 국세징수법 제85조의2 제1항 제2호, 제2항, 제3항, 국세기본법 제31조 제2항, 국세기본법 시행규칙 제9조 제2항
[별지 제11호 서식]
`, style_class: "textareabox",
          },
          //           {
          //             poi_key: "참조판례", poi_value: `[1] 대법원 2002. 10. 11. 선고 2001다10113 판결(공2002하, 2675), 대법원 2006. 9. 22. 선고 2006다29358 판결, 대법원 2009. 1. 15. 선고 2008다58367 판결(공2009상, 155)
          // `, style_class: "textareabox",
          //           },
          {
            poi_key: "원고", poi_value: `원고 (소송대리인 변호사 김해마중 외 2인)`, style_class: "inputbox",
          },
          {
            poi_key: "피고", poi_value: `포항세무서장`, style_class: "inputbox",
          },
          {
            poi_key: "원심판결", poi_value: `대구고법 2020. 2. 7. 선고 2018누4619 판결`, style_class: "inputbox",
          },
          {
            poi_key: "주문", poi_value: `원심판결을 파기하고, 사건을 대구고등법원에 환송한다.`, style_class: "inputbox",
          },
          {
            poi_key: "이유", poi_value: `상고이유를 판단한다.
   1. 사안의 개요
      원심판결 이유와 기록에 의하면, 다음과 같은 사실을 알 수 있다.
      가. 주식회사 금오이앤지(이하 ‘금오이앤지’라고 한다)와 금오이앤지의 설립 자본금을 출자한 원고(이하에서 금오이앤지와 원고를 통칭할 때에는 ‘원고 측’이라고 한다)는 2016. 9.경 경영난으로 인하여 매각을 추진하던 영일기업 주식회사(이하 ‘영일기업’이라고 한다)를 인수하고자 하였다.
      나. 당시 영일기업은 부가가치세, 근로소득세, 퇴직소득세 등 합계 1,919,760,870원(이하 ‘이 사건 체납세액’이라고 한다)의 국세를 체납하고 있었다. 피고는 2016. 8. 2. 위와 같은 조세체납을 이유로 영일기업 소유의 포항시 남구 (주소 생략) 공장용지 10,961.5㎡ 등 20개 부동산(이하 ‘이 사건 각 부동산’이라고 한다)에 관하여 체납처분에 기한 압류등기를 마쳤다(이하 ‘이 사건 압류’라고 한다).
      다. 금오이앤지는 2016. 10. 25. 영일기업으로부터 이 사건 각 부동산을 대금 86억 원에 매수하고, 2016. 10. 26. 위 각 부동산에 관하여 소유권이전등기를 마쳤다. 또한 원고는 2016. 10. 31. 영일기업과 원고 측이 영일기업으로부터 자산, 부채 및 발행주식 전부를 양수하는 내용의 영업양수도계약을 체결하였다.
      라. 원고는 2016. 12. 8. 피고에게 ‘이 사건 체납세액 중 10억 원은 2016. 12. 9.에 납부하고 나머지는 2017. 3. 31.까지 완납하기로 하며, 이 사건 체납세액에 대한 납세보증서를 제공하되 보증기한까지 완납되지 않으면 피고가 자신의 재산에 대하여 체납처분을 하여도 이의를 제기하지 않는다’는 내용의 확인서를 제출하였다.
      마. 이후 원고는 2016. 12. 9. 피고에게 ‘영일기업이 2017. 3. 31.까지 이 사건 체납세액을 완납하지 아니할 때에는 원고의 책임으로 위 체납세액을 납부할 것을 보증한다’는 취지의 납세보증서를 제출하였다(이하 ‘이 사건 납세보증’이라고 한다). 이에 피고는 같은 날 국세징수법상 체납처분 유예 규정에 따라 이 사건 납세보증서를 담보로 이 사건 체납세액에 관한 체납처분 절차를 2017. 3. 31.까지 유예하고, 같은 날 이 사건 각 부동산에 관하여 이 사건 압류를 해제하면서 압류등기를 말소하였다.
      바. 금오이앤지는 2016. 12. 9. 위와 같이 압류해제된 이 사건 각 부동산을 담보로 금융기관으로부터 대출을 받아 영일기업의 이 사건 체납세액 가운데 10억 원을 피고에게 납부하였다.
      사. 피고는 영일기업 등이 2017. 3. 31.까지 나머지 체납세액을 납부하지 아니하자, 2017. 4. 3. 원고에 대하여 2015년 제1기분 부가가치세 570,171,000원(가산세 포함) 및 가산금 64,999,480원, 2015년 제2기분 부가가치세 256,051,420원(가산세 포함) 및 가산금 50,043,340원, 2015년 제2기분 부가가치세에 대한 가산금 18,154,270원을 각각 납부통지하였다(이하 ‘이 사건 처분’이라고 한다).
   2. 상고이유 제1점에 대하여
      가. 관련 규정
     국세징수법 제85조의2는 세무서장은 체납자가 재산의 압류나 압류재산의 매각을 유예함으로써 사업을 정상적으로 운영할 수 있게 되어 체납액의 징수가 가능하다고 인정되는 경우에는 그 체납액에 대하여 체납처분에 의한 재산의 압류나 압류재산의 매각을 대통령령으로 정하는 바에 따라 유예할 수 있고(제1항 제2호), 제1항에 따라 유예를 하는 경우에 필요하다고 인정하면 이미 압류한 재산의 압류를 해제할 수 있으며(제2항), 제1항 및 제2항에 따라 재산의 압류를 유예하거나, 압류한 재산의 압류를 해제하는 경우에는 그에 상당하는 납세담보의 제공을 요구할 수 있다(제3항)고 규정하고 있다.
      한편 국세기본법 제31조 제2항은 납세보증보험증권이나 납세보증서를 납세담보로 제공하려는 자는 그 보험증권이나 보증서를 세무서장에게 제출하여야 한다고 규정하고 있다.
      나. 원심의 판단
      앞서 본 사실관계를 토대로 원심은, 이 사건 납세보증과 관련하여 체납자인 영일기업은 피고에게 이 사건 각 부동산에 대한 압류해제 등 체납처분의 유예를 신청한 적이 없고, 피고가 영일기업에 납세담보 제공을 요구하였다는 자료도 없으며, 피고는 체납자인 영일기업을 배제한 채 원고에게 이 사건 압류해제에 대응하는 납세보증을 요구한 사정 등을 종합하면, 이 사건 납세보증은 국세징수법 제85조의2 제3항에 근거하여 이루어진 것이 아니라 사법상 보증계약에 의한 납세보증에 불과하여 무효이고, 이러한 납세보증계약에 기초한 이 사건 처분은 그 하자가 중대하고 명백하여 당연무효라고 판단하였다.
      다. 대법원의 판단
      그러나 원심의 위와 같은 판단은 수긍할 수 없다.
   1) 국세징수법상 체납처분 유예와 압류해제, 납세담보에 관한 규정의 체계와 문언 내용, 특히 국세징수법 제85조의2 제1항은 세무서장이 일정한 사유가 있는 경우 체납처분을 유예할 수 있다고 규정하고 있을 뿐 체납자의 신청이 있는 경우에만 체납처분 유예를 할 수 있다고 규정하지 않고 있고, 국세징수법 제15조와 제17조에 규정된 징수유예도 세무서장이 직권으로 할 수 있는 점과의 균형 등에 비추어 보면, 세무서장은 국세징수법 제85조의2 제1항 각호에서 정한 체납처분 유예의 사유가 인정되는 경우에는 체납자의 신청이 없더라도 같은 조 제1항, 제2항에 따라 직권으로 체납처분에 의한 재산의 압류나 압류재산의 매각을 유예하고 이미 압류한 재산의 압류를 해제할 수 있다고 봄이 타당하다.
     그리고 세무서장이 직권으로 체납처분 유예 및 압류해제를 하면서 국세징수법 제85조의2 제3항 본문, 국세기본법 제31조 제2항에 따라 납세담보 제공자로부터 그 체납액에 상당하는 납세보증서를 제출받았다면 이는 세법에 근거하여 적법하게 제공받은 납세담보이다.
   2) 위와 같은 사실관계와 기록에 의하여 알 수 있는 다음과 같은 사정들을 이러한 법리에 비추어 살펴보면, 이 사건 납세보증은 세법에 근거하여 적법하게 제공받은 납세담보라고 할 것이다(나아가 원심판단과 같이 그 납세보증이 체납자를 배제한 채 체납자의 의사에 반하여 이루어졌다고 단정하기도 어렵다).
      가) 이 사건 납세보증은 원고가 체납처분 유예 및 압류해제를 받기 위해 국세기본법 제31조 제2항 및 국세기본법 시행규칙 제9조 제2항 [별지 제11호 서식]에 따라 작성하여 피고에게 제출한 것이다. 피고는 국세징수법 제85조의2에 따라 이 사건 납세보증을 제출받으면서 직권으로 체납처분을 유예하고 이 사건 각 부동산의 압류를 해제하였다. 따라서 이 사건 납세보증은 피고가 위 국세기본법 및 국세징수법 규정에 근거하여 제공받은 납세담보에 해당한다.
      나) 한편 체납자인 영일기업은 원고 측과 체결한 부동산매매계약 및 영업양수도계약의 이행을 위해 잔금지급일 전에 미리 원고 측에 영일기업의 영업을 위한 주요 자산인 이 사건 각 부동산에 관한 소유권이전등기를 마쳐주었다. 원고 측은 이 사건 각 부동산에 관한 피고의 압류해제 및 압류등기 말소가 이루어지자 이 사건 각 부동산을 담보로 대출받은 돈으로 피고에게 이 사건 체납세액 중 10억 원을 대납하였고, 그 일련의 과정에서 영일기업이 이의를 제기한 사정은 엿보이지 않는다.
   3) 그런데도 원심은 그 판시와 같은 사정만으로 이 사건 납세보증이 사법상 보증계약에 의한 납세보증이라고 전제하고 이러한 납세보증에 기초한 이 사건 처분은 그 하자가 중대하고 명백하여 당연무효라고 판단하였으니, 이러한 원심의 판단에는 직권으로 한 체납처분 유예의 요건과 압류해제 시 제공된 납세보증의 효력 등에 관한 법리를 오해함으로써 판결에 영향을 미친 잘못이 있고, 이를 지적하는 상고이유 주장은 이유 있다. 원심이 들고 있는 대법원 판례는 사안이 달라 이 사건에 원용하기에 적절하지 않다.
   3. 결론
      그러므로 나머지 상고이유에 대한 판단을 생략한 채 원심판결을 파기하고, 사건을 다시 심리·판단하게 하기 위하여 원심법원에 환송하기로 하여, 관여 대법관의 일치된 의견으로 주문과 같이 판결한다.`, style_class: "textareabox",
          },
        ],
        // metas_tr: [
        //   { poi_key: "판시사항", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "판결요지", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "참조조문", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "참조판례", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "원고", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "피고", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "원심판결", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "주문", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "이유", poi_value: "", style_class: "textareabox", },
        // ],
      },
      //       doc_basic1: `- 문서명: 대여금[지적장애를 가진 사람의 의사능력이 문제된 사건]
      // - 문서번호: 2019다213344
      // - 법원 종류: 대법원
      // - 일자: 2022. 5. 26.
      // - 법관: 대법관 노정희(재판장) 김재형(주심) 안철상 이흥구
      // `,
    };
  },
  components: {
    Header
  },
  methods: {
  },
  mounted: async function () {
    this.no = this.$route.params.no;
  }
};
</script>



<style>
.textareabox2 {
  border: 1px solid #d3d9dd;
  background-color: #f3f6f9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 200px;
}
</style>