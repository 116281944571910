<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">번역 검수 목록(<span v-if="language == 'en'">영어</span>
          <span v-else-if="language == 'ja'">일본어</span>
          <span v-else-if="language == 'chs'">중국어(간체)</span>
          <span v-else-if="language == 'cht'">중국어(번체)</span>
          <span v-else>미지원 언어</span>)
        </h2>

        <div class="search_wrapper mgT10">
          <input type="number" class="inputbox" style="width:260px;" v-model="search_poi" placeholder="POI 번호로 검색" />
          &nbsp;
          <a @click="doSearch2()" class="btn bg">검색</a>
        </div>

        <div class="search_wrapper mgT10">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>

        <!-- table_type -->
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <col width="8%" />
              <col width="8%" />
              <col />
              <col width="10% " />
              <col width="8%" />
              <!-- <col width="8%" /> -->
              <col width="8%" />
              <col width="8%" />
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">POI 번호</th>
              <th scope="col">이름</th>
              <th scope="col">작업자</th>
              <th scope="col">1차 검수상태</th>
              <!-- <th scope="col">임의검수</th> -->
              <th scope="col">2차 검수상태</th>
              <th scope="col">3차 검수상태</th>
            </thead>
            <tbody>
              <tr v-for="(t, index) in trans_list" :key="t.no" @click="to_view(t.no)" style="cursor:pointer;">
                <td>{{ trans_numbering(index) }}</td>
                <td>{{ t.poi_no }}</td>
                <td class="txL">{{ t.poi_name }}</td>
                <td>{{ t.username }} ( {{ t.userid }} )</td>

                <!-- 0검은색(검수대기), 1파란색(검수완료), 2초록색, 3빨간색 -->
                <td>
                  <span v-if="t.error1 == 1 && role >= 2" class="co_state3">수정 후 완료</span>
                  <span v-else-if="t.error1 == 1 && role == 1" class="co_state1">검수완료</span>
                  <span v-else-if="t.state == 0 && t.tempsave == 1" class="co_state">임시저장</span>
                  <span v-else-if="t.state == 0" class="co_state"></span>
                  <span v-else-if="t.state == 1 || t.state == 3" class="co_state1">검수완료</span>
                  <span v-else-if="t.state == 2" class="co_state2">추가 검수요망</span>
                </td>
                <!-- <td>
                  <span v-if="t.is_rand == 1">O</span>
                </td> -->
                <td>
                  <span v-if="t.error2 == 1" class="co_state3">수정 후 완료</span>
                  <span v-else-if="t.state2 == 3 || (t.state == 1 && t.confirmer2 == 0)" class="co_state1">검수패스</span>
                  <span v-else-if="t.state2 == 0 && t.tempsave == 2" class="co_state">임시저장</span>
                  <span v-else-if="t.state2 == 0" class="co_state"></span>
                  <span v-else-if="t.state2 == 1" class="co_state1">검수완료</span>
                  <span v-else-if="t.state2 == 2" class="co_state2">추가 검수요망</span>
                  <span v-else class="co_state">미검수</span>
                </td>
                <td>
                  <span v-if="t.error2 == 1" class="co_state1">검수완료</span>
                  <span v-else-if="(t.state == 1 && t.confirmer2 == 0) || (t.state2 == 1 && t.confirmer3 == 0)"
                    class="co_state1">검수패스</span>
                  <span v-else-if="t.state3 == 0 && t.tempsave == 3" class="co_state">임시저장</span>
                  <span v-else-if="t.state3 == 0" class="co_state"></span>
                  <span v-else-if="t.state3 == 1" class="co_state1">검수완료</span>
                  <span v-else class="co_state">미검수</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->
        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import common from "../common/common.js";
import config from "../common/config.js";
import Header from "../components/Header.vue";

export default {
  name: "TransList",
  data() {
    return {
      search: "",
      search_now: "",
      search_queue: false,
      search_poi: "",

      language: "",
      page: 1,
      page_total: 1,
      limit: 15,
      poi_num: 1,
      trans_list: [],
      role: 0,
    };
  },
  components: {
    Header,
  },
  methods: {
    to_view(tno) { // view 들어가기
      this.$router.push({
        name: "TransView",
        params: {
          tno: tno,
        }
      });
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.trans_list_load();
    },
    trans_numbering(num) {
      // POI 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - (this.page - 1) * this.limit - num;
      } else {
        return this.poi_num - (this.page_total - 1) * this.limit - num;
      }
    },
    trans_list_load(poi_no = "") {
      // 번역 리스트 목록

      let search = "";
      if (this.search !== "") {
        search = this.search;
      }

      axios({
        url: config.apiurl + "?part=trans&mode=trans_work_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          gno: this.$route.params.gno,
          mno: this.$route.params.mno,
          language: this.language,
          page: this.page,
          limit: this.limit,
          poi_no: poi_no,
          search: search,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d);
        this.trans_list = d.list;
        this.poi_num = d.poi_num;
        this.page_total = d.page_total;
        this.role = d.role;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.trans_list_load();
      }, 250);
    },
    doSearch2() {
      this.trans_list_load(this.search_poi);
    },
  },
  mounted: async function () {
    this.language = this.$route.params.lan;
    if (this.$route.params.lan == 'en') { await common.check_perm(this.$store.state.token, [411, 412]); } // 영어
    else if (this.$route.params.lan == 'cht') { await common.check_perm(this.$store.state.token, [421, 422]); } // 번체
    else if (this.$route.params.lan == 'chs') { await common.check_perm(this.$store.state.token, [431, 432]); } // 간체
    else if (this.$route.params.lan == 'ja') { await common.check_perm(this.$store.state.token, [441, 442]); } // 일본어
    else {
      alert("해당 언어는 지원하지 않습니다.");
      this.$router.push({ name: "Mypage" });
    }
    this.trans_list_load();
  },
};
</script>




<style>
/* .search_wrapper {
  padding: 12px;
} */

.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>