<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <!-- <div style="float: right">
          <router-link :to="{ path: '/mypage/' }" class="btn bg2">목록으로</router-link>
        </div> -->
        <h2 class="h_title">이미지 워커별 작업현황</h2>
        <h2 class="h_title2 mgT50">이미지 업로더</h2>
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">번호</th>
                <th scope="col">소속</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">찜</th>
                <th scope="col">등록 및 검수대기</th>
                <th scope="col">검수완료</th>
                <th scope="col">반려</th>
                <th scope="col">찜 취소</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <th>합계</th>
                <th>{{ total_count1 }}</th>
                <th>{{ total_count2 }}</th>
                <th>{{ total_count3 }}</th>
                <th>{{ total_count4 }}</th>
                <th>{{ total_count5 }}</th>
                <!-- <td></td>
                <td>
                  <router-link :to="{ path: '/group_view_work/' + language + '/' + gno }" class="btn size1">전체작업보기
                  </router-link>
                </td> -->
              </tr>
              <tr v-if="member_list.length == 0">
                <td colspan="9">
                  <span>로딩중입니다...</span>
                </td>
              </tr>
              <tr v-for="(m, index) in member_list" :key="m.no">
                <td>{{ member_numbering(index) }}</td>
                <td>{{ m.userclass }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>
                <td>{{ m.count1 }}</td>
                <td>{{ m.count2 }}</td>
                <td>{{ m.count3 }}</td>
                <td>{{ m.count4 }}</td>
                <td>{{ m.count5 }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3">
                  <!-- <a class="btn" @click="add_worker()">작업자 추가</a> -->
                </th>
                <th>합계</th>
                <th>{{ total_count1 }}</th>
                <th>{{ total_count2 }}</th>
                <th>{{ total_count3 }}</th>
                <th>{{ total_count4 }}</th>
                <th>{{ total_count5 }}</th>
                <!-- <th>{{ count.error }}</th>
                <th></th> -->
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- //table_type -->

        <!-- <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
          &nbsp;
          <span>페이지 이동 후 약 5초 정도의 로딩시간이 있습니다.</span>
        </div> -->
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "PoiAssignConfirm",
  data: function () {
    return {
      member_list: [],
      assign_num: 0,
      total_count1: 0,
      total_count2: 0,
      total_count3: 0,
      total_count4: 0,
      total_count5: 0,
    };
  },
  components: {
    Header,
  },
  methods: {
    member_list_load() {
      axios({
        url: config.apiurl + "?part=image&mode=image_day_table",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          page: this.page,
          limit: this.limit,
        },
      })
        .then((response) => {
          var d = response.data;
          console.log(d);
          this.member_list = d.list;
          this.total_count1 = d.total_count1;
          this.total_count2 = d.total_count2;
          this.total_count3 = d.total_count3;
          this.total_count4 = d.total_count4;
          this.total_count5 = d.total_count5;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    member_numbering(num) {
      // 작업자 목록 번호 세팅
      // return this.member_list.length - num
      return num+1
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 53);
    this.member_list_load();
  },
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>