<template>
  <div class="edit">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div class="view_top">
          <h2 class="h_title">
            <span>최종 POI 수정</span>
          </h2>
          <a href="/final_poi_list" class="btn size1">목록보기</a>
        </div>
        <div class="fx-1 mgT10">
          <h3 class="mgT10 h_title">반려사유</h3>
          <div class="box_type1">
            <span v-if="final_info.qc_reject_reason != ''">{{ final_info.qc_reject_reason }}<br></span>
            <span v-if="final_info.ac_reject_reason != ''">{{ final_info.ac_reject_reason }}</span>
          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <template v-if="(final_info.poi_is_edit == 0)">
            <a v-if="edited == 0" @click="submit(1)" class="btn bg0">임시저장</a>
            <a v-if="edited == 0" @click="submit()" class="btn bg1">POI수정</a>
            <span v-else>저장중입니다...</span>
          </template>
          <router-link :to="{ path: '/final_poi_list/' }" class="btn bg">확인</router-link>
        </div>
        <a @click="resort()" class="btn size1">재정렬하기</a>
        <div class="fx-1 mgT10">
          <!-- table_type -->
          <div class="table_type3" @change="json_preview()">
            <table>
              <colgroup>
                <col width="20%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <input value="관광타입" class="inputbox" readonly />
                  </th>
                  <td>
                    <div class="fx-2">{{ ttype }}</div>
                  </td>
                </tr>

                <tr v-for="t in type" :key="t">
                  <th scope="row">
                    <input class="inputbox" v-model="t[0]" readonly />
                  </th>
                  <td>
                    <div class="fx-2">
                      <input class="inputbox" v-model="t[1]" :readonly="check_address(t)"
                        :class="[check_address(t) ? 'readonly' : '']" />
                      <!-- <a @click="meta_move(index, -1)" class="btn">↑</a>
                      <a @click="meta_move(index, 1)" class="btn">↓</a> -->
                      <template v-if="!(fixed.indexOf(t[0]) != -1)">
                        <!-- <a @click="removemeta(index)" class="btn">제거</a> -->
                      </template>
                    </div>
                  </td>
                </tr>

                <!-- <tr>
                  <th scope="row">
                    <input v-model="addkey" type="text" class="inputbox" />
                  </th>
                  <td>
                    <div class="fx-2">
                      <input v-model="addvalue" type="text" class="inputbox" />
                      <a @click="addmeta" class="btn">추가</a>
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <!-- //table_type -->
          <div class="json_box">
            json preview<br /><br />
            <pre>{{ json }}</pre>
          </div>
        </div>

        <!-- 트리플! -->
        <div class="fx-5 mgT30">
          <div class="list_box">
            <h2 class="h_title">Triple</h2>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>A</th>
                    <th>R</th>
                    <th>B</th>
                  </tr>
                  <tr v-if="triple.length == 0">
                    <td colspan="3" style="text-align:center;">등록된 Triple이 없습니다.</td>
                  </tr>
                  <tr v-for="t in triple" :key="t">
                    <td>{{ t.a }}</td>
                    <td>{{ t.r }}</td>
                    <td>{{ t.b }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- 이미지! -->
        <div class="fx-5 mgT50">
          <div v-for="(img, index) in image_list" :key="img" class="list_box">
            <h2 class="h_title">이미지{{ index + 1 }}</h2>

            <div class="qna_img_box">
              <img class="mgT10" v-if="img.img_url != ''" :src="'http://' + image_list[index].img_url"
                title="클릭하면 새창으로 원본을 볼 수 있습니다." onclick="window.open(this.src)" style="cursor: pointer;">
            </div>
            <!-- table_type -->
            <div class="table_type2 mgT20">
              <table>
                <tbody>
                  <tr>
                    <td>
                      [Caption1]<textarea v-if="image_list.length > 0" class="textareabox"
                        v-model="image_list[index].caption1" readonly style="height:100px;">
                                            </textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      [Caption2]<textarea v-if="image_list.length > 0" class="textareabox"
                        v-model="image_list[index].caption2" readonly style="height:100px;">
                                            </textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="mgT20">
                <tbody v-for="i in 4" :key="i">
                  <tr>
                    <th style="word-break:break-all">{{ image_list[index].qna[i - 1].key }}. {{
                        image_list[index].qna[i - 1].q
                    }}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <span style="font-weight:600;">Triple</span>
                      <p v-for="(t, idx) in image_list[index].qna[i - 1].triple" :key="t" style="word-break:break-all">
                        <template v-if="t == '선택안함'">
                          {{ t }}
                        </template>
                        <template v-else>
                          {{ idx + 1 }}. {{ t }}
                        </template>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ image_list[index].qna[i - 1].akey }}. {{ image_list[index].qna[i - 1].a }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
        </div>

      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "FinalPoiView",
  data() {
    return {
      fixed: [
        "관광타입",
        "관광지명",
        "시설명",
        "업소명",
        "음식점명",
        "개요",
        "주소",
        "주소(도로명)",
        "주소(지번주소)",
      ], // 제거x 항목
      addkey: "",
      addvalue: "",
      origin_address: "",
      poi_no: "",
      type: [],
      json: {},
      ttype: "", // 관광타입
      poi_info: {},
      qna_info: {},
      final_info: {},
      ll: "", // 위도경도
      resorted: 0, // 재정렬여부
      edited: 0,
      triple: [],
      image_list: [],
      type_preset: {
        관광지: [
          ["관광지명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        문화시설: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        음식점: [
          ["음식점명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["영업시간", ""],
          ["휴무일", ""],
          ["취급메뉴", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        레포츠: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["체험프로그램", ""],
          ["리뷰키워드", ""],
        ],
        숙박: [
          ["업소명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["입실시간", ""],
          ["퇴실시간", ""],
          ["홈페이지 주소", ""],
          ["리뷰키워드", ""],
        ],
        쇼핑: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["영업시간", ""],
          ["휴무일", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        편의오락시설: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
        편의오락: [
          ["시설명", ""],
          ["개요", ""],
          ["주소", ""],
          ["대표번호", ""],
          ["이용시간", ""],
          ["휴무일", ""],
          ["입장료/시설이용료", ""],
          ["주차시설 유무", ""],
          ["리뷰키워드", ""],
        ],
      },
    };
  },
  components: {
    Header,
  },
  methods: {
    resort() { // 재정렬하기
      if (this.type_preset[this.ttype] != undefined) {
        if (confirm("[" + this.ttype + "] 관광타입의 프리셋에 따라 재정렬하시겠습니까?")) {
          var temp_type = this.type_preset[this.ttype];
          temp_type.forEach(element => {
            for (let i = 0; i < this.type.length; i++) {
              if (this.type[i][0] == element[0]) {
                element[1] = this.type[i][1];
                break;
              }
            }
          });
          console.log(temp_type)
          this.type = temp_type;
          this.resorted = 1;
        }
      } else {
        alert("[" + this.ttype + "] 관광타입에 대한 프리셋이 없습니다. 관리자에게 문의해주세요.");
      }
    },
    check_address(t) {
      if (t[0] == "주소(도로명)" || t[0] == "주소(지번주소)") return true;
      else return false;
    },
    meta_move(index, count) {
      // 항목 이동
      if (index + count >= 0 && index + count < this.type.length) {
        [this.type[index + count], this.type[index]] = [
          this.type[index],
          this.type[index + count],
        ];
        this.json_preview();
      }
    },
    addmeta() {
      // 항목 추가
      this.type.push([this.addkey, this.addvalue]);
      this.addkey = "";
      this.addvalue = "";
      this.json_preview();
    },
    removemeta(index) {
      // 항목 제거
      if (confirm("해당 항목을 제거하시겠습니까?")) {
        this.type.splice(index, 1);
        this.json_preview();
      }
    },
    async address_change() {
      var metas = this.type;
      var address = "";
      let address_index = 0;

      for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        if (meta[0] == "주소") {
          address_index = i;
          address = meta[1];
          break;
        }
      }

      var address_info = await this.address_search(address);

      if (address_info.address) {
        if (this.resorted == 1) { // 재정렬되었으면 추가
          metas.splice(address_index + 1, 0, [
            "주소(지번주소)",
            address_info.address,
          ]);
        } else {
          for (let i = 0; i < metas.length; i++) {
            if (metas[i][0] == "주소(지번주소)") {
              metas[i][1] = address_info.address;
            }
          }
        }
      }
      if (address_info.road_address) {
        if (this.resorted == 1) { // 재정렬되었으면 추가
          metas.splice(address_index + 1, 0, [
            "주소(도로명)",
            address_info.road_address,
          ]);
        } else {
          for (let i = 0; i < metas.length; i++) {
            if (metas[i][0] == "주소(도로명)") {
              metas[i][1] = address_info.road_address;
            }
          }
        }
      }
      this.ll = address_info.coord;
    },
    async submit(tempsave = 0) {
      let metas = this.type;

      let blank_check = false;

      metas.forEach(meta => {
        if (meta[1].trim() == '') blank_check = true;
      });

      if (blank_check) {
        alert("내용 중에 공란이 있습니다.");
      } else {
        let message = "POI를 수정하시겠습니까?";
        if (tempsave != 0) {
          message = "POI 수정내용을 임시저장 하시겠습니까?";
        }
        if (confirm(message)) {
          this.edited = 1;

          for (let i = 0; i < metas.length; i++) {
            const meta = metas[i];
            if (meta[0] == "주소" && (this.origin_address != meta[1] || this.resorted == 1)) {
              await this.address_change();
            }
          }

          metas.unshift(["관광타입", this.ttype]);

          axios({
            url: config.apiurl + "?part=final&mode=final_poi_edit_submit",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              poi_no: this.poi_no,
              metas: metas,
              latlng: this.ll,
              tempsave: tempsave,
            },
          })
            .then((response) => {
              console.log(response.data);
              alert("수정되었습니다.");
              this.edited = 0;
              this.$router.push({ name: "FinalPoiList" });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(1)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    load_data() {
      axios({
        url: config.apiurl + "?part=final&mode=final_poi_view", // 기존 poi 정보불러오기와 동일
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          poi_no: this.poi_no,
        },
      })
        .then((response) => {
          let d = response.data;
          console.log(d);
          this.poi_info = d.poi_info;
          this.final_info = d.final_info;
          this.qna_info = d.qna_info;
          let lists = d.list;
          for (let i = 0; i < lists.length; i++) {
            if (lists[i][0] == "관광타입") {
              this.ttype = lists[i][1];
              lists.splice(i, 1);
            } else if (lists[i][0] == "주소") {
              this.origin_address = lists[i][1];
            }
          }
          this.type = lists;
          this.triple = d.triple;
          this.image_list = d.image_list;
          this.json_preview();
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
    },
    json_preview() {
      // json 미리보기
      var arr = {};
      this.type.forEach(function (element) {
        arr[element[0]] = element[1];
      });
      this.json = JSON.stringify(arr, null, 4);
    },
    async address_search(address) {
      return new Promise((resolv, reject) => {
        let result = {};
        if (address == "") {
          result["coord"] = [0, 0];
          resolv(result);
        } else {
          var geocoder = new kakao.maps.services.Geocoder();

          geocoder.addressSearch(address, (result, status) => {
            if (result.length == 0) {
              result["coord"] = [0, 0];
              resolv(result);
            } else {
              if (
                Object.prototype.hasOwnProperty.call(result[0], "address") &&
                result[0].address !== null
              ) {
                // 지번주소
                result["address"] = result[0].address.address_name;
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  result[0],
                  "road_address"
                ) &&
                result[0].road_address !== null
              ) {
                // 도로명주소
                result["road_address"] = result[0].road_address.address_name;
              }

              if (status === kakao.maps.services.Status.OK) {
                // console.log("해당 좌표 : " + result[0].y + ", " + result[0].x);
                result["coord"] = [result[0].y, result[0].x];
                resolv(result);
              } else {
                result["coord"] = [0, 0];
                reject(result);
                console.log("[좌표 없음]" + address);
              }
            }
          });
        }
      });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 72);
    this.final_no = this.$route.params.no;
    this.poi_no = this.$route.params.no;
    this.load_data();

    if (window.kakao && window.kakao.maps) {
      // this.initMap();
    } else {
      const script = document.createElement("script");
      /* global kakao */
      // script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6d213af40222b5124ab4c81049cb37c4&libraries=services";
      document.head.appendChild(script);
    }
  },
};
</script>
