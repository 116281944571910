<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <p class="h_title">90-2. 법률 문서 트리플 가공 페이지</p>
        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title txtCenter">[ 법률 문서 ]</h2>
            <!-- table_type -->
            <a class="doc_toggle" @click="doc_toggle()">
              <template v-if="doc_is_open">[PDF 닫기]</template>
              <template v-else>[PDF 열기]</template>
            </a>
            <div class="image_box2" style="border:1px solid #ced3d9" :class="{ 'ib_on': doc_is_open }">
              <img style="width:100%;" src="../../../public/images/2022다305861판결-1.png" alt="">
              <img style="width:100%;" src="../../../public/images/2022다305861판결-2.png" alt="">
            </div>
            <div class="table_type3 mgT40">
              <table>
                <colgroup>
                  <col width="12%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="(data, index) in datas[no]" :key="data">
                    <th scope="row">{{ index }}</th>
                    <td>
                      <div style="width:100%">
                        <template v-for="d in data" :key="d">
                          <!-- <span>{{ d.key }}</span>&nbsp; -->
                          <input class="inputbox2" :value="d.value"><br>
                        </template>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
              <!-- <div class="table_type3 mgT20">
                <table>
                  <colgroup>
                    <col width="12%" />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">메타데이터</th>
                      <td>
                        <span>유사법령 </span><input class="inputbox3" value=""><br>
                        <span>적용법령 </span><input class="inputbox3" value=""><br>
                        <span>상위법령 </span><input class="inputbox3" value=""><br>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
            <!-- //table_type -->
          </div>
          <div class="list_box">
            <h2 class="h_title txtCenter">[ 트리플 ]</h2>
            <!-- table_type -->
            <div class="table_type3 mgT20">
              <table>
                <colgroup>
                  <col width="10%" />
                  <col width="37%" />
                  <col width="16%" />
                  <col width="37%" />
                </colgroup>
                <tbody>
                  <tr>
                    <!-- <th scope="col" class="txtCenter">문서 내 문장 번호</th> -->
                    <th scope="col" class="txtCenter">번호</th>
                    <th scope="col" class="txtCenter">Subject</th>
                    <th scope="col" class="txtCenter">Predicate</th>
                    <th scope="col" class="txtCenter">Object</th>
                  </tr>
                  <tr>
                    <th scope="row" class="txtCenter">1</th>
                    <td>
                      <input class="inputbox2" value="공직선거법 제263조">
                      <input class="inputbox2" value="① 제122조(선거비용제한액의 공고)의 규정에 의하여 공">
                      <input class="inputbox2" value="② 「정치자금법」 제49조(선거비용관련 위반행위에 ">
                    </td>
                    <td>
                      <select class="selectbox">
                        <option value="" selected>유사하다</option>
                        <option value="">속한다</option>
                        <option value="">참조한다</option>
                        <option value="">결정한다</option>
                      </select>
                    </td>
                    <td>
                      <input class="inputbox2" value="공직선거법 제265조">
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="txtCenter">2</th>
                    <td>
                      <input class="inputbox2" value="① 원고 산하 ○○○○선거관리위원회 가 2015. 11. 17. ">
                      <input class="inputbox2" value="② 위 고지서가 2015. 11. 30. 피고에게 도달되었음에도">
                      <input class="inputbox2" value="③ 원고는 2020. 8. 3. 이 사건 기탁금 등 반환채권에 관">
                    </td>
                    <td>
                      <select class="selectbox">
                        <option value="">유사하다</option>
                        <option value="" selected>속한다</option>
                        <option value="">참조한다</option>
                        <option value="">결정한다</option>
                      </select>
                    </td>
                    <td>
                      <input class="inputbox2" value="대법원 2023. 5. 18.선고 2022다">
                      <input class="inputbox2" value="공2023하,1086">
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="txtCenter">3</th>
                    <td>
                      <input class="inputbox2" value="대법원 2023. 5. 18.선고 2022다30581 판결">
                      <input class="inputbox2" value="공2023하,1086">
                    </td>
                    <td>
                      <select class="selectbox">
                        <option value="">유사하다</option>
                        <option value="">속한다</option>
                        <option value="" selected>참조한다</option>
                        <option value="">결정한다</option>
                      </select>
                    </td>
                    <td>
                      <input class="inputbox2" value="공직선거법 제263조">
                      <input class="inputbox2" value="① 제122조(선거비용제한액의">
                      <input class="inputbox2" value="② 「정치자금법」 제49조(선거">
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="txtCenter">4</th>
                    <td>
                      <input class="inputbox2" value="공직선거법 제264조">
                      <input class="inputbox2" value="당선인이 당해 선거에 있어 이 법에 규정된 죄">
                    </td>
                    <td>
                      <select class="selectbox">
                        <option value="" selected>유사하다</option>
                        <option value="">속한다</option>
                        <option value="">참조한다</option>
                        <option value="">결정한다</option>
                      </select>
                    </td>
                    <td>
                      <input class="inputbox2" value="공직선거법 제268조">
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="txtCenter">5</th>
                    <td>
                      <input class="inputbox2" value="대법원">
                    </td>
                    <td>
                      <select class="selectbox">
                        <option value="">유사하다</option>
                        <option value="">속한다</option>
                        <option value="">참조한다</option>
                        <option value="" selected>결정한다</option>
                      </select>
                    </td>
                    <td>
                      <input class="inputbox2" value="대법원 2023. 5. 18.선고 2022다">
                      <input class="inputbox2" value="공2023하,1086">
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <div class="table_type3 mgT20">
                <table>
                  <colgroup>
                    <col width="12%" />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">메타데이터</th>
                      <td>
                        <span>유사법령 </span><input class="inputbox3" value=""><br>
                        <span>적용법령 </span><input class="inputbox3" value=""><br>
                        <span>상위법령 </span><input class="inputbox3" value=""><br>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
            <!-- //table_type -->
          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a class="btn bg" @click="submit(1)">가공완료</a>
          <router-link class="btn bg2" :to="{ path: '/m_triple_list' }">목록</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import Header from "../../components/Header.vue";

export default {
  name: 'MTripleView',
  data() {
    return {
      no: 1,
      doc_is_open: false,
      datas: {
        "1": {
          "판례": [
            {
              "key": 1,
              "value": `대법원 2023. 5. 18.선고 2022다30581 판결`,
            },
            {
              "key": 2,
              "value": `공2023하,1086`,
            },
          ],
          "소송사건": [
            {
              "key": 3,
              "value": `① 원고 산하 ○○○○선거관리위원회 가 2015. 11. 17. `,
            },
            {
              "key": 4,
              "value": `② 위 고지서가 2015. 11. 30. 피고에게 도달되었음에도`,
            },
            {
              "key": 5,
              "value": `③ 원고는 2020. 8. 3. 이 사건 기탁금 등 반환채권에 관`,
            },
          ],
          "법률1": [
            {
              "key": 6,
              "value": `공직선거법 제263조`,
            },
            {
              "key": 7,
              "value": `① 제122조(선거비용제한액의 공고)의 규정에 의하여 공`,
            },
            {
              "key": 8,
              "value": `② 「정치자금법」 제49조(선거비용관련 위반행위에 `,
            },
          ],
          "유사법률1": [
            {
              "key": 9,
              "value": `공직선거법 제265조`,
            },
          ],
          "법률2": [
            {
              "key": 10,
              "value": `① 원고 산하 ○○○○선거관리위원회 가 2015. 11. 17. `,
            },
            {
              "key": 10,
              "value": `② 위 고지서가 2015. 11. 30. 피고에게 도달되었음에도`,
            },
            {
              "key": 10,
              "value": `③ 원고는 2020. 8. 3. 이 사건 기탁금 등 반환채권에 관`,
            },
          ],
          "유사법률2": [
            {
              "key": 11,
              "value": `공직선거법 제268조`,
            },
          ],
          "법원": [
            {
              "key": 12,
              "value": `대법원`,
            },
          ],
          "주문": [
            {
              "key": 13,
              "value": `상고를 기각한다.`,
            },
            {
              "key": 14,
              "value": `상고비용은 피고가 부담한다.`,
            },
          ],
        },
      },
    };
  },
  components: {
    Header
  },
  methods: {
    doc_toggle() {
      console.log("!");
      this.doc_is_open = !this.doc_is_open;
      console.log(this.doc_is_open);
    },
    submit(no) {
      if (no == 1) {
        if (confirm("가공 완료 처리하시겠습니까?")) {
          this.$router.push({ name: "MTripleList" });
        }
      }
    }
  },
  mounted: async function () {
    // this.no = this.$route.params.no;
    this.no = 1;
  }
};
</script>



<style>
.textareabox2 {
  border: 1px solid #d3d9dd;
  background-color: #f3f6f9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 200px;
}

.inputbox2 {
  border: 1px solid #d3d9dd;
  background-color: #f3f6f9;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
  width: 96%;
  height: 36px;
  line-height: 34px;
}

.inputbox3 {
  border: 1px solid #d3d9dd;
  background-color: #f3f6f9;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
  width: 90%;
  height: 36px;
  line-height: 34px;
}

.txtCenter {
  text-align: center !important;
}

.image_box2 {
  display: none;
}

.ib_on {
  display: block;
}

.doc_toggle {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
</style>