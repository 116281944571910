<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          <router-link :to="{ path: '/caption_list/' }">캡션 수정 목록</router-link>
        </h2>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <th scope="col">이미지</th>
              <th scope="col">이미지1</th>
              <th scope="col">이미지2</th>
              <th scope="col">이미지3</th>
              <th scope="col">이미지4</th>
              <th scope="col">이미지5</th>
            </thead>
            <tbody>
              <tr>
                <th>이동</th>
                <th v-for="(i, index) in img_list" :key="i">
                  <a @click="page(index + 1, true)" class="btn line2">이동하기</a>
                </th>
              </tr>
              <tr>
                <th>캡션수정여부</th>
                <td v-for="i in img_list" :key="i">
                  <span v-if="i.caption_state == 0" class="co_state0">수정 전</span>
                  <span v-if="i.caption_state == 1" class="co_state1">수정 완료</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a v-if="is_edit == 0" @click="submit_all()" class="btn bg2">수정 확정</a>
          <router-link :to="{ path: '/caption_list/' }" class="btn bg">목록으로</router-link>
        </div>
        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title">POI정보</h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr v-for="info in poi_info" :key="info">
                    <th scope="row">{{ info[0] }}</th>
                    <td style="word-break: break-all">{{ info[1] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="list_box">
            <h2 class="h_title">이미지{{ img_page }} 캡션 등록</h2>
            <div class="qna_img_box">
              <img v-if="img_list.length > 0" :src="'http://' + img_list[img_page - 1].img_url" alt="" />
              <div class="btn_box">
                <a @click="page(-1)" class="prev">
                  <i class="fa-solid fa-circle-chevron-left"></i>
                </a>
                <a @click="page(1)" class="next">
                  <i class="fa-solid fa-circle-chevron-right"></i>
                </a>
              </div>
            </div>
            <!-- table_type -->

            <div class="table_type2">
              <table>
                <colgroup>
                  <col width="8%" />
                  <col />
                  <col width="12%" />
                </colgroup>
                <tbody v-if="img_list.length > 0">
                  <tr>
                    <th>캡션1</th>
                    <td colspan="2">
                      <input class="inputbox" id="caption1" v-model="img_list[img_page - 1].caption1" />
                    </td>
                  </tr>
                  <tr>
                    <th>캡션2</th>
                    <td colspan="2">
                      <textarea class="textareabox" id="caption2" v-model="img_list[img_page - 1].caption2"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
              <a v-if="is_edit == 0" @click="submit()" class="btn bg2">캡션 등록</a>
            </div>
          </div>

        </div>
      </div>
    </div>
</div>
</template>

<script>




import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
// import QA_Reject from "../components/QA_Reject.vue";

export default {
  name: "CaptionView",
  data() {
    return {
      triple: [],
      new_triple: [],
      concat_triple: [],
      poi_info: "",
      img_list: [],
      img_page: 1,
      current_image: "",
      is_submit: false,
      q_category: [],
      a_state: 0,
      a_reject_reason: "",
      a_confirm: 0,
      pre_q: [], // 미리 준비된 질문, DB에서 가져옴

      reject_flag: false,
      qna: "aa",
      can_request: 0,
      qc_reject_reason: "",
      ac_reject_reason: "",
    };
  },
  components: {
    Header,
    // QA_Reject
  },
  methods: {
    all_load() {
      // 전체 정보 불러오기 : poi, image, qna
      axios({
        url: config.apiurl + "?part=caption&mode=caption_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          no: this.$route.params.no,
        },
      })
        .then((response) => {
          let d = response.data;
          console.log(d);
          this.is_edit = d.is_edit;
          this.poi_info = d.poi_info;
          this.img_list = d.image_list;
          this.can_request = d.can_request;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    page(count, to = false) {
      // 이미지 이동, to를 주면 바로 감
      if (to) {
        this.img_page = count;
      } else {
        var c = this.img_page + count;
        if (c <= 1) {
          c = 1;
        } else if (c >= 5) {
          c = 5;
        }

        this.img_page = c;
      }
    },
    submit() {
      // 답변 등록
      let img_no = this.img_list[this.img_page - 1].no;
      let caption1 = this.img_list[this.img_page - 1].caption1;
      let caption2 = this.img_list[this.img_page - 1].caption2;

      if (caption1.trim() == '' || caption2.trim() == '') {
        alert("캡션1 또는 캡션2에 빈칸이 있습니다.");
      } else {
        if (confirm("현재 이미지의 캡션 수정 내용을 등록하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=caption&mode=caption_upload",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              no: this.$route.params.no,
              img_no: img_no,
              caption1: caption1,
              caption2: caption2,
            },
          }).then((response) => {
            let d = response.data;
            console.log(d);

            this.all_load();
            if (this.img_page < 5) {
              this.page(1);
            }
          }).catch(function (error) {
            alert("오류가 발생했습니다...(2)");
            console.log("에러야!");
            console.log(error);
          });
        }
      }
    },
    submit_all() {
      // 등록확정
      if (this.can_request != 1) {
        if(this.can_request == 0){
          alert("5개 이미지의 캡션을 모두 등록해야 수정을 확정할수 있습니다.");
        }else if(this.can_request == -1){
          alert("5개 이미지 중 캡션1 또는 캡션2에 빈칸이 있습니다.");
        }
      } else {
        if (confirm("캡션 수정 내용을 확정하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=caption&mode=caption_state",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              no: this.$route.params.no,
              state: 1,
            },
          })
            .then((response) => {
              console.log(response.data);
              alert("수정 확정 되었습니다.");
              this.$router.push({ name: "CaptionList" });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(3)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    // select_check(qna_cate, index) {
    //   // 질문카테고리 체크시 추가
    //   var q = document.getElementsByName("qc" + index);
    //   var clist = [];
    //   q.forEach((e) => {
    //     if (e.checked) {
    //       clist.push(e.value);
    //     }
    //   });
    //   var cate = clist.join(",");

    //   var qna = this.img_list[this.img_page - 1].qna;
    //   qna[index].cate = cate;
    // },
    // check_qc(qc, qna_cate) {
    //   // 질문카테고리 체크
    //   if (qna_cate.indexOf(qc) > -1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // reject_toggle() { // 반려사유 토글
    //   if (this.can_request == 1) {
    //     this.reject_flag = !this.reject_flag;
    //     if (this.reject_flag) {
    //       this.$refs.rejectPop.load_reject(this.qna_no);
    //     }
    //   } else {
    //     alert("답변을 모두 등록한 후에 사용해 주세요.");
    //   }
    // },
    // set_q(e, qna_key) {
    //   // 준비된 질문 선택
    //   let val = e.target.value;
    //   let v = val.split(",,")

    //   this.img_list[this.img_page - 1].qna.forEach((qna) => {
    //     if (qna.key == qna_key) {
    //       qna.q = v[0];
    //     }
    //   });
    //   var qna = this.img_list[this.img_page - 1].qna;
    //   qna[v[2]].cate = v[1];
    // },
    // select_triple(event, index, none = false) {
    //   // 트리플 체크시 추가
    //   let triple = "0";
    //   if (!none) { // 선택안함 말고 다른거 체크
    //     triple = event.target.value;
    //     // var t = document.getElementsByName("triple" + index);
    //     // var tlist = [];
    //     // t.forEach((e) => {
    //     //   if (e.checked) {
    //     //     tlist.push(e.value);
    //     //   }
    //     // });
    //     // triple = tlist.join(",");
    //   }

    //   // console.log(triple)

    //   var qna = this.img_list[this.img_page - 1].qna;
    //   qna[index].triple = triple;
    // },
    // check_triple(t, triple_list) {
    //   // 트리플 체크
    //   if (triple_list.indexOf(t) > -1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 82);
    this.all_load();
  },
};
</script>