<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          <router-link :to="{ path: '/final_qna_list/' }">최종 Q&A 수정 목록</router-link>
        </h2>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <th scope="col">이미지</th>
              <th scope="col">이미지1</th>
              <th scope="col">이미지2</th>
              <th scope="col">이미지3</th>
              <th scope="col">이미지4</th>
              <th scope="col">이미지5</th>
            </thead>
            <tbody>
              <tr>
                <th>이동</th>

                <th v-for="(i, index) in img_list" :key="i">
                  <!-- <a v-if="i.state == 1" @click="page(index + 1, true)" class="btn">작성완료</a> -->
                  <a @click="page(index + 1, true)" class="btn line2">이동하기</a>

                  <!-- <p v-if="i.a_confirm == 1" style="color: blue">[승인]</p>
                  <p v-if="i.a_confirm == -1" style="color: red">[반려]</p> -->
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <!-- <a v-if="a_state == 1 && view_flag > 0" @click="reject_toggle()" class="btn bg">오류체크</a> -->
          <a @click="submit_all()" class="btn bg2">수정 확정</a>
          <router-link :to="{ path: '/final_qna_list/' }" class="btn bg">목록으로</router-link>
        </div>
        <div class="fx-5">
          <div class="list_box">
            <h3 class="mgT10 h_title">Q&A 오류체크 사유</h3>
            <div class="box_type1">
              <span v-if="qc_reject_reason != ''">{{ qc_reject_reason }}<br></span>
              <span v-if="ac_reject_reason != ''">{{ ac_reject_reason }}</span>
            </div>
            <h2 class="h_title mgT30">POI정보</h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr v-for="info in poi_info" :key="info">
                    <th scope="row">{{ info[0] }}</th>
                    <td style="word-break: break-all">{{ info[1] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->
            <div class="view_top mgT30">
              <h2 class="h_title">
                <span>Triple</span>
              </h2>
            </div>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>A</th>
                    <th>R</th>
                    <th>B</th>
                  </tr>
                  <tr v-if="triple.length == 0">
                    <td colspan="3" style="text-align: center">
                      등록된 Triple이 없습니다.
                    </td>
                  </tr>
                  <tr v-for="t in triple" :key="t" style="word-break: break-all">
                    <td>{{ t.a }}</td>
                    <td>{{ t.r }}</td>
                    <td>{{ t.b }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="view_top mgT30">
              <h2 class="h_title">
                <span>New Triple</span>
              </h2>
            </div>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>A</th>
                    <th>R</th>
                    <th>B</th>
                  </tr>
                  <tr v-if="new_triple.length == 0">
                    <td colspan="3" style="text-align: center">
                      등록된 New_Triple이 없습니다.
                    </td>
                  </tr>
                  <tr v-for="t in new_triple" :key="t" style="word-break: break-all">
                    <td>{{ t.a }}</td>
                    <td>{{ t.r }}</td>
                    <td>{{ t.b }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="list_box">
            <h2 class="h_title">이미지{{ img_page }} Q&A 등록</h2>
            <div class="qna_img_box">
              <img v-if="img_list.length > 0" :src="'http://' + img_list[img_page - 1].img_url" alt="" />
              <div class="btn_box">
                <a @click="page(-1)" class="prev">
                  <i class="fa-solid fa-circle-chevron-left"></i>
                </a>
                <a @click="page(1)" class="next">
                  <i class="fa-solid fa-circle-chevron-right"></i>
                </a>
              </div>
            </div>
            <!-- table_type -->

            <div class="mgT20" v-if="img_list.length > 0">
              <!-- <div class="confirmer_say" v-if="a_confirm != 0">
                <h2 class="h_title"><span>검수자의 한마디</span></h2>
                <textarea class="textareabox" v-model="a_reject_reason" style="height: 70px" readonly></textarea>
              </div> -->
              <!-- <div class="reaject_reason mgT20" v-if="img_list[img_page - 1].a_confirm == -1">
                <h2 class="h_title">
                  <span style="color: red">반려사유</span>
                </h2>
                <textarea class="textareabox" v-model="img_list[img_page - 1].a_reject_reason" style="height: 70px"
                  readonly></textarea>
              </div> -->
            </div>

            <div class="view_top mgT30" v-if="img_list.length > 0">
              <h2 class="h_title">
                <span>캡션&질문 및 답변</span>
                <!-- <span v-if="img_list[img_page - 1].a_confirm == 1" style="color: blue">[승인]</span>
                <span v-if="img_list[img_page - 1].a_confirm == -1" style="color: red">[반려]</span> -->
              </h2>
            </div>
            <div class="table_type2">
              <table>
                <colgroup>
                  <col width="8%" />
                  <col />
                  <col width="12%" />
                </colgroup>
                <tbody v-if="img_list.length > 0">
                  <tr>
                    <th>캡션1</th>
                    <td colspan="2">
                      <input class="inputbox" id="caption1" v-model="img_list[img_page - 1].caption1" />
                    </td>
                  </tr>
                  <tr>
                    <th>캡션2</th>
                    <td colspan="2">
                      <textarea class="textareabox" id="caption2" v-model="img_list[img_page - 1].caption2"></textarea>
                    </td>
                  </tr>
                  <template v-for="(qna, index) in img_list[img_page - 1].qna" :key="qna">
                    <tr>
                      <th rowspan="2">{{ qna.key }}</th>
                      <td>
                        <ul>
                          <li>
                            <select v-if="img_list[img_page - 1].q_confirm != 1" class="inputbox"
                              @change="set_q($event, qna.key)">
                              <option :value="['', , 4, , index]" selected>직접입력</option>
                              <option :value="[q.desc, , q.category, , index]" v-for="q in pre_q" :key="q">
                                {{ q.cate_desc2 }} : {{ q.desc }}
                              </option>
                            </select>
                          </li>
                          <li>
                            <input class="inputbox" v-model="qna.q" />
                            <!-- <input class="inputbox" v-model="qna.q" readonly /> -->
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li v-for="(qc, qcid) in q_category" :key="qc">
                            <input type="checkbox" :value="qc.no" :name="'qc' + index" :id="'qc' + index + qcid"
                              :checked="check_qc(qc.no, qna.cate)" @change="select_check(index)" disabled />
                            <label :for="'qc' + index + qcid" style="user-select: none">{{ qc.desc }}</label><br />
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <td colspan="2" style="position: relative">
                      <ul>
                        <!-- <span style="font-weight: 600">Triple</span>
                        <li v-for="t in qna.triple" :key="t" style="word-break:break-all;">
                          {{ t }}
                        </li> -->
                        <!-- :disabled="img_list[img_page - 1].q_confirm == 1 || q_state > 1"  -->
                        <li v-for="(t, tid) in concat_triple" :key="t">
                          <input type="radio" :value="t.no" :name="'triple' + index" :id="'triple' + index + tid"
                            :checked="check_triple(t.no, qna.triple)" @change="select_triple($event, index)" />
                          <label :for="'triple' + index + tid" style="user-select: none; word-break: break-all;">
                            <span style="color:red;" v-if="t.is_new == 1"> (신규)</span>
                            {{ t.desc }}
                          </label>
                          <br />
                        </li>
                      </ul>
                    </td>
                    <tr>
                      <th>{{ qna.akey }}</th>
                      <td colspan="2">
                        <input class="inputbox" v-model="qna.a" />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->

            <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
              <a @click="submit()" class="btn bg2">Q&A 등록</a>
              <!-- <div v-if="img_list.length > 0 && view_flag > 0">
                <template v-if="img_list[img_page - 1].a_confirm != 1">
                  <template v-if="a_state == 1">
                  </template>
                  <template v-else>
                    <router-link :to="{ path: '/answer_list/' }" class="btn bg1">확인</router-link>
                  </template>
                </template>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- //contents -->
    </div>
    <!-- <QA_Reject :reject_flag="reject_flag" :qna="qna" @close="reject_toggle" ref="rejectPop" /> -->
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
// import QA_Reject from "../components/QA_Reject.vue";

export default {
  name: "FinalQnAView",
  data() {
    return {
      qna_no: this.$route.params.no,
      triple: [],
      new_triple: [],
      concat_triple: [],
      poi_info: "",
      img_list: [],
      img_page: 1,
      current_image: "",
      is_submit: false,
      q_category: [],
      a_state: 0,
      a_reject_reason: "",
      a_confirm: 0,
      pre_q: [], // 미리 준비된 질문, DB에서 가져옴

      reject_flag: false,
      qna: "aa",
      can_request: 0,
      qc_reject_reason: "",
      ac_reject_reason: "",
    };
  },
  components: {
    Header,
    // QA_Reject
  },
  methods: {
    all_load() {
      // 전체 정보 불러오기 : poi, image, qna
      axios({
        url: config.apiurl + "?part=final&mode=final_qna_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          poi_no: this.$route.params.no,
        },
      })
        .then((response) => {
          let d = response.data;
          console.log(d);
          this.a_state = d.a_state;
          this.triple = d.triple;
          this.new_triple = d.new_triple;
          this.poi_info = d.poi_info;
          this.img_list = d.image_list;
          this.q_category = d.q_category;
          this.qc_reject_reason = d.qc_reject_reason;
          this.ac_reject_reason = d.ac_reject_reason;
          this.current_image = d.image_list[0];
          this.can_request = d.can_request;
          this.pre_q = d.pre_q;
          this.concat_triple = d.triple.concat(d.new_triple);
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    page(count, to = false) {
      // 이미지 이동, to를 주면 바로 감
      if (to) {
        this.img_page = count;
      } else {
        var c = this.img_page + count;
        if (c <= 1) {
          c = 1;
        } else if (c >= 5) {
          c = 5;
        }

        this.img_page = c;
      }
    },
    submit() {
      // 답변 등록
      let qna = this.img_list[this.img_page - 1].qna;
      let img_no = this.img_list[this.img_page - 1].no;
      let caption1 = this.img_list[this.img_page - 1].caption1;
      let caption2 = this.img_list[this.img_page - 1].caption2;

      let q_check = true; let a_check = true;
      let t_check = 0;

      qna.forEach((element) => {
        console.log(element.triple)
        this.new_triple.forEach(new_t => {
          if (new_t.no == element.triple) { t_check++; }
        });
      });

      if (t_check < 4) {
        alert("신규 트리플을 선택해주세요.");
      } else if (!q_check) {
        alert("질문을 전부 작성해 주세요.");
      } else if (!a_check) {
        alert("답변을 전부 작성해 주세요.");
      } else {
        if (confirm("현재 이미지의 Q&A수정 내용을 등록하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=final&mode=final_qna_upload",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              poi_no: this.$route.params.no,
              img_no: img_no,
              qna: qna,
              caption1: caption1,
              caption2: caption2,
            },
          })
            .then((response) => {
              let d = response.data;
              console.log(d);

              this.all_load();
              if (this.img_page == 5 && d.can_request == 1) {
                this.can_request = d.can_request;
                this.submit_all();
              } else {
                this.page(1);
              }
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(2)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    submit_all() {
      // 등록확정
      if (confirm("Q&A 수정 내용을 확정하시겠습니까?")) {
        axios({
          url: config.apiurl + "?part=final&mode=final_qna_state",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            poi_no: this.$route.params.no,
            state_qna: 1,
          },
        })
          .then((response) => {
            console.log(response.data);
            alert("등록확정 되었습니다.");
            this.$router.push({ name: "FinalQnAList" });
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(3)");
            console.log("에러야!");
            console.log(error);
          });
      }
    },
    select_check(qna_cate, index) {
      // 질문카테고리 체크시 추가
      var q = document.getElementsByName("qc" + index);
      var clist = [];
      q.forEach((e) => {
        if (e.checked) {
          clist.push(e.value);
        }
      });
      var cate = clist.join(",");

      var qna = this.img_list[this.img_page - 1].qna;
      qna[index].cate = cate;
    },
    check_qc(qc, qna_cate) {
      // 질문카테고리 체크
      if (qna_cate.indexOf(qc) > -1) {
        return true;
      } else {
        return false;
      }
    },
    // reject_toggle() { // 반려사유 토글
    //   if (this.can_request == 1) {
    //     this.reject_flag = !this.reject_flag;
    //     if (this.reject_flag) {
    //       this.$refs.rejectPop.load_reject(this.qna_no);
    //     }
    //   } else {
    //     alert("답변을 모두 등록한 후에 사용해 주세요.");
    //   }
    // },
    set_q(e, qna_key) {
      // 준비된 질문 선택
      let val = e.target.value;
      let v = val.split(",,")

      this.img_list[this.img_page - 1].qna.forEach((qna) => {
        if (qna.key == qna_key) {
          qna.q = v[0];
        }
      });
      var qna = this.img_list[this.img_page - 1].qna;
      qna[v[2]].cate = v[1];
    },
    select_triple(event, index, none = false) {
      // 트리플 체크시 추가
      let triple = "0";
      if (!none) { // 선택안함 말고 다른거 체크
        triple = event.target.value;
        // var t = document.getElementsByName("triple" + index);
        // var tlist = [];
        // t.forEach((e) => {
        //   if (e.checked) {
        //     tlist.push(e.value);
        //   }
        // });
        // triple = tlist.join(",");
      }

      // console.log(triple)

      var qna = this.img_list[this.img_page - 1].qna;
      qna[index].triple = triple;
    },
    check_triple(t, triple_list) {
      // 트리플 체크
      if (triple_list.indexOf(t) > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 76);
    this.all_load();
  },
};
</script>