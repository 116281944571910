<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">권역별 작업현황</h2>
        <router-link :to="{ path: '/region_status_day_table/' }" class="btn bg">
          일자/지역별 트리플 전송수량 보기
        </router-link>
        <!-- //table_type -->
        <div class="table_type6 mgT10">
          <table>
            <colgroup>
              <col width="8%" />
              <col width="8%" />
              <col />
              <col width="9%" />
              <col width="9%" />
              <col width="9%" />
              <col width="9%" />
              <col width="9%" />
              <col width="9%" />
            </colgroup>

            <thead>
              <tr>
                <th scope="col">권역 구분</th>
                <th scope="col">지역명</th>
                <th scope="col">플랫폼 상 주소 표기</th>
                <th scope="col">전체</th>
                <th scope="col">수정 전</th>
                <th scope="col">수정 중</th>
                <th scope="col">검수요청</th>
                <th scope="col">검수완료</th>
                <th scope="col">트리플 전송</th>
              </tr>
            </thead>

            <tbody>
              <template v-if="region_data.length == 0">
                <tr>
                  <td colspan="9" style="text-align: center;">로딩중입니다... 약 10초 내로 완료됩니다.</td>
                </tr>
              </template>
              <template v-else>
                <template v-for="r1 in region_data" :key="r1">
                  <tr v-for="(r2, index) in r1.data" :key="r2">
                    <th v-if="index == 0" :rowspan="r1.data.length">{{ r1.area }}</th>
                    <th>{{ r2.region_name }}</th>
                    <th>{{ r2.region_mark }}</th>
                    <td class="txR">{{ r2.nums.total }}</td>
                    <td class="txR">{{ r2.nums.not_work }}</td>
                    <td class="txR">{{ r2.nums.work }}</td>
                    <td class="txR">{{ r2.nums.request }}</td>
                    <td class="txR">{{ r2.nums.confirm }}</td>
                    <td class="txR">{{ r2.nums.triple_ready }}</td>
                  </tr>
                </template>
              </template>
            </tbody>

          </table>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "RegionStatus",
  data: function () {
    return {
      region_data: []
    };
  },
  components: {
    Header
  },
  methods: {
    region_status_load() {
      axios({
        url: config.apiurl + "?part=util&mode=region_status",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        // console.log(this.region_data)
        this.region_data = d.region_data;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 50);
    this.region_status_load();
  },
};
</script>
