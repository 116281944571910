<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">
          법률 문서 목록
        </h2>
        <div class="btn_box mgT10">
          <a @click="cate_change(1)" class="btn" :class="{ 'bg4': cate == 1, 'bg': cate != 1 }">판례문</a>
          &nbsp;
          <a @click="cate_change(2)" class="btn" :class="{ 'bg4': cate == 2, 'bg': cate != 2 }">결정문</a>
        </div>

        <!-- table_type -->
        <div class="table_type1 mgT20">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="9%" />
              <col width="13%" />
              <col />
              <col width="6%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">번호</th>
                <th scope="col">사건 구분</th>
                <th scope="col">문서 번호</th>
                <th scope="col">문서명</th>
                <th scope="col">번역여부</th>
                <th scope="col">관리</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(poi) in poi_list" :key="poi.no">
                <td>{{ poi.no }}</td>
                <td>{{ poi.cate }}</td>
                <td class="txL">{{ poi.doc_no }}</td>
                <td class="txL">{{ poi.doc_name }}</td>
                <td>{{ poi.is_edit }}</td>
                <td>
                  <!-- <a class="btn size1">문서보기</a> -->
                  <router-link :to="{ path: poi.link + '/' + lan + '/' + poi.no }" class="btn size1">번역작업</router-link>
                  <a class="btn size1">다운로드</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page_box mgT10">
          {{ page }} / {{ page_total }} (페이지)
        </div>
        <!-- //table_type -->
        <!-- <div class="list_bot" style="justify-content: end; gap: 5px">
          <div class="btn_box">
            <a class="btn bg4">문서 업로드</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../../components/Header.vue";

export default {
  name: "MList",
  data: function () {
    return {
      poi_list: [
        { no: 1, cate: "민사", doc_no: "2020두36687", doc_name: "부가가치세부과처분취소", is_edit: "X", link: "/m_trans_view" },
        { no: 2, cate: "민사", doc_no: "2020두52214", doc_name: "증여세경정거부처분취소", is_edit: "O", link: "/m_trans_view" },
      ],
      page: 1,
      page_total: "0000",
      cate: 1,
      lan: "en",
    };
  },
  components: {
    Header,
  },
  methods: {
    cate_change(no) {
      this.cate = no;
    },
  },
  mounted: async function () {
    this.lan = this.$route.params.lan;
  },
};
</script>


<style>
.search_wrapper {
  padding: 12px;
}

.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>