<template>
    <div id="container">
        <!-- header -->
        <Header />
        <!-- /header -->
        <div id="contents_box">
            <!-- contents -->
            <div id="contents" class="wrap_box">
                <h2 class="h_title">
                    <router-link :to="{ path: '/qna_viewer_list/' + area }">Q&A Viewer</router-link>
                </h2>

                <div class="fx-5">
                    <div class="list_box">
                        <h2 class="h_title">POI 정보</h2>

                        <div class="table_type3">
                            <table>
                                <colgroup>
                                    <col width="20%" />
                                    <col />
                                </colgroup>
                                <tbody>

                                    <tr v-for="info in poi_info" :key="info">
                                        <th scope="row">{{ info[0] }}</th>
                                        <td>{{ info[1] }}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="list_box">
                        <h2 class="h_title">Triple</h2>
                        <div class="table_type3">
                            <table>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>A</th>
                                        <th>R</th>
                                        <th>B</th>
                                    </tr>
                                    <tr v-if="triple.length == 0">
                                        <td colspan="3" style="text-align:center;">등록된 Triple이 없습니다.</td>
                                    </tr>
                                    <tr v-for="t in triple" :key="t">
                                        <td>{{ t.a }}</td>
                                        <td>{{ t.r }}</td>
                                        <td>{{ t.b }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="fx-5 mgT20">
                    <div v-for="(img, index) in img_list" :key="img" class="list_box">
                        <h2 class="h_title">이미지{{ index + 1 }}</h2>

                        <div class="qna_img_box">
                            <img class="mgT10" v-if="img.img_url != ''" :src="'http://' + img_list[index].img_url"
                                title="클릭하면 새창으로 원본을 볼 수 있습니다." onclick="window.open(this.src)"
                                style="cursor: pointer;">
                        </div>
                        <!-- table_type -->
                        <div class="table_type2 mgT20">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            [Caption1]<textarea v-if="img_list.length > 0" class="textareabox"
                                                v-model="img_list[index].caption1" readonly style="height:100px;">
                                            </textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            [Caption2]<textarea v-if="img_list.length > 0" class="textareabox"
                                                v-model="img_list[index].caption2" readonly style="height:100px;">
                                            </textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="mgT20">
                                <tbody v-for="i in 4" :key="i">
                                    <tr>
                                        <th style="word-break:break-all">{{ img_list[index].qna[i - 1].key }}. {{
                                        img_list[index].qna[i - 1].q }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style="font-weight:600;">Triple</span>
                                            <p v-for="(t, idx) in img_list[index].qna[i - 1].triple" :key="t"
                                                style="word-break:break-all">
                                                <template v-if="t == '선택안함'">
                                                    {{ t }}
                                                </template>
                                                <template v-else>
                                                    {{ idx+1 }}. {{ t }}
                                                </template>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ img_list[index].qna[i - 1].akey }}. {{ img_list[index].qna[i - 1].a }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- //table_type -->
                    </div>
                </div>

                <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
                    <router-link :to="{ path: '/qna_viewer_list/' + area }" class="btn bg">목록으로</router-link>
                </div>
            </div>
            <!-- //contents -->
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
    name: 'AnswerConfirmView',
    data() {
        return {
            img_list: [],
            triple: [],
            poi_info: [],
            area: "",
        };
    },
    components: {
        Header
    },
    methods: {
        all_load() { // 전체 정보 불러오기 : image, qna
            axios({
                url: config.apiurl + "?part=qna&mode=qna_viewer_view",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                method: "POST",
                data: {
                    token: this.$store.state.token,
                    qna_no: this.$route.params.no
                },
            }).then((response) => {
                var d = response.data;
                console.log(d);
                this.img_list = d.image_list;
                this.poi_info = d.poi_info;
                this.triple = d.triple;
            }).catch(function (error) {
                alert("오류가 발생했습니다...(3)");
                console.log("에러야!");
                console.log(error);
            });
        },
    },
    mounted: async function () {
        await common.check_perm(this.$store.state.token, 61);
        this.area = this.$route.params.area;
        this.all_load()
    }
};
</script>