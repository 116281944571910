<template>
  <div class="edit">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div class="view_top">
          <h2 class="h_title">
            <span>최종 Triple 수정</span>
          </h2>
          <a href="/final_triple_list" class="btn size1">목록보기</a>
        </div>
        <span v-if="(loading == 1)" style="color:blue;">POI 및 트리플 정보를 로딩중입니다...</span>
        <div class="fx-5 mgT30">
          <div class="list_box">
            <h2 class="h_title">POI 정보</h2>

            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr v-for="d in poi_data_list" :key="d">
                    <th scope="row">{{ d[0] }}</th>
                    <td style="word-break: break-all;">{{ d[1] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="fx-5 mgT40">
          <div class="list_box">
            <h2 class="h_title">Triple</h2>
            <span style="color:blue;">※참조중인 Triple은 삭제할 수 없으며 수정만 가능합니다.</span>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col width="20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>A</th>
                    <th>R</th>
                    <th>B</th>
                    <th></th>
                  </tr>
                  <tr v-if="(triple.length == 0 && loading == 0)">
                    <td colspan="4" style="text-align: center">
                      등록된 Triple이 없습니다.
                    </td>
                  </tr>
                  <tr v-for="t, index in triple" :key="t">
                    <td v-if="(t.delete == 0)">{{ t.a }}</td>
                    <td v-if="(t.delete == 0)">
                      <input class="inputbox" v-model="t.r">
                    </td>
                    <td v-if="(t.delete == 0)" style="word-break: break-all;">
                      <input class="inputbox" v-model="t.b">
                    </td>
                    <td v-if="(t.delete == 0)" style="text-align: center;">
                      <!-- <a @click="triple_edit(t.no)" class="btn size1">수정</a>
                      &nbsp; -->
                      <a v-if="(t.delete == 0 && t.used == 0)" @click="triple_delete(t.no, index)"
                        class="btn size1">삭제</a>
                    </td>
                  </tr>
                  <tr v-if="(loading == 0)">
                    <td colspan="3"></td>
                    <td style="text-align: center;">
                      <a @click="triple_add()" class="btn bg2 size1">추가</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <template v-if="(final_info.triple_is_edit == 0)">
            <a v-if="edited == 0" @click="submit(2)" class="btn bg0">임시저장</a>
            <a v-if="edited == 0" @click="submit()" class="btn bg1">POI수정</a>
            <span v-else>저장중입니다...</span>
          </template>
          <router-link :to="{ path: '/final_triple_list/' }" class="btn bg">확인</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "FinalTripleView",
  data() {
    return {
      triple: [],
      addkey: "",
      addvalue: "",
      poi_no: "",
      poi_info: {},
      final_info: {},
      edited: 0,
      poi_data_list: [],

      loading: 0,
    };
  },
  components: {
    Header,
  },
  methods: {
    meta_move(index, count) {
      // 항목 이동 -> 트리플에선 사용하지 않음
      if (index + count >= 0 && index + count < this.type.length) {
        [this.type[index + count], this.type[index]] = [
          this.type[index],
          this.type[index + count],
        ];
        this.json_preview();
      }
    },
    triple_add() {
      // 트리플 항목 추가
      // if (confirm("트리플 항목을 추가하시겠습니까?")) {
      this.triple.push({ "no": '0', "poi_no": this.poi_no, "a": this.poi_info.poi_name, "r": "", "b": "", "delete": 0, "used": 0, });
      // }
    },
    triple_delete(tno, index) {
      // 트리플 항목 제거

      if (confirm("해당 항목을 제거하시겠습니까?")) {
        this.triple[index]['delete'] = 1;
      }
    },
    async submit(tempsave = 0) {
      let blank_check = false;

      this.triple.forEach(t => {
        // 삭제되지 않은 triple 중 r, b에서 공란 체크
        if (t['delete'] == 0 && (t['r'].trim() == '' || t['b'].trim() == '')) blank_check = true;
      });

      if (blank_check) {
        alert("내용 중에 공란이 있습니다.");
      } else {
        let message = "트리플을 수정하시겠습니까?";
        if (tempsave != 0) {
          message = "트리플 수정내용을 임시저장 하시겠습니까?";
        }
        if (confirm(message)) {
          this.edited = 1;

          axios({
            url: config.apiurl + "?part=final&mode=final_triple_edit_submit",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              poi_no: this.poi_no,
              tempsave: tempsave,
              triple: this.triple,
            },
          })
            .then((response) => {
              let d = response.data;
              console.log(d);
              alert("수정되었습니다.");
              this.edited = 0;
              this.$router.push({ name: "FinalTripleList" });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(1)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }

    },
    load_data() {
      this.loading = 1;
      axios({
        url: config.apiurl + "?part=final&mode=final_triple_view", // 기존 poi 정보불러오기와 동일
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          poi_no: this.poi_no,
        },
      })
        .then((response) => {
          let d = response.data;
          console.log(d);
          this.poi_info = d.poi_info;
          let lists = d.list;
          for (let i = 0; i < lists.length; i++) {
            if (lists[i][0] == "관광타입") {
              this.ttype = lists[i][1];
              lists.splice(i, 1);
            } else if (lists[i][0] == "주소") {
              this.origin_address = lists[i][1];
            }
          }
          this.poi_data_list = lists;
          this.triple = d.triple;
          this.final_info = d.final_info;
          this.loading = 0;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 74);
    this.final_no = this.$route.params.no;
    this.poi_no = this.$route.params.no;
    this.load_data();

  },
};
</script>
