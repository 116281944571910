<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div style="float: right">
          <a v-if="csv == true" @click="download_to_csv('table1')" class="btn bg4">CSV로 받기</a>
          &nbsp;
          <router-link :to="{ path: '/mypage/' }" class="btn bg2">목록으로</router-link>
        </div>
        <h2 class="h_title">영어 번역: 일자별 검수량</h2>
        <h2 class="h_title2 mgT30">영어 번역 검수자</h2>

        <select type="selectbox" class="selectbox mgT20" style="width:200px;">
          <option value="" selected disabled>월 선택</option>
          <option :value="[m.value, m.start, m.end]" v-for="m in month_list" :key="m">{{ m.desc }}</option>
        </select>

        <div class="table_type1 scrollx mgT10" id="table1">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col />
            </colgroup>
            <thead class="headfix">
              <tr>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">합계</th>
                <th scope="col" v-for="day in full_days" :key="day">{{ full_to_md(day) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="member_list.length == 0">
                <td :colspan="full_days.length + 3">
                  <span>로딩중입니다... 잠시만 기다려 주세요.</span>
                </td>
              </tr>
              <tr v-for="(m, index) in member_list" :key="m.no">
                <td>{{ member_numbering(index) }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>
                <td>{{ m.total_confirm }}</td>
                <td v-for="full_day in full_days" :key="full_day">{{ get_count(m.days, full_day) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Header from "../../components/Header.vue";
// import common from "../../common/common.js";
// import config from "../../common/config.js";

export default {
  name: "PoiAssignConfirm",
  data: function () {
    return {
      member_list: [
        {
          "no": "51",
          "username": "영어검수자1",
          "userid": "transConfirmer01",
          "days": [
            {
              "day": "2022-08-04",
              "count": "151"
            },
            {
              "day": "2022-08-05",
              "count": "152"
            },
            {
              "day": "2022-08-08",
              "count": "160"
            },
            {
              "day": "2022-08-09",
              "count": "160"
            },
            {
              "day": "2022-08-10",
              "count": "160"
            },
            {
              "day": "2022-08-11",
              "count": "160"
            },
            {
              "day": "2022-08-12",
              "count": "160"
            },
            {
              "day": "2022-08-16",
              "count": "160"
            },
            {
              "day": "2022-08-17",
              "count": "161"
            },
            {
              "day": "2022-08-18",
              "count": "130"
            },
            {
              "day": "2022-08-19",
              "count": "152"
            },
            {
              "day": "2022-08-22",
              "count": "140"
            },
            {
              "day": "2022-08-23",
              "count": "151"
            },
            {
              "day": "2022-08-24",
              "count": "153"
            },
            {
              "day": "2022-08-25",
              "count": "152"
            },
            {
              "day": "2022-08-26",
              "count": "155"
            },
            {
              "day": "2022-08-29",
              "count": "155"
            },
            {
              "day": "2022-08-30",
              "count": "155"
            },
            {
              "day": "2022-08-31",
              "count": "155"
            }
          ],
          "total_confirm": "11108"
        },
        {
          "no": "53",
          "username": "영어검수자2",
          "userid": "transConfirmer02",
          "days": [
            {
              "day": "2022-08-09",
              "count": "152"
            },
            {
              "day": "2022-08-10",
              "count": "154"
            },
            {
              "day": "2022-08-11",
              "count": "152"
            },
            {
              "day": "2022-08-12",
              "count": "152"
            },
            {
              "day": "2022-08-16",
              "count": "152"
            },
            {
              "day": "2022-08-17",
              "count": "152"
            },
            {
              "day": "2022-08-18",
              "count": "152"
            },
            {
              "day": "2022-08-19",
              "count": "152"
            },
            {
              "day": "2022-08-22",
              "count": "152"
            },
            {
              "day": "2022-08-23",
              "count": "152"
            },
            {
              "day": "2022-08-24",
              "count": "152"
            },
            {
              "day": "2022-08-25",
              "count": "152"
            },
            {
              "day": "2022-08-26",
              "count": "152"
            },
            {
              "day": "2022-08-29",
              "count": "152"
            },
            {
              "day": "2022-08-30",
              "count": "152"
            },
            {
              "day": "2022-08-31",
              "count": "133"
            }
          ],
          "total_confirm": "15124"
        }
      ],
      full_days: [],
      days: [],
      month_list: [
        { "desc": "2022년 8월", "start": "2022-08-04", "end": "2022-08-31", "value": "2022-08" },
        // { "desc": "2022년 9월", "start": "2022-09-01", "end": "2022-09-30", "value": "2022-09" },
        // { "desc": "2022년 10월", "start": "2022-10-01", "end": "2022-10-31", "value": "2022-10" },
        // { "desc": "2022년 11월", "start": "2022-11-01", "end": "2022-11-30", "value": "2022-11" },
        // { "desc": "2022년 12월", "start": "2022-12-01", "end": "2022-12-31", "value": "2022-12" },
        // { "desc": "2023년 1월", "start": "2023-01-01", "end": "2023-01-31", "value": "2023-01" },
      ],
      now_month: {
        "desc": "",
        "value": "",
        "start": "",
        "end": "",
      },
      csv: false,
    };
  },
  components: {
    Header,
  },
  methods: {
    month_change(e) { // 월 selectbox 체인지이벤트
      let values = e.target.value.split(",");

      this.now_month.value = values[0];
      this.now_month.start = values[1];
      this.now_month.end = values[2];


      this.member_list = [];
      this.full_days = [];
      this.days = [];
      this.member_list_load();
    },
    // async member_list_load() {
    //   var month = '2022-08';

    //   if (this.now_month.value == "") {
    //     await this.get_date('2022-08-04', '2022-08-31');
    //   } else {
    //     await this.get_date(this.now_month.start, this.now_month.end)
    //     month = this.now_month.value;
    //   }
    //   axios({
    //     url: config.apiurl + "?part=poi&mode=poi_assign_day_table",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //     method: "POST",
    //     data: {
    //       token: this.$store.state.token,
    //       month: month,
    //     },
    //   }).then((response) => {
    //     var d = response.data;
    //     console.log(d);
    //     this.member_list = d.list;
    //     if (d.mno == 1 || d.mno == 2) this.csv = true;
    //   }).catch(function (error) {
    //     alert("오류가 발생했습니다...(2)");
    //     console.log("에러야!");
    //     console.log(error);
    //   });
    // },
    member_numbering(num) {
      return this.member_list.length - num;
    },
    full_to_md(date) {
      return date.toString().substr(5);
    },
    get_count(arr, e) {
      let count = this.is_in(arr, e);
      return count;
    },
    is_in(arr, e) {
      var count = 0;
      arr.forEach(a => {
        if (a['day'] == e) {
          count = a['count'];
        }
      });
      return count;
    },
    get_date(s, l) {
      return new Promise((resolv) => {
        // 8월 4일부터 시작
        // let today = new Date();
        // let month = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
        // let day = today.getDate() < 10 ? '0' + (today.getDate()) : (today.getDate());
        // let lastDate = today.getFullYear() + '-' + month + '-' + day;

        let full_days = this.getDatesStartToLast(s, l);
        this.full_days = full_days;
        resolv();
      })
    },
    getDatesStartToLast(startDate, lastDate) {
      var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      if (!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      var result = [];
      var curDate = new Date(startDate);
      while (curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    },
    download_to_csv(table_id, separator = ',') {
      // Select rows from table_id
      var rows = document.querySelectorAll('#' + table_id + ' tr');
      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll('td, th');
        for (var j = 0; j < cols.length; j++) {
          // Clean innertext to remove multiple spaces and jumpline (break csv)
          var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
          // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
          data = data.replace(/"/g, '""');
          // Push escaped string
          row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
      }
      var csv_string = csv.join('\n');
      // Download it
      var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
      var link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + '\ufeff' + encodeURIComponent(csv_string));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted: async function () {
    await this.get_date('2022-08-04', '2022-08-31');
    // await common.check_perm(this.$store.state.token, 12);
    // this.member_list_load();
    // var a = this.test();
    // console.log(a);
  },
};
</script>


<style>
.headfix {
  position: sticky;
  top: 0px;
}
</style>