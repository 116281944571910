<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">
          <router-link :to="{ path: '/redeem2_list' }">번역 데이터보완 목록</router-link>
        </h2>
        <span style="color:blue"> 수정은 제한없이 할수 있으며 수정일시는 마지막에 수정한 시간으로 업데이트됩니다. </span>

        <div class="btn_box mgT10">
          <a v-for="t in page_option.type_filter" :key="t" v-bind:class="{ 'btn bg4': t.value, 'btn bg': !t.value }"
            style="margin-right:8px;" @click="toggle_type(t.type)">
            {{ t.desc }}
          </a>&nbsp;
        </div>

        <div class="search_wrapper">
          <input type="number" class="inputbox" style="width:260px;" v-model="page_option.search_poi"
            placeholder="POI 번호로 검색" />
          &nbsp;
          <a @click="doSearch2()" class="btn bg">검색</a>
        </div>
        <div class="search_wrapper">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="6%" />
              <col width="6%" />
              <col />
              <col />
              <col width="6%" />
              <col width="7%" />
              <col width="8%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">번호</th>
                <th scope="col">POI 번호</th>
                <th scope="col">언어</th>
                <th scope="col">이름</th>
                <th scope="col">주소</th>
                <th scope="col">수정 여부</th>
                <th scope="col">수정일시</th>
                <th scope="col">관리</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="poi_list.length == 0">
                <td colspan="8">
                  <span>{{ search_now }}로 검색된 결과가 없습니다</span>
                </td>
              </tr>
              <tr v-for="(poi, index) in poi_list" :key="poi.no">
                <td>{{ poi_numbering(index) }}</td>
                <td>{{ poi.no }}</td>
                <td>{{ poi.lan_desc }}</td>
                <td class="txL">{{ poi.poi_name }}</td>
                <td class="txL">{{ poi.poi_address }}</td>
                <td>{{ poi.is_edit == 1 ? "✓" : "" }}</td>
                <td>{{ poi.edit_date != null ? poi.edit_date : "" }}</td>
                <td>
                  <router-link :to="{ path: '/redeem2_view/' + poi.r2no }" class="btn size1">번역 수정</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>
        <!-- //table_type -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "redeem22List",
  data: function () {
    return {
      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,
      page: 1,
      page_total: 1,
      limit: 20,

      page_option: {
        search_poi: "",
        type_filter: [
          { type: "en", desc: "영어", value: false },
          { type: "cht", desc: "중국어(번체)", value: false },
          { type: "chs", desc: "중국어(간체)", value: false },
          { type: "ja", desc: "일본어", value: false },
        ],
      },

      poi_num: 1,
      poi_list: [],
    };
  },
  components: {
    Header,
  },
  methods: {
    toggle_type(t) {
      this.page_option.type_filter.forEach(filter => {
        if (filter.type == t) {
          filter.value = !filter.value;
        }
      });
      this.page = 1;
      this.list_load();
    },
    doAdressSearch(e) {
      this.address_search(e.target.value);
    },
    paging(count, to = false) {
      // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.list_load();
    },
    poi_numbering(num) {
      // POI 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - (this.page - 1) * this.limit - num;
      } else {
        return this.poi_num - (this.page_total - 1) * this.limit - num;
      }
    },
    list_load(poi_no = 0) {
      this.poi_list = [];
      let search = "";
      if (this.search !== "") {
        search = this.search;
      }
      if (this.page_option.search_poi != "") {
        poi_no = this.page_option.search_poi;
      }

      axios({
        url: config.apiurl + "?part=redeem2&mode=redeem2_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          poi_no: poi_no,
          search: search,
          page: this.page,
          limit: this.limit,
          type_filter: this.page_option.type_filter,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.poi_list = response.data.list;
          this.poi_num = response.data.poi_num;
          this.page_total = response.data.page_total;

          common.set_page_info(this, this.$route.name, this.page, search, this.page_option);
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log(error);
        });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.list_load();
      }, 250);
    },
    doSearch2() {
      this.list_load(this.page_option.search_poi);
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 451);
    await common.set_page(this);

    this.list_load();
  },
};
</script>


<style>
.search_wrapper {
  padding: 12px;
}

.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>