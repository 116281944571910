<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <p class="h_title">90-1. 법률 문서 정제 작업</p>

        <div class="list_bot" style="justify-content: end;">
          <a @click="submit(0)" class="btn bg0 right">임시저장</a>
          <a @click="submit(1)" class="btn bg">정제완료</a>
          <router-link class="btn bg2" :to="{ path: '/m_edit_list' }">목록</router-link>
        </div>

        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title">판례문</h2>
            <div>
              <div class="image_box" style="border:1px solid #ced3d9">
                <img style="width:100%;" :src=" require('../../../public/images/2022다305861판결-1.png')" alt="">
                <img style="width:100%;" :src=" require('../../../public/images/2022다305861판결-2.png')" alt="">
              </div>
            </div>
          </div>
          <div class="list_box">
            <h2 class="h_title">법률 문서</h2>
            <!-- table_type -->
            <div class="table_type3 mgT20">
              <table>
                <colgroup>
                  <col width="12%" />
                  <col />
                  <col width="12%" />
                </colgroup>
                <tbody>

                  <tr v-for="(data, index) in datas[edit_no]" :key="data">
                    <th scope="row">
                      {{ index }}
                      <template v-if="index == '법률2'">
                        <a class="btn bg2">추가</a>
                        <a class="btn bg3">제거</a>
                      </template>
                    </th>
                    <td>
                      <div style="width:100%">
                        <input v-for="d in data.ko" :key="d" class="inputbox" :value="d.value" readonly>
                      </div>
                    </td>
                    <td>
                      <a class="btn bg">삭제</a>
                      <a class="btn bg2">추가</a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a class="btn bg0">임시저장</a>
          <a class="btn bg">작업완료</a>
          <router-link class="btn bg2" :to="{ path: '/m_edit_list' + '/' + lan }">목록</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import Header from "../../components/Header.vue";

export default {
  name: 'MEditView',
  data() {
    return {
      lan: "en",
      lan_ko: "영어",
      edit_no: 1,
      col_preset: {
        "ko": {
          "판례": "판례",
          "소송사건": "소송사건",
          "법률1": "법률1",
          "유사법률1": "유사법률1",
          "법률2": "법률2",
          "유사법률2": "유사법률2",
          "법원": "법원",
          "주문": "주문",
        },
      },
      datas: {
        "1": {
          "판례": {
            "ko": [
              {
                "key": 0,
                "value": `대법원 2023. 5. 18.선고 2022다30581 판결`,
              },
              {
                "key": 0,
                "value": `공2023하,1086`,
              },
            ],
          },
          "소송사건": {
            "ko": [
              {
                "key": 0,
                "value": `① 원고 산하 ○○○○선거관리위원회 가 2015. 11. 17. 피고에게 2014. 6. 4. 자 ○○○○교육감선거 와 관련하여 반환한 기탁금 및 보전하여 준 선거비용 합계 1,078,536,677원(이하 ‘이 사건 기탁금 등’이라 한다)을 공직선거법 제265조의2 에 따라 고지일로부터 30일 이내에 반환할 것을 요청하는 고지서를 발송한 사실`,
              },
              {
                "key": 0,
                "value": `② 위 고지서가 2015. 11. 30. 피고에게 도달되었음에도 이 사건 기탁금 등이 납부기한 내에 반환되지 아니하자, 원고 산하 ○○○○선거관리위원회 는 피고 주소지의 관할세무서장에게 징수를 위탁하였으나 역시 징수되지 않은 사실`,
              },
              {
                "key": 0,
                "value": `③ 원고는 2020. 8. 3. 이 사건 기탁금 등 반환채권에 관한 소멸시효의 중단을 위하여 이 사건 소를 제기한 사실`,
              },
            ],
          },
          "법률1": {
            "ko": [
              {
                "key": 0,
                "value": `공직선거법 제263조`,
              },
              {
                "key": 0,
                "value": `① 제122조(선거비용제한액의 공고)의 규정에 의하여`,
              },
              {
                "key": 0,
                "value": `② 「정치자금법」 제49조(선거비용관련 위반행위에 `,
              },
            ],
          },
          "유사법률1": {
            "ko": [
              {
                "key": 0,
                "value": `공직선거법 제265조`,
              },
            ],
          },
          "법률2": {
            "ko": [
              {
                "key": 0,
                "value": `공직선거법 제 264조`,
              },
              {
                "key": 0,
                "value": `당선인이 당해 선거에 있어 이 법에 규정된 죄 또는`,
              },
            ],
          },
          "유사법률2": {
            "ko": [
              {
                "key": 0,
                "value": `공직선거법 제268조`,
              },
            ],
          },
          "법원": {
            "ko": [
              {
                "key": 0,
                "value": `대법원`,
              },
            ],
          },
          "주문": {
            "ko": [
              {
                "key": 0,
                "value": `상고를 기각한다.`,
              },
              {
                "key": 0,
                "value": `상고비용은 피고가 부담한다.`,
              },
            ],
          },
        },
      },
    };
  },
  components: {
    Header
  },
  methods: {
    submit(no) {
      if (no == 0) {
        if (confirm("임시저장 하시겠습니까?")) {
          this.$router.push({ name: "MEditList" });
        }
      } else if (no == 1) {
        if (confirm("정제완료 처리 하시겠습니까?")) {
          this.$router.push({ name: "MEditList" });
        }
      }
    }
  },
  mounted: async function () {
    // this.edit_no = this.$route.params.no;
    this.edit_no = 1;
  }
};
</script>



<style>
.textareabox2 {
  border: 1px solid #d3d9dd;
  background-color: #f3f6f9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 200px;
}
</style>