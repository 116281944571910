<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          번역 POI 오류 분류현황
        </h2>

        <div class="btn_box mgT10">
          <a v-for="t in page_option.type_filter" :key="t" v-bind:class="{ 'btn bg4': t.value, 'btn bg': !t.value }"
            style="margin-right:8px;" @click="toggle_type(t.type)">
            {{ t.type }}
          </a>&nbsp;
          <a v-bind:class="{ 'btn bg4': page_option.is_reject.value, 'btn bg': !page_option.is_reject.value }"
            style="margin-right:8px;" @click="toggle_is_reject(page_option.is_reject.value)">
            {{ page_option.is_reject.type }}
          </a>&nbsp;
        </div>

        <!-- <div id="options" style="width:430px; display:flex; justify-content: space-between;"> -->
        <!-- 매니저 이상은 그룹별로 볼 수 있음. -->
        <!-- <div v-if="role >= 2 && g_list.length > 0" @change="group_change($event);">
            <select type="selectbox" class="selectbox" style="width:200px;">
              <option value="" selected disabled>그룹 선택</option>
              <option value="">전체</option>
              <option v-for="g in g_list" :key="g.no" :value="g.no">{{ g.group_name }}</option>
            </select>
          </div>
        </div> -->

        <div class="search_wrapper mgT20">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>

        <!-- table_type -->
        <div class="table_type1 mgT20">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="6%" />
              <col />
              <col />
              <col width="11%" />
              <col width="5%" />
              <col width="5%" />
              <col width="11%" />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">POI 번호</th>
              <th scope="col">이름</th>
              <th scope="col">주소</th>
              <th scope="col">번역자(ID)</th>
              <th scope="col">번역상태</th>
              <th scope="col">POI상태</th>
              <th scope="col">반려자(ID)</th>
              <th scope="col">반려날짜</th>
              <th scope="col">오류사유</th>
              <th scope="col">오류체크</th>
            </thead>
            <tbody>
              <tr v-if="trans_list.length == 0">
                <td colspan="11">번역 기록이 없습니다.</td>
              </tr>

              <!-- @click="to_view(t.no)" style="cursor:pointer;" -->
              <tr v-for="(t, index) in trans_list" :key="t.no">
                <td>{{ trans_numbering(index) }}</td>
                <td>{{ t.poi_no }}</td>
                <td class="txL">{{ t.poi_name }}</td>
                <td class="txL">{{ t.poi_address }}</td>
                <td>{{ t.t_username }}({{t.t_userid}})</td>
                <td>
                  <span v-if="t.state == 0" class="co_state">등록중</span>
                  <span v-else-if="t.state >= 1" class="co_state1">등록완료</span>
                </td>
                <td>
                  <span v-if="t.is_reject == 1" style="color:red;">오류</span>
                  <span v-else-if="t.is_reject == 0" style="color:blue;">정상</span>
                </td>
                <td>
                  <span v-if="t.r_username != null">
                    {{ t.r_username }}({{t.r_userid}})
                  </span>
                </td>
                <td>
                  <span v-if="t.reject_date > '2022-01-01'">
                    {{ t.reject_date }}
                  </span>
                </td>
                <td>
                  {{t.reject_reason}}
                </td>
                <td><a @click="reject_toggle(t.no)" class="btn bg">오류체크</a></td>
              </tr>

            </tbody>
          </table>
        </div>
        <!-- //table_type -->
        <div class="page_box mgT10" style="width:500px;">
          <div style="float:left;">
            page : <input type="text" class="page" v-model="page" @input="paging($event, true)"> / {{ page_total }}
            &nbsp;
            <a @click="paging(-1)" class="btn size1">이전</a>
            &nbsp;
            <a @click="paging(1)" class="btn size1">다음</a>
          </div>
          <div style="float:right; ">
            <span>한페이지 게시물 수 : </span>
            <select class="selectbox" v-model="page_option.limit" style="width:80px;" @change="limit_set($event)">
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </div>
      <!-- //contents -->
    </div>
    <Trans_Reject :reject_flag="reject_flag" @close="reject_toggle" ref="rejectPop" />
  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
import Trans_Reject from "../components/Trans_Reject.vue";

export default {
  name: 'TransErrorCheckList',
  data() {
    return {
      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,
      language: this.$route.params.lan,
      page: 1,
      page_total: 1,
      poi_num: 1,
      trans_list: [],
      role: 0,
      page_option: {
        type_filter: [
          { type: "영어", lan: "en", value: false },
          { type: "중국어(번체)", lan: "cht", value: false },
          { type: "중국어(간체)", lan: "chs", value: false },
          { type: "일본어", lan: "ja", value: false },
        ],
        is_reject: {
          type: "오류 POI만 보기",
          value: false,
        },
        limit: 10,
        gno: "",
        need_work: false,
      },
      group_access: 1, // 그룹 접근권한(매니저)
      work: 0,
      assign: 0,
      work_rate: 0,
      reject_flag: false,
    };
  },
  components: {
    Header, Trans_Reject
  },
  methods: {
    toggle_is_reject() {
      this.page_option.is_reject.value = !this.page_option.is_reject.value;
      this.page = 1;
      this.trans_list_load();
    },
    toggle_type(t) {
      this.page_option.type_filter.forEach(filter => {
        if (filter.type == t) {
          filter.value = !filter.value;
        }
      });
      this.page = 1;
      this.trans_list_load();
    },
    need_work_change() {
      this.page = 1;
      this.page_total = 1;
      this.poi_num = 1;
      this.trans_list_load();
    },
    to_view(tno) { // view 들어가기
      this.$router.push({
        name: "TransView",
        params: {
          tno: tno,
        }
      });
    },
    limit_set() { // 한페이지 게시물 수
      this.page = 1;
      this.page_total = 1;
      this.poi_num = 1;
      this.trans_list_load();
    },
    group_change(e) { // 그룹별 보기 selectbox
      // 페이지 초기화
      this.page = 1;
      this.page_total = 1;
      this.poi_num = 1;

      // 그룹 이동
      let gno = e.target.value;
      this.page_option.gno = gno;
      this.trans_list_load();
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.trans_list_load();
    },
    trans_numbering(num) { // POI 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - ((this.page - 1) * this.page_option.limit) - num;
      } else {
        return this.poi_num - ((this.page_total - 1) * this.page_option.limit) - num;
      }
    },
    trans_list_load() {
      let search = "";
      if (this.search !== "") {
        search = this.search;
      }

      axios({
        url: config.apiurl + "?part=trans&mode=trans_error_check_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          language: this.language,
          page: this.page,
          limit: this.page_option.limit,
          gno: this.page_option.gno,
          search: search,
          type_filter: this.page_option.type_filter,
          is_reject: this.page_option.is_reject.value,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.trans_list = d.list;
        this.poi_num = d.poi_num;
        this.page_total = d.page_total;
        this.role = d.role;
        this.g_list = d.g_list;
        this.group_access = d.group_access;
        common.set_page_info(this, this.$route.name, this.page, search, this.page_option);
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.trans_list_load();
      }, 250);
    },
    reject_toggle(qna_no) {
      // 반려사유 토글
      this.reject_flag = !this.reject_flag;
      if (this.reject_flag) {
        this.$refs.rejectPop.load_reject(qna_no);
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 57);
    await common.set_page(this);
    this.trans_list_load();
  }
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  ;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>