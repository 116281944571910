<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">
          그룹관리(<span v-if="language == 'en'">영어</span>
          <span v-else-if="language == 'ja'">일본어</span>
          <span v-else-if="language == 'chs'">중국어(간체)</span>
          <span v-else-if="language == 'cht'">중국어(번체)</span>
          <span v-else>미지원 언어</span>) 목록
        </h2>
        <div v-if="is_expert == 1" class="btn_box mgT10">
          <router-link class="btn bg" :to="{ path : '/trans_day_table/' + language} ">그룹/일자별 작업량 보기</router-link>&nbsp;
        </div>
        <div class="table_type2 mgT20" style="width:200px;">
          <table>
            <tbody>
              <th style="text-align:center" scope="col">할당가능 : {{ assign_num?assign_num:"불러오는중..." }}</th>
            </tbody>
          </table>
        </div>
        <!-- table_type -->
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">그룹명</th>
                <th scope="col">매니저</th>
                <th scope="col">작업자 수</th>
                <th scope="col">할당량</th>
                <th scope="col">작업량</th>
                <th scope="col">작업진행률</th>
                <th scope="col">에러</th>
                <th scope="col">2차 검수</th>
                <th scope="col">관리</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="g in group_list" :key="g">
                <td>{{ g.group_name }}</td>
                <td>{{ set_manager(g.manager) }}</td>
                <td>{{ g.workers }}</td>
                <td>{{ g.assign_num }}</td>
                <td>{{ g.work_num }}</td>
                <td>{{ g.work_rate }} %</td>
                <td>{{ g.error_num }}</td>
                <td>
                  <input type="checkbox" name="check" :id="'check' + g.no" :checked="g.pass == 1"
                    @change="check_pass($event, g.no)">
                </td>
                <td>
                  <router-link :to="{ path: '/group_view/' + language + '/' + g.no }" class="btn size1">워커 관리
                  </router-link>
                  &nbsp;
                  <a class="btn size1" @click="group_delete(g.no)">삭제</a>
                </td>
              </tr>
              <tr>
                <th>그룹수 : {{ total_info.group_count }}</th>
                <th>합계</th>
                <th>{{ total_info.total_workers }}</th>
                <th>{{ total_info.total_assign_num }}</th>
                <th>{{ total_info.total_work_num }}</th>
                <th>{{ total_info.total_work_rate }} %</th>
                <th>{{ total_info.total_error_num }}</th>
                <th></th>
                <th></th>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="10%" />
              <col width="" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" colspan="4">신규그룹추가</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="is_expert == 1">
                <td>신규그룹추가 : </td>
                <td>
                  <input v-model="add_group_name" type="text" class="inputbox" />
                </td>
                <td>2차 검수 여부&nbsp;
                  <input type="checkbox" v-model="pass">
                </td>
                <td><a v-if="is_expert == 1" @click="group_add" class="btn mgT10">그룹 추가</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <!-- //table_type -->

      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "GroupList",
  data: function () {
    return {
      language: "",
      add_group_name: "",
      group_list: [],
      is_expert: 0,
      pass: true,
      assign_num: 0,
      total_info: {},
    };
  },
  components: {
    Header
  },
  methods: {
    group_list_load() {
      axios({
        url: config.apiurl + "?part=group&mode=group_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          language: this.language,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.group_list = d.list;
        this.is_expert = d.expert;
        // this.assign_num = d.assign_num;
        this.total_info = d.total_info;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });

      axios({
        url: config.apiurl + "?part=group&mode=group_get_assign_num",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          language: this.language,
        },
      }).then((response) => {
        console.log(response.data)
        this.assign_num = response.data.assign_num;
      }).catch(function (error) {
        alert("할당가능 갯수를 불러오지 못했습니다.");
        console.log(error);
      });
    },
    set_manager(list) { // 매니저 이름
      return list.join(" / ");
    },
    group_add() { // 그룹 추가
      if (this.add_group_name == "") {
        alert("그룹명을 입력한 후 추가해주세요.");
      } else {
        if (confirm(this.add_group_name + " 그룹명으로 추가하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=group&mode=group_add",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              language: this.language,
              group_name: this.add_group_name,
              pass: this.pass
            },
          }).then((response) => {
            console.log(response.data)
            this.group_list_load();
          }).catch(function (error) {
            alert("오류가 발생했습니다...(2)");
            console.log("에러야!");
            console.log(error);
          });
        }
      }
    },
    group_delete(gno) {
      if (confirm("그룹 정보 및 워커 정보까지 삭제되며 작업은 되돌릴 수 없습니다. 그래도 삭제하시겠습니까?")) {
        axios({
          url: config.apiurl + "?part=group&mode=group_delete",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            gno: gno
          },
        }).then((response) => {
          response;
          // console.log(response.data)
          this.group_list_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
    check_pass(e, gno) {
      var c = document.getElementById('check' + gno);
      if (this.is_expert == 1) {
        if (e.target.checked) {
          if (confirm("체크하면 매니저가 2차 검수를 진행합니다. 체크하시겠습니까?")) {
            this.set_pass(1, gno);
          } else {
            c.checked = false;
          }
        } else {
          if (confirm("체크를 해제하면 매니저가 2차 검수를 진행하지 않습니다. 해제하시겠습니까?")) {
            this.set_pass(0, gno);
          } else {
            c.checked = true;
          }
        }
      } else { // 익스퍼트 권한이 없으면 체크를 변경할 수 없음.
        alert("관리자 권한이 필요합니다.");
        c.checked = !e.target.checked;
      }
    },
    set_pass(c, gno) {
      axios({
        url: config.apiurl + "?part=group&mode=group_set_pass",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          check: c,
          gno: gno,
        },
      }).then((response) => {
        response;
        // console.log(response.data)
        this.group_list_load();
      }).catch(function (error) {
        alert("오류가 발생했습니다...(4)");
        console.log("에러야!");
        console.log(error);
      });
    }
  },
  mounted: async function () {
    this.language = this.$route.params.lan;
    if (this.$route.params.lan == 'en') { await common.check_perm(this.$store.state.token, [411, 412]); } // 영어
    else if (this.$route.params.lan == 'cht') { await common.check_perm(this.$store.state.token, [421, 422]); } // 번체
    else if (this.$route.params.lan == 'chs') { await common.check_perm(this.$store.state.token, [431, 432]); } // 간체
    else if (this.$route.params.lan == 'ja') { await common.check_perm(this.$store.state.token, [441, 442]); } // 일본어
    else {
      alert("해당 언어는 지원하지 않습니다.");
      this.$router.push({ name: "Mypage" });
    }
    this.group_list_load();
  },
};
</script>