<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div style="float: right">
          <router-link :to="{ path: '/mypage/' }" class="btn bg2">목록으로</router-link>
        </div>
        <h2 class="h_title">일자/지역별 Triple 전송 수량</h2>
        <!-- <span style="color:blue;">비고 : 전송된 후 수정된 POI 건으로 약간의 오차가 있을 수 있습니다.</span> -->
        <div class="table_type1 scrollx mgT10">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="20%" />
              <col width="10%" />
              <col />
            </colgroup>
            <thead class="headfix">
              <tr>
                <th scope="col">권역 구분</th>
                <th scope="col">지역명</th>
                <th scope="col">플랫폼 상 주소 표기</th>
                <th scope="col">전체</th>
                <th scope="col" v-for="day in full_days" :key="day" style="white-space:nowrap; ">{{ day.triple_date }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="region_data.length == 0">
                <tr>
                  <td colspan="4">로딩중입니다... 잠시만 기다려주세요</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <th colspan="3">계</th>
                  <td>{{ main_total }}</td>
                  <td v-for="days in full_days" :key="days">{{ days.cnt }}</td>
                </tr>
                <template v-for="r1 in region_data" :key="r1">
                  <tr v-for="(r2, index) in r1.data" :key="r2">
                    <th v-if="index == 0" :rowspan="r1.data.length">{{ r1.area }}</th>
                    <th>{{ r2.region_name }}</th>
                    <th>{{ r2.region_mark }}</th>
                    <td>{{ r2.total }}</td>
                    <td v-for="nums in r2.nums" :key="nums" >{{ nums.num }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "PoiAssignConfirm",
  data: function () {
    return {
      full_days: [],
      days: [],
      region_data: [],
      main_total : 0,
    };
  },
  components: {
    Header,
  },
  methods: {
    region_list_load() {
      axios({
        url: config.apiurl + "?part=util&mode=region_status_day_table",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
        },
      }).then((response) => {
        var d = response.data;
        console.log(d);
        this.full_days = d.date_list;
        this.region_data = d.region_data;
        this.main_total = d.main_total;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(2)");
        console.log("에러야!");
        console.log(error);
      });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 50);
    this.region_list_load();
  },
};
</script>


<style>
.headfix {
  position: sticky;
  top: 0px;
}
</style>