<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          비식별화 작업 목록
        </h2>
        <div class="table_type2" style="width:400px;">
          <table>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th style="text-align:center" scope="col">작업량 / 할당량 ( 진행률[%] )</th>
                <th style="text-align:center" scope="col">오늘 작업한 비식별화</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align:center">{{ work }} / {{ assign }} ({{ work_rate }}%)</td>
                <td style="text-align:center">{{ today_work }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="search_wrapper mgT20">
          <input type="number" class="inputbox" style="width:260px;" v-model="search_poi" placeholder="POI 번호로 검색" />
          &nbsp;
          <a @click="doSearch2()" class="btn bg">검색</a>
          &nbsp;&nbsp;
          <select type="selectbox" class="selectbox" style="width:200px;" @change="region_select($event)">
            <option value="" selected disabled>지역 선택</option>
            <option value="">전체</option>
            <option v-for="r in region_list" :key="r" :value="r.value" :selected="page_option.now_region == r.value">{{
              r.value
            }}</option>
          </select>
          &nbsp;
          <select type="selectbox" class="selectbox" style="width:200px;" @change="type_select($event)">
            <option value="" selected disabled>관광타입 선택</option>
            <option value="">전체</option>
            <option v-for="t in type_list" :key="t" :value="t.value" :selected="page_option.now_type == t.value">{{
              t.value
            }}</option>
          </select>
        </div>

        <div class="search_wrapper mgT10">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>

        <!-- table_type -->
        <div class="table_type1 mgT20">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="6%" />
              <col />
              <col width="8%" />
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">POI 번호</th>
              <th scope="col">이름</th>
              <th scope="col">작업상태</th>
            </thead>
            <tbody>
              <tr v-if="poi_list.length == 0">
                <td colspan="4">POI가 없습니다.</td>
              </tr>

              <tr v-for="(t, index) in poi_list" :key="t.no" @click="to_view(t.no)" style="cursor:pointer;">
                <td>{{ poi_numbering(index) }}</td>
                <td>{{ t.poi_no }}</td>
                <td class="txL">{{ t.poi_name }}</td>

                <!-- 0검은색, 1파란색, 2초록색, 3빨간색 -->
                <td>
                  <span v-if="t.is_edit == 1" class="co_state1">작업완료</span>
                  <!-- <span v-else-if="(t.tempsave == 2)" class="co_state0">임시저장</span> -->
                  <span v-else class="co_state"></span>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <!-- //table_type -->
        <div class="page_box mgT10" style="width:500px;">
          <div style="float:left;">
            page : <input type="text" class="page" v-model="page" @input="paging($event, true)"> / {{ page_total }}
            &nbsp;
            <a @click="paging(-1)" class="btn size1">이전</a>
            &nbsp;
            <a @click="paging(1)" class="btn size1">다음</a>
          </div>
          <div style="float:right; ">
            <span>한페이지 게시물 수 : </span>
            <select class="selectbox" v-model="page_option.limit" style="width:80px;" @change="limit_set($event)">
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </div>
      <!-- //contents -->
    </div>

</div>
</template>

<script>

import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'BlurList',
  data() {
    return {
      region_list: [
        { "value": "서울" }, { "value": "인천" }, { "value": "경기" },
        { "value": "제주" },
        { "value": "강원" }, { "value": "경북" }, { "value": "대구" }, { "value": "울산" }, { "value": "경남" }, { "value": "부산" },
        { "value": "충북" }, { "value": "충남" }, { "value": "대전" }, { "value": "전북" }, { "value": "광주" }, { "value": "전남" },
        { "value": "기타" }
      ],
      type_list: [
        { "value": "관광지" }, { "value": "문화시설" }, { "value": "음식점" }, { "value": "레포츠" }, { "value": "숙박" }, { "value": "쇼핑" }, { "value": "편의오락" },
      ],

      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,
      search_poi: "",

      page: 1,
      page_total: 1,
      poi_num: 1,
      poi_list: [],
      page_option: {
        limit: 10,
        now_region: "",
        now_type: "",
      },
      work: 0,
      today_work: 0,
      assign: 0,
      work_rate: 0,
    };
  },
  components: {
    Header
  },
  methods: {
    region_select(e) {
      this.page_option.now_region = e.target.value;
      this.poi_list_load();
    },
    type_select(e) {
      this.page_option.now_type = e.target.value;
      this.poi_list_load();
    },
    to_view(no) { // view 들어가기
      this.$router.push({
        name: "BlurView",
        params: {
          no: no,
        }
      });
    },
    limit_set() { // 한페이지 게시물 수
      this.page = 1;
      this.page_total = 1;
      this.poi_num = 1;
      this.poi_list_load();
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.poi_list_load();
    },
    poi_numbering(num) { // POI 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - ((this.page - 1) * this.page_option.limit) - num;
      } else {
        return this.poi_num - ((this.page_total - 1) * this.page_option.limit) - num;
      }
    },
    poi_list_load(poi_no = "") {

      let search = "";
      if (this.search !== "") {
        search = this.search;
      }

      axios({
        url: config.apiurl + "?part=blur&mode=blur_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          page: this.page,
          limit: this.page_option.limit,
          search: search,
          poi_no: poi_no,
          region: this.page_option.now_region,
          type: this.page_option.now_type,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.poi_list = d.list;
        this.poi_num = d.poi_num;
        this.page_total = d.page_total;
        this.work = d.work;
        this.today_work = d.today_work;
        this.assign = d.assign;
        this.work_rate = d.work_rate;
        common.set_page_info(this, this.$route.name, this.page, search, this.page_option);
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.poi_list_load();
      }, 250);
    },
    doSearch2() {
      this.poi_list_load(this.search_poi);
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 84);
    await common.set_page(this);
    this.poi_list_load();
  }
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  ;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>