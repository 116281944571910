<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div style="float: right">
          <router-link :to="{ path: '/group_list/' + language }" class="btn bg2">목록으로</router-link>
        </div>
        <h2 class="h_title">
          그룹관리(<span v-if="language == 'en'">영어</span>
          <span v-else-if="language == 'ja'">일본어</span>
          <span v-else-if="language == 'chs'">중국어(간체)</span>
          <span v-else-if="language == 'cht'">중국어(번체)</span>
          <span v-else>미지원 언어</span>) > 워커 관리
        </h2>

        <h2 class="h_title2">매니저</h2>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="5%" />
              <col width="8%" />
              <col width="10%" />
              <col width="6%" />
              <col />
              <col />
              <col />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">선택</th>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">역할</th>
                <th scope="col">할당량</th>
                <th scope="col">작업량</th>
                <th scope="col">에러</th>
                <th scope="col">작업보기</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="manager_list.length == 0">
                <td colspan="9">
                  <span>배정된 매니저가 없습니다.</span>
                </td>
              </tr>
              <tr v-for="(m, index) in manager_list" :key="m.no">
                <td>
                  <input type="checkbox" name="manager_checkbox" :value="m.no" />
                </td>
                <td>{{ manager_numbering(index) }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>
                <td>{{ m.role }}</td>
                <td>-</td>
                <td>{{ m.work }}</td>
                <td>{{ m.error }}</td>
                <td>
                  <router-link :to="{
                    path:
                      '/group_view_work/' +
                      language +
                      '/' +
                      gno +
                      '/' +
                      m.mno,
                  }" class="btn size1">작업보기</router-link>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="role == 3 || is_expert == 1">
              <tr>
                <th colspan="2">
                  <a class="btn" @click="manager_delete_check()">매니저 선택삭제</a>
                </th>
                <th colspan="2">
                  <a class="btn" @click="manager_add()">매니저 추가</a>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- //table_type -->

        <h2 class="h_title2 mgT50">작업자</h2>
        <div class="search_wrapper">
          <input type="text" class="inputbox" @input="doSearch" placeholder="작업자 이름으로 검색" />
        </div>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="5%" />
              <col width="8%" />
              <col width="10%" />
              <col />
              <col width="13%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">선택</th>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">할당 관리</th>
                <th scope="col">검수율</th>
                <th scope="col">할당량</th>
                <th scope="col">작업량</th>
                <th scope="col">작업진행률</th>
                <th scope="col">에러</th>
                <th scope="col">작업보기</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>할당가능 : {{ assign_num?assign_num:"불러오는중..." }}</td>
                <td>기본 : 10%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <router-link :to="{ path: '/group_view_work/' + language + '/' + gno }" class="btn size1">전체작업보기
                  </router-link>
                </td>
              </tr>
              <tr v-if="member_list.length == 0 && search_now == ''">
                <td colspan="11">
                  <span>배정된 작업자가 없습니다.</span>
                </td>
              </tr>
              <tr v-else-if="member_list.length == 0">
                <td colspan="11">
                  <span>{{ search_now }}로 검색된 결과가 없습니다</span>
                </td>
              </tr>
              <tr v-for="(m, index) in member_list" :key="m.no">
                <td>
                  <input type="checkbox" name="worker_checkbox" :value="m.no" />
                </td>
                <td>{{ member_numbering(index) }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>

                <td v-if="m.is_active == 1">
                  <input type="number" style="width: 100px" v-bind:id="'member' + m.mno" />&nbsp;&nbsp;&nbsp;
                  <template v-if="assigned == 0">
                    <a @click="assign(m.mno, m.username)" class="btn size1">할당하기</a>
                    <a @click="assign_cancel(m.mno, m.username)" class="btn size1 bg3">할당취소</a>
                  </template>
                  <span v-else>적용중...</span>
                </td>
                <td v-if="m.is_active == 1">
                  <input type="number" style="width: 60px" v-bind:id="'crate' + m.mno" v-model="m.confirm_rate" />
                  %
                  <a @click="set_crate(m.mno)" class="btn size1">설정하기</a>
                </td>
                <td v-if="m.is_active != 1" colspan="2">비활성화</td>

                <td>{{ m.assign_num }}</td>
                <td>{{ m.work_num }}</td>
                <td>{{ m.work_rate }} %</td>
                <td>{{ m.error_num }}</td>
                <td>
                  <router-link :to="{
                    path:
                      '/group_view_work/' +
                      language +
                      '/' +
                      gno +
                      '/' +
                      m.mno,
                  }" class="btn size1">작업보기</router-link>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">
                  <a class="btn" @click="worker_delete_check()">작업자 비활성화</a>
                </th>
                <th colspan="2">
                  <a class="btn" @click="worker_add()">작업자 추가</a>
                </th>
                <th></th>
                <th></th>
                <th>{{ count.assign }}</th>
                <th>{{ count.work }}</th>
                <th>{{ count.work_rate }} %</th>
                <th>{{ count.error }}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- //table_type -->

        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>
      </div>
      <!-- //contents -->
    </div>
    <addWorker :active="popupWorker.active" :gno="this.$route.params.no" @close:popup="popupClose" ref="addWorker" />
    <addManager :active="popupManager.active" :permno="popupManager.permno" :gno="this.$route.params.no"
      @close:popup="popupClose" ref="addManager" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
import addWorker from "../components/PopupAddWorker.vue";
import addManager from "../components/PopupAddManager.vue";

export default {
  name: "GroupView",
  data: function () {
    return {
      popupWorker: {
        active: false,
      },
      popupManager: {
        active: false,
        permno: [],
      },
      language: "",
      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,

      gno: 0,
      assigned: 0,

      page: 1,
      page_total: 1,
      limit: 10,
      poi_num: 1,

      member_list: [],
      manager_list: [],
      assign_num: 0,
      assign_num_load: false,
      is_expert: 0, // 해당 언어의 익스퍼트 여부
      role: 0, // 그룹 내 역할 권한

      count: {
        assign: 0,
        work: 0,
        error: 0,
        work_rate: 0,
      },
    };
  },
  components: {
    Header,
    addWorker,
    addManager,
  },
  methods: {
    assign_cancel(mno, username) { // 할당취소
      if (!this.assign_num_load) {
        alert("할당가능 수가 로딩된 후에 시도해 주세요.");
      } else {
        if (
          confirm(
            username +
            " 작업자에게 할당됐지만 작업되기 전인 작업물들을 취소합니다. 이 작업은 돌이킬 수 없습니다. 취소하시겠습니까?"
          )
        ) {
          this.assigned = 1;
          axios({
            url: config.apiurl + "?part=group&mode=group_assign_cancel",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              language: this.language,
              mno: mno,
            },
          })
            .then((response) => {
              var d = response.data;
              console.log(d);
              alert(d.cancel_count + "개가 할당 취소되었습니다.");
              this.assigned = 0;
              this.member_list_load();
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(1)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    worker_delete_check() {
      // 작업자 선택삭제
      if (this.role >= 2 || this.is_expert == 1) {
        // 매니저 이상
        var worker_checkbox = document.getElementsByName("worker_checkbox");
        var w_checklist = [];
        worker_checkbox.forEach((checkbox) => {
          if (checkbox.checked) {
            w_checklist.push(checkbox.value);
          }
        });
        if (w_checklist.length == 0) {
          alert("그룹에서 비활성화할 작업자를 선택해 주세요.");
        } else {
          if (
            confirm(
              "선택한 작업자를 이 그룹에서 비활성화하시겠습니까? 이 작업은 되돌릴 수 없으며 해당 작업자의 할당량 중 작업되지 않은 작업물은 반환됩니다."
            )
          ) {
            w_checklist.forEach((no) => {
              this.worker_delete(no);
            });
            console.log(w_checklist);
          }
        }
      } else {
        alert("매니저 이상의 권한이 필요합니다.");
      }
    },
    worker_delete(no) {
      axios({
        url: config.apiurl + "?part=group&mode=group_view_worker_delete",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          no: no,
          language: this.language,
        },
      })
        .then((response) => {
          var d = response.data;
          console.log(d);
          if (d.result != 1) {
            alert(
              "정상적으로 비활성화되지 않았습니다. 관리자에게 문의해주세요."
            );
          } else {
            alert(
              d.username +
              " 작업자가 이 그룹에서 비활성화되었습니다.(반환 작업물 수 : " +
              d.deleteNum +
              ")"
            );
            this.member_list_load();
          }
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
    },
    manager_delete_check() {
      // 매니저 선택삭제
      if (this.is_expert == 1) {
        // 관리자 체크
        var manager_checkbox = document.getElementsByName("manager_checkbox");
        var m_checklist = [];
        manager_checkbox.forEach((checkbox) => {
          if (checkbox.checked) {
            m_checklist.push(checkbox.value);
          }
        });
        if (m_checklist.length == 0) {
          alert("그룹에서 제거할 매니저를 선택해 주세요.");
        } else {
          if (
            confirm(
              "선택한 매니저를 이 그룹에서 제거하시겠습니까? 이 작업은 되돌릴 수 없습니다."
            )
          ) {
            m_checklist.forEach((no) => {
              this.manager_delete(no);
            });
          }
        }
      } else {
        alert("관리자 권한이 필요합니다.");
      }
    },
    manager_delete(no) {
      axios({
        url: config.apiurl + "?part=group&mode=group_view_manager_delete",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          no: no,
        },
      })
        .then((response) => {
          var d = response.data;
          console.log(d);
          if (d.result != 1) {
            alert("정상적으로 제거되지 않았습니다. 관리자에게 문의해주세요.");
          } else {
            alert("선택한 매니저가 이 그룹에서 제거되었습니다.");
            this.member_list_load();
          }
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        });
    },
    popupClose(value) {
      this.popupWorker.active = value;
      this.popupManager.active = value;
      this.member_list_load();
    },
    worker_add() {
      // 작업자 추가
      this.popupWorker.active = true;
      this.$refs.addWorker.load();
    },
    manager_add() {
      // 매니저
      this.popupManager.active = true;
      this.$refs.addManager.load();
    },
    member_list_load() {
      let search = "";
      if (this.search !== "") {
        search = this.search;
      }

      var page = 1;
      if (this.page <= 1) {
        page = 1;
      } else if (this.page >= this.page_total) {
        page = this.page_total;
      } else {
        page = this.page;
      }

      axios({
        url: config.apiurl + "?part=group&mode=group_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          search: search,
          page: page,
          limit: this.limit,
          gno: this.$route.params.no,
          language: this.language,
        },
      })
        .then((response) => {
          var d = response.data;
          console.log(d);
          this.member_list = d.list;
          this.page_total = d.page_total;
          this.member_num = d.member_num;
          this.manager_list = d.list_m;
          this.count = d.count;
          // console.log(this.count)
          this.role = d.role;
          this.is_expert = d.is_expert;
          this.assigned = 0;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(4)");
          console.log("에러야!");
          console.log(error);
        });

      this.assign_num_load = false;
      axios({
        url: config.apiurl + "?part=group&mode=group_get_assign_num",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          language: this.language,
        },
      })
        .then((response) => {
          this.assign_num = response.data.assign_num;
          this.assign_num_load = true;
        })
        .catch(function (error) {
          alert("할당가능 갯수를 불러오지 못했습니다.");
          console.log(error);
        });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.member_list_load();
      }, 250);
    },
    member_numbering(num) {
      // POI 목록 번호 세팅
      num = num + 1;
      if (this.page < 1) {
        return num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return (this.page - 1) * this.limit + num;
      } else {
        return (this.page_total - 1) * this.limit + num;
      }
    },
    manager_numbering(num) {
      return this.manager_list.length - num;
    },
    paging(count, to = false) {
      // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.member_list_load();
    },
    assign(mno, username) { // 할당하기
      if (!this.assign_num_load) {
        alert("할당가능 수가 로딩된 후에 시도해 주세요.");
      } else {
        var numid = document.getElementById("member" + mno.toString());
        var num = Number(numid.value);

        if (num >= 1 && num <= this.assign_num) {
          if (
            confirm(username + " 작업자에게 " + num + "개만큼 할당하시겠습니까?")
          ) {
            this.assigned = 1;
            axios({
              url: config.apiurl + "?part=group&mode=group_assign",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              method: "POST",
              data: {
                token: this.$store.state.token,
                language: this.language,
                mno: mno,
                num: num,
                gno: this.gno,
              },
            })
              .then((response) => {
                console.log(response.data);
                alert(num + "개가 할당되었습니다.");
                this.member_list_load();
              })
              .catch(function (error) {
                alert("오류가 발생했습니다...(5)");
                console.log("에러야!");
                console.log(error);
              });
          }
        } else {
          alert("1개부터 " + this.assign_num + "개까지 할당할 수 있습니다.");
        }
      }
    },
    set_crate(mno) {
      // 할당하기
      var numid = document.getElementById("crate" + mno.toString());
      var num = Number(numid.value);

      if (num >= 0 && num <= 100) {
        axios({
          url: config.apiurl + "?part=group&mode=group_set_crate",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            language: this.language,
            mno: mno,
            num: num,
          },
        })
          .then((response) => {
            console.log(response.data);
            alert("검수율을 " + num + "%로 설정했습니다.");
            this.member_list_load();
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(6)");
            console.log("에러야!");
            console.log(error);
          });
      } else {
        alert("0부터 100 사이에서 설정할 수 있습니다.");
      }
    },
  },
  mounted: async function () {
    this.language = this.$route.params.lan;
    if (this.$route.params.lan == "en") {
      // 영어
      await common.check_perm(this.$store.state.token, [411, 412]);
      this.popupManager.permno = [411, 412];
    } else if (this.$route.params.lan == "cht") {
      // 번체
      await common.check_perm(this.$store.state.token, [421, 422]);
      this.popupManager.permno = [421, 422];
    } else if (this.$route.params.lan == "chs") {
      // 간체
      await common.check_perm(this.$store.state.token, [431, 432]);
      this.popupManager.permno = [431, 432];
    } else if (this.$route.params.lan == "ja") {
      // 일본어
      await common.check_perm(this.$store.state.token, [441, 442]);
      this.popupManager.permno = [441, 442];
    } else {
      alert("해당 언어는 지원하지 않습니다.");
      this.$router.push({ name: "Mypage" });
    }
    this.gno = this.$route.params.no;
    this.member_list_load();
  },
};
</script>

<style>
.search_wrapper {
  padding: 12px;
}

.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>