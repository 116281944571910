<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <p class="h_title">90-{{ no }}. 법률 수집 문서</p>
        <div class="" style="text-align: center;">
          <div class="" style="display: inline-block; width:60%;">
            <h2 class="h_title" style="text-align: left;">법류 문서(한국어)</h2>

            <!-- <span>문서 기본정보</span>
            <textarea class="textareabox" v-model="doc_basic1"></textarea> -->


            <!-- table_type -->
            <div class="table_type3 mgT20">
              <table>
                <colgroup>
                  <col width="12%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_ko" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div style="width:100%">
                        <input v-if="meta.style_class == 'inputbox'" class="inputbox" v-model="meta.poi_value" readonly>
                        <textarea v-else class="textareabox2" v-model="meta.poi_value" readonly></textarea>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
          <!-- <div class="list_box">
            <h2 class="h_title" style="text-align: center;">정제</h2>
            <span>문서 기본정보</span>
            <textarea class="textareabox" v-model="doc_basic1"></textarea>
            <div class="table_type3 mgT20">
              <table>
                <colgroup>
                  <col width="12%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">판시사항</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox"
                          value="[1] 의사능력의 의미 및 의사능력 유무는 구체적인 법률행위와 관련하여 개별적으로 판단해야 하는지 여부(적극) / 의사능력이 인정되기 위해서는 법률행위의 일상적 의미뿐만 아니라 법률적 의미나 효과에 대해서도 이해할 수 있어야 하는지 여부(적극) ">
                        <input class="inputbox" value="[2] 지적장애를 가진 사람에게 의사능력이 있는지 판단하는 기준 ">
                        <input class="inputbox"
                          value="[3] 지적장애 3급의 장애인인 甲이 乙 주식회사와 체결한 굴삭기 구입자금 대출약정에 기한 대출금채무를 연체하자 乙 회사가 甲을 상대로 대출원리금의 지급을 구하는 소를 제기하였고, 이에 甲이 대출약정 당시 의사능력이 없었으므로 대출약정이 무효라고 주장한 사안에서, 제반 사정에 비추어 지적장애인인 甲이 대출약정의 법률적인 의미나 효과를 이해할 수 있었다고 보기 어려우므로, 甲은 대출약정 당시 의사능력이 없다는 이유로 대출약정은 무효라고 볼 여지가 많은데도, 이와 달리 본 원심판결에 법리오해 등의 잘못이 있다고 한 사례">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">판결요지</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox"
                          value="[1] 의사능력이란 자기 행위의 의미나 결과를 정상적인 인식력과 예기력을 바탕으로 합리적으로 판단할 수 있는 정신적 능력이나 지능을 말한다. 의사능력 유무는 구체적인 법률행위와 관련하여 개별적으로 판단해야 하고, 특히 어떤 법률행위가 일상적인 의미만을 이해해서는 알기 어려운 특별한 법률적 의미나 효과가 부여되어 있는 경우 의사능력이 인정되기 위해서는 그 행위의 일상적인 의미뿐만 아니라 법률적인 의미나 효과에 대해서도 이해할 수 있어야 한다. ">
                        <input class="inputbox" value="[2] 장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호, 장애인복지법 시행규칙 제2조 제1항 ">
                        <input class="inputbox"
                          value="[별표 1] 제6호에 따르면, 특별한 사정이 없는 한 지능지수가 70 이하인 사람은 교육을 통한 사회적·직업적 재활이 가능하더라도 지적장애인으로서 위 법령에 따른 보호의 대상이 된다. 지적장애인에 해당하는 경우에도 의학적 질병이나 신체적 이상이 드러나지 않아 사회 일반인이 보았을 때 아무런 장애가 없는 것처럼 보이는 경우가 있다. 반면 지적장애를 가진 사람이 장애인복지법령에 따라 지적장애인 등록을 하지 않았다거나 등록 기준을 충족하지 못하였다고 해서 반드시 의사능력이 있다고 단정할 수 없다. 이러한 사정을 고려하면, 지적장애를 가진 사람에게 의사능력이 있는지를 판단할 때 단순히 그 외관이나 피상적인 언행만을 근거로 의사능력을 쉽게 인정해서는 안 되고, 의학적 진단이나 감정 등을 통해 확인되는 지적장애의 정도를 고려해서 법률행위의 구체적인 내용과 난이도, 그에 따라 부과되는 책임의 중대성 등에 비추어 볼 때 지적장애를 가진 사람이 과연 법률행위의 일상적 의미뿐만 아니라 법률적인 의미나 효과를 이해할 수 있는지, 법률행위가 이루어지게 된 동기나 경위 등에 비추어 합리적인 의사결정이라고 보기 어려운 사정이 존재하는지 등을 세심하게 살펴보아야 한다. ">
                        <input class="inputbox"
                          value="[3] 지적장애 3급의 장애인인 甲이 乙 주식회사와 체결한 굴삭기 구입자금 대출약정에 기한 대출금채무를 연체하자 乙 회사가 甲을 상대로 대출원리금의 지급을 구하는 소를 제기하였고, 이에 甲이 대출약정 당시 의사능력이 없었으므로 대출약정이 무효라고 주장한 사안에서, 대출약정 이후 甲에 대해 한정후견이 개시되었고, 그 심판 절차에서 이루어진 甲에 대한 정신상태 감정 결과의 내용과 감정 시기 등에 비추어 대출약정 당시 甲의 지능지수와 사회적 성숙도 역시 감정 당시와 비슷한 정도였을 것으로 보이는 점, 대출금액이 소액이라고 볼 수 없고, 위 대출약정은 굴삭기 구입자금을 마련하기 위한 것으로서 굴삭기는 실질적으로 대출금채무의 담보가 되고 대출금은 굴삭기 매도인에게 직접 지급되는데, 이와 같은 대출 구조와 내용은 甲의 당시 지적능력으로는 이해하기 어려운 정도라고 볼 수 있는 점, 대출약정 당시 甲이 제출한 굴삭기운전자격증은 위조된 것이었고, 甲의 지적능력에 비추어 굴삭기를 운전할 능력이 있었다고 보기도 어려우며, 甲이 자격증을 위조하면서까지 대출약정을 할 동기를 찾기 어려운 등 대출약정의 체결 경위에 합리적인 의사결정이라고 보기 어려운 사정이 있고, 오히려 제3자가 대출금을 실제로 사용하기 위해서 甲을 이용한 것은 아닌지 의심이 드는 점 등에 비추어, 지적장애인인 甲이 대출약정의 법률적인 의미나 효과를 이해할 수 있었다고 보기 어려우므로, 甲은 대출약정 당시 의사능력이 없다는 이유로 대출약정은 무효라고 볼 여지가 많은데도, 이와 달리 본 원심판결에 법리오해 등의 잘못이 있다고 한 사례.">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">참조조문</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="[1] 민법 제9조 제1항, 제12조 제1항, 제14조의2 제1항  ">
                        <input class="inputbox"
                          value="[2] 민법 제9조 제1항, 제12조 제1항, 제14조의2 제1항, 장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호, 제2항, 장애인복지법 시행규칙 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호  ">
                        <input class="inputbox" value="[3] 민법 제12조 제1항, 장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호, 제2항, 장애인복지법 시행규칙 제2조 제1항 ">
                        <input class="inputbox" value="[별표 1] 제6호">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">참조판례</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="[1] 대법원 2002. 10. 11. 선고 2001다10113 판결(공2002하, 2675), 대법원 2006. 9. 22. 선고 2006다29358 판결, 대법원 2009. 1. 15. 선고 2008다58367 판결(공2009상, 155)">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">원고</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="비엔케이캐피탈 주식회사">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">피고</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="피고(법정대리인 한정후견인 ○○○) (소송대리인 변호사 민선향)">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">원심판결</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="부산지법 2019. 1. 18. 선고 2018나41754 판결">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">주문</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="원심판결을 파기하고, 사건을 부산지방법원에 환송한다.">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">이유</th>
                    <td>
                      <div style="width:100%">
                        <input class="inputbox" value="상고이유를 판단한다.">
                        <input class="inputbox" value="1. 사안 개요">
                        <input class="inputbox" value="원심판결 이유에 따르면 다음 사실을 알 수 있다.">
                        <input class="inputbox"
                          value="가. 원고는 2015. 7. 6.경 피고에게 굴삭기(‘굴착기’라고도 한다) 구입자금으로 8,800만 원을 대출하고(이하 ‘이 사건 대출약정’이라 한다), 대출금 중 인지대를 공제한 8,796만 5,000원을 굴삭기 공급자에게 직접 지급하였다.">
                        <input class="inputbox"
                          value="나. 피고가 대출금채무를 연체하자, 원고는 피고를 상대로 대출원리금의 지급을 구하는 이 사건 소를 제기하였다. 이에 대해 피고는 이 사건 대출약정 당시 의사능력이 없었으므로 이 사건 대출약정은 무효라고 다투었다.">
                        <input class="inputbox" value="2. 원심판단">
                        <input class="inputbox"
                          value="원심은 이 사건 대출약정 당시 피고가 인지·판단능력이 현저히 결여되어 독자적으로 자기 의사를 결정할 수 없는 의사무능력 상태에 있었다고 인정하기 어렵다는 이유로, 피고의 의사무능력 주장을 배척하였다.">
                        <input class="inputbox" value="3. 대법원 판단">
                        <input class="inputbox"
                          value="가. 의사능력이란 자기 행위의 의미나 결과를 정상적인 인식력과 예기력을 바탕으로 합리적으로 판단할 수 있는 정신적 능력이나 지능을 말한다. 의사능력 유무는 구체적인 법률행위와 관련하여 개별적으로 판단해야 하고(대법원 2002. 10. 11. 선고 2001다10113 판결 참조), 특히 어떤 법률행위가 그 일상적인 의미만을 이해해서는 알기 어려운 특별한 법률적 의미나 효과가 부여되어 있는 경우 의사능력이 인정되기 위해서는 그 행위의 일상적인 의미뿐만 아니라 법률적인 의미나 효과에 대해서도 이해할 수 있어야 한다(대법원 2006. 9. 22. 선고 2006다29358 판결, 대법원 2009. 1. 15. 선고 2008다58367 판결 등 참조).">
                        <input class="inputbox"
                          value="장애인복지법 제2조 제2항 제2호, 장애인복지법 시행령 제2조 제1항 [별표 1] 제6호, 장애인복지법 시행규칙 제2조 제1항 [별표 1] 제6호에 따르면, 특별한 사정이 없는 한 지능지수가 70 이하인 사람은 교육을 통한 사회적·직업적 재활이 가능하더라도 지적장애인으로서 위 법령에 따른 보호의 대상이 된다. 지적장애인에 해당하는 경우에도 의학적 질병이나 신체적 이상이 드러나지 않아 사회 일반인이 보았을 때 아무런 장애가 없는 것처럼 보이는 경우가 있다. 반면 지적장애를 가진 사람이 장애인복지법령에 따라 지적장애인 등록을 하지 않았다거나 등록 기준을 충족하지 못하였다고 해서 반드시 의사능력이 있다고 단정할 수 없다.">
                        <input class="inputbox"
                          value="이러한 사정을 고려하면, 지적장애를 가진 사람에게 의사능력이 있는지를 판단할 때 단순히 그 외관이나 피상적인 언행만을 근거로 의사능력을 쉽게 인정해서는 안 되고, 의학적 진단이나 감정 등을 통해 확인되는 지적장애의 정도를 고려해서 법률행위의 구체적인 내용과 난이도, 그에 따라 부과되는 책임의 중대성 등에 비추어 볼 때 지적장애를 가진 사람이 과연 법률행위의 일상적 의미뿐만 아니라 법률적인 의미나 효과를 이해할 수 있는지, 법률행위가 이루어지게 된 동기나 경위 등에 비추어 합리적인 의사결정이라고 보기 어려운 사정이 존재하는지 등을 세심하게 살펴보아야 한다.">
                        <input class="inputbox" value="나. 원심판결 이유와 원심이 적법하게 채택한 증거에 따르면 다음과 같은 사정을 알 수 있다.">
                        <input class="inputbox"
                          value="1) 피고는 2005. 10. 12. 지적장애 3급의 장애인으로 등록하였다. 피고는 2013. 5. 20. ‘지능지수 70, 사회발달연령 7세 8개월, 사회성숙지수 43’의 장애진단을 받았다.">
                        <input class="inputbox"
                          value="2) 이 사건 대출약정 이후 피고에 대한 성년후견 개시가 청구되어(사건번호 생략) 2017. 1. 18. 피고에 대해 한정후견이 개시되었다. 그 심판 절차에서 2016. 10. 31.부터 2016. 11. 24.까지 이루어진 피고에 대한 정신상태 감정 결과 ‘지능지수 52, 사회지수 50(사회연령 9세)’이라는 진단을 받았고, ‘학습이나 문제해결을 위한 기본적인 지적능력뿐만 아니라 일상생활에서의 사회 적응 수준이 해당 연령에 비해 매우 부족하고, 사회적 규범에 대한 이해가 부족하며, 비합리적 방식의 의사결정 가능성이 높아 정신적 제약으로 사무를 처리할 능력이 매우 부족하다.’는 평가를 받았다. 위 감정 결과의 내용과 그 감정 시기 등에 비추어 볼 때, 이 사건 대출약정 당시 피고의 지능지수와 사회적 성숙도 역시 위 감정 당시와 비슷한 정도였을 것으로 볼 수 있다.">
                        <input class="inputbox"
                          value="3) 이 사건 대출약정의 대출금은 8,800만 원으로서 결코 소액이라고 볼 수 없다. 이 사건 대출약정은 굴삭기 구입자금을 마련하기 위한 것으로서 굴삭기는 실질적으로 대출금채무의 담보가 되고 대출금은 굴삭기 매도인에게 직접 지급되는데, 이와 같은 대출 구조와 내용은 피고의 당시 지적능력으로는 이해하기 어려운 정도라고 볼 수 있다.">
                        <input class="inputbox"
                          value="4) 원고는 피고가 굴삭기의 실수요자라고 보아 이 사건 대출을 한 것이고, 증빙자료로서 피고의 굴삭기운전자격증을 제출받았으나, 굴삭기운전자격증은 이후 위조된 것으로 판명되었다. 이 사건 대출약정 당시 피고의 지적능력에 비추어 피고가 굴삭기를 운전할 능력이 있었다고 보기도 어렵다. 이 사건 대출금은 굴삭기 공급자에게 직접 지급되어 피고가 이를 받은 적이 없는데도, 피고가 굴삭기운전자격증을 위조하면서까지 이 사건 대출약정을 할 동기를 찾기 어렵다. 이와 같이 이 사건 대출약정의 체결 경위에는 합리적인 의사결정이라고 보기 어려운 사정이 있고, 오히려 제3자가 대출금을 실제로 사용하기 위해서 피고를 이용한 것은 아닌지 의심이 든다.">
                        <input class="inputbox"
                          value="다. 이러한 사정을 위에서 본 법리에 비추어 보면, 지적장애인인 피고가 이 사건 대출약정의 법률적인 의미나 효과를 이해할 수 있었다고 보기 어렵다. 따라서 피고는 이 사건 대출약정 당시 의사능력이 없다는 이유로 이 사건 대출약정은 무효라고 볼 여지가 많다.">
                        <input class="inputbox"
                          value="그런데 원심은 피고가 이 사건 대출약정 당시 인지·판단능력이 현저히 결여되어 독자적으로 자기 의사를 결정할 수 없는 상태에 있었다고 보기 어렵다는 이유로 피고의 의사무능력 주장을 배척하였다. 원심판결은 지적장애인의 의사능력에 관한 법리를 오해하여 필요한 심리를 다하지 않아 판결에 영향을 미친 잘못이 있다. 이를 지적하는 상고이유 주장은 정당하다.">
                        <input class="inputbox" value="4. 결론">
                        <input class="inputbox"
                          value="나머지 상고이유에 대한 판단을 생략한 채 원심판결을 파기하고 사건을 다시 심리·판단하도록 원심법원에 환송하기로 하여, 대법관의 일치된 의견으로 주문과 같이 판결한다.">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <router-link class="btn bg1" :to="{ path: '/m_list' }">확인</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import Header from "../../components/Header.vue";

export default {
  name: 'MView2',
  data() {
    return {
      no:1,
      metas_info: {
        metas_ko: [
          {
            poi_key: "판시사항", poi_value: `[1] 구 상속세 및 증여세법 제45조의3의 입법 취지
[2] 구 상속세 및 증여세법 제45조의3에 따른 증여세의 경우 증여자는 특수관계법인으로, 수증자는 증여세 납부의무자인 수혜법인의 지배주주 등으로 보아야 하는지 여부(적극) / 수혜법인의 지배주주 등이 동시에 특수관계법인의 주주인 경우, 수혜법인의 지배주주 등이 특수관계법인으로부터 증여받은 것으로 의제되는 이익이 구 상속세 및 증여세법 제45조의3에서 정한 증여세 과세대상에서 제외된다고 볼 수 있는지 여부(소극) 및 이는 2014. 2. 21. 상속세 및 증여세법 시행령이 개정되면서 제34조의2 제12항 제3호에서 증여의제이익 계산방법을 종전과 달리 정하였더라도 마찬가지인지 여부(적극)`, style_class: "textareabox",
          },
          {
            poi_key: "판결요지", poi_value: `[1] 구 상속세 및 증여세법(2013. 1. 1. 법률 제11609호로 개정되기 전의 것 및 2015. 12. 15. 법률 제13557호로 개정되기 전의 것) 제45조의3은 특수관계법인이 수혜법인에 일감을 몰아주는 방법으로 수혜법인의 기업가치를 상승시켜 그 지배주주 등의 부를 증식하는 변칙적인 증여행위에 대하여 증여세를 과세함으로써 과세의 공평을 도모하기 위하여 도입된 규정이다. 위 규정은 이른바 ‘일감몰아주기’를 통해 지배주주 등에게 발생한 이익을 과세대상으로 삼기 위하여, 수혜법인의 매출액 중 일정한 비율을 초과하는 매출액이 특수관계법인과의 거래에서 발생한 경우, 수혜법인의 세후영업이익 중 일정 부분을 수혜법인의 지배주주 등이 증여받은 것으로 의제한다.
[2] 구 상속세 및 증여세법(2013. 1. 1. 법률 제11609호로 개정되기 전의 것 및 2015. 12. 15. 법률 제13557호로 개정되기 전의 것) 제45조의3 제1항은 수혜법인의 매출액 중에서 일정한 비율을 초과하는 매출액이 ‘특수관계법인’과의 거래에서 발생한 경우 ‘수혜법인의 지배주주 등’이 일정한 이익을 증여받은 것으로 보아 ‘수혜법인의 지배주주 등’에게 증여세를 부과하도록 정하고 있다. 따라서 위 규정에 따른 증여세의 경우 증여자는 특수관계법인으로, 수증자는 증여세 납부의무자인 수혜법인의 지배주주 등으로 보아야 한다. 그런데 증여자인 특수관계법인은 그 주주와 구별되는 별개의 법적 주체이므로, 수증자인 수혜법인의 지배주주 등이 동시에 특수관계법인의 주주이더라도 증여자와 수증자가 같다고 할 수 없다. 더욱이 특수관계법인은 수혜법인과의 거래로 인하여 손실을 입는 것이 아니므로, 수혜법인의 지배주주 등이 동시에 특수관계법인의 주주이더라도, 그 거래로 인한 이익과 손실이 함께 수혜법인의 지배주주 등에게 귀속되어 그 재산가치가 실질적으로 증가하지 않는다고 평가할 수도 없다. 따라서 수혜법인의 지배주주 등이 특수관계법인으로부터 증여받은 것으로 의제되는 이익이 위 규정에서 정한 증여세 과세대상에서 제외된다고 볼 수 없다. 이는 2014. 2. 21. 상속세 및 증여세법 시행령이 개정되면서 제34조의2 제12항 제3호에서 ‘수혜법인이 특수관계법인과 거래한 매출액에 지배주주 등의 그 특수관계법인에 대한 주식보유비율을 곱한 금액’을 과세제외 매출액에 포함하도록 정하는 등 증여의제이익 계산방법을 종전과 달리 정하였더라도 다르지 않다.`, style_class: "textareabox",
          },
          {
            poi_key: "참조조문", poi_value: `[1] 구 상속세 및 증여세법(2013. 1. 1. 법률 제11609호로 개정되기 전의 것) 제45조의3, 구 상속세 및 증여세법(2015. 12. 15. 법률 제13557호로 개정되기 전의 것) 제45조의3
[2] 구 상속세 및 증여세법(2013. 1. 1. 법률 제11609호로 개정되기 전의 것) 제45조의3 제1항, 구 상속세 및 증여세법(2015. 12. 15. 법률 제13557호로 개정되기 전의 것) 제45조의3 제1항, 구 상속세 및 증여세법 시행령(2014. 2. 21. 대통령령 제25195호로 개정되기 전의 것) 제34조의2 제3항(현행 제34조의3 제3항 참조), 제4항(현행 제34조의3 제5항 참조), 제9항(현행 제34조의3 제11항 참조), 제10항 제1호(현행 제34조의3 제12항 제3호 참조), 구 상속세 및 증여세법 시행령(2015. 2. 3. 대통령령 제26069호로 개정되기 전의 것) 제34조의 2 제3항(현행 제34조의3 제3항 참조), 제5항(현행 제34조의3 제5항 참조), 제8항(현행 제34조의3 제8항 참조), 제11항(현행 제34조의3 제11항 참조), 제12항 제3호(현행 제34조의3 제12항 제3호 참조)`, style_class: "textareabox",
          },
          //           {
          //             poi_key: "참조판례", poi_value: `[1] 대법원 2002. 10. 11. 선고 2001다10113 판결(공2002하, 2675), 대법원 2006. 9. 22. 선고 2006다29358 판결, 대법원 2009. 1. 15. 선고 2008다58367 판결(공2009상, 155)
          // `, style_class: "textareabox",
          //           },
          {
            poi_key: "원고", poi_value: `원고 (소송대리인 법무법인(유한) 태평양 담당변호사 조일영 외 7인)`, style_class: "inputbox",
          },
          {
            poi_key: "피고", poi_value: `연수세무서장 (소송대리인 변호사 김의식)`, style_class: "inputbox",
          },
          {
            poi_key: "원심판결", poi_value: `서울고법 2020. 9. 23. 선고 2019누36669 판결`, style_class: "inputbox",
          },
          {
            poi_key: "주문", poi_value: `상고를 기각한다. 상고비용은 원고가 부담한다.`, style_class: "inputbox",
          },
          {
            poi_key: "이유", poi_value: `상고이유(상고이유서 제출기간이 지난 후에 제출된 상고이유보충서 기재는 상고이유를 보충하는 범위 내에서)를 판단한다.
   1. 사안의 개요
      원심판결 이유와 기록에 의하면 다음과 같은 사실을 알 수 있다.
      가. 원고는 2012년과 2013년 주식회사 셀트리온홀딩스와 주식회사 셀트리온지에스씨를 통하여 주식회사 셀트리온(이하 ‘셀트리온’이라 한다)의 주식을 간접적으로 보유하는 한편 주식회사 셀트리온헬스케어(이하 ‘헬스케어’라 한다)의 주식을 50% 이상 직접 보유하였다.
      나. 셀트리온은 2012 사업연도 및 2013 사업연도에 헬스케어에 의약품을 공급하였는데(이하 ‘이 사건 거래’라 한다), 셀트리온의 매출액 중에서 헬스케어에 대한 매출액이 차지하는 비율은 2012 사업연도에 94.56%, 2013 사업연도에 98.65%였다.
      다. 원고는 구 「상속세 및 증여세법」(2013. 1. 1. 법률 제11609호로 개정되기 전의 것 및 2015. 12. 15. 법률 제13557호로 개정되기 전의 것, 이하 양자를 구분하지 않고 ‘구 상증세법’이라 한다) 제45조의3 제1항 등에 따라 자신이 셀트리온의 지배주주 지위에서 2012. 12. 31. 및 2013. 12. 31. 헬스케어로부터 일정한 이익(이하 ‘이 사건 증여의제이익’이라 한다)을 증여받은 것으로 의제된다는 이유로, 2013. 7. 31. 및 2014. 6. 27. 피고에게 각 증여세를 신고·납부하였다.
      라. 원고는 2014. 10. 14. 피고에게 위 각 증여세의 환급을 구하는 취지의 경정청구를 하였는데, 피고는 2014. 12. 9. 이를 거부하였다(이하 ‘이 사건 처분’이라 한다).
   2. 관련 법령
      가. 구 상증세법 제45조의3 제1항은 법인의 사업연도 매출액 중에서 그 법인의 지배주주와 대통령령으로 정하는 특수관계에 있는 법인(이하 ‘특수관계법인’이라 한다)에 대한 매출액이 차지하는 비율(이하 ‘특수관계법인거래비율’이라 한다)이 그 법인의 업종 등을 고려하여 대통령령으로 정하는 비율(이하 ‘정상거래비율’이라 한다)을 초과하는 경우에는 그 법인(이하 ‘수혜법인’이라 한다)의 지배주주와 그 지배주주의 친족[수혜법인의 발행주식총수 또는 출자총액에 대하여 직접 또는 간접으로 보유하는 주식보유비율이 대통령령으로 정하는 보유비율(이하 ‘한계보유비율’이라 한다)을 초과하는 주주에 한정한다]이 ‘수혜법인의 세후영업이익 × 정상거래비율 또는 그 1/2을 초과하는 특수관계법인거래비율 × 한계보유비율을 초과하는 주식보유비율’의 계산식에 따라 계산한 이익(이하 ‘증여의제이익’이라 한다)을 각각 증여받은 것으로 보도록 정하고 있다.
      그 위임에 따른 구 「상속세 및 증여세법 시행령」(2014. 2. 21. 대통령령 제25195호로 개정되기 전의 것, 이하 ‘2014년 개정 전 상증세법 시행령’이라 한다) 제34조의2는 제10항 제1호에서 수혜법인의 사업연도 매출액 중 수혜법인의 지배주주가 100%를 출자한 법인 또는 지배주주의 친족이 각각 100%를 출자한 법인(이하 ‘완전지배법인’이라 한다)에 대한 매출액이 있는 경우 ‘(특수관계법인거래비율 - 수혜법인의 사업연도 매출액 중에서 완전지배법인과의 매출액이 차지하는 비율) ÷ 특수관계법인비율’의 계산방법에 따른 비율을 제9항에 따라 계산된 증여의제이익에 곱하여 최종 증여의제이익을 계산하도록 정하고, 제4항에서 구 상증세법 제45조의3 제1항의 정상거래비율을 30%로 정하고 있다. 한편 구 「상속세 및 증여세법 시행령」(2015. 2. 3. 대통령령 제26069호로 개정되기 전의 것, 이하 ‘2015년 개정 전 상증세법 시행령’이라 한다) 제34조의2 제5항은 구 상증세법 제45조의3 제1항의 정상거래비율을 30%로 정하되, 일정한 중소기업 또는 중견기업에 해당하는 경우에는 50%로 정하고 있다.
      나. 구 상증세법 제45조의3은 특수관계법인이 수혜법인에 일감을 몰아주는 방법으로 수혜법인의 기업가치를 상승시켜 그 지배주주 등의 부를 증식하는 변칙적인 증여행위에 대하여 증여세를 과세함으로써 과세의 공평을 도모하기 위하여 도입된 규정이다. 위 규정은 이른바 ‘일감몰아주기’를 통해 지배주주 등에게 발생한 이익을 과세대상으로 삼기 위하여, 수혜법인의 매출액 중 일정한 비율을 초과하는 매출액이 특수관계법인과의 거래에서 발생한 경우, 수혜법인의 세후영업이익 중 일정 부분을 수혜법인의 지배주주 등이 증여받은 것으로 의제한다.
   3. 구 상증세법 제45조의3과 구 상증세법 제2조 제3항의 관계 등(제1 상고이유)
      가. 원심은 ① 구 상증세법 제45조의3의 문언과 조세법률주의의 원칙 등에 비추어 어느 행위 또는 거래가 ‘기여에 의한 재산가치 증가’라는 구 상증세법 제2조 제3항의 증여 개념에 부합해야만 구 상증세법 제45조의3에 의한 증여세를 과세할 수 있는 것은 아닌 점, ② 구 상증세법 제2조 제3항의 증여 개념이 증여의제 규정인 구 상증세법 제45조의3에 그대로 적용된다고 보기 어려운 점, ③ 이 사건 거래의 내용 등에 비추어 원고의 주장과 같이 셀트리온이 헬스케어에 이익과 사업기회를 일방적으로 제공하는 관계라고 보기 어려운 점 등을 들어, 이 사건 거래가 구 상증세법 제45조의3에 의한 증여세 과세요건을 충족하였다고 판단하고, 이와 다른 전제에서 이 사건 거래는 헬스케어가 셀트리온에 기여를 한 것이 아니므로 구 상증세법 제2조 제3항의 증여 개념에 부합하지 않아 구 상증세법 제45조의3에 의한 증여세 과세요건을 충족하지 못하였다는 취지의 원고 주장을 배척하였다.
      나. 원심판결 이유를 관련 규정과 법리 및 기록에 비추어 살펴보면, 원심이 구 상증세법 제45조의3을 이른바 증여세 완전포괄주의 과세제도의 개별적 예시규정이라고 설시한 부분은 다소 부적절하지만, 이 사건 거래가 구 상증세법 제45조의3에 의한 증여세 과세요건을 충족하였다는 원심의 결론은 정당하고, 거기에 상고이유 주장과 같이 구 상증세법 제45조의3의 과세요건에 관한 법리를 오해하여 이 사건 거래가 구 상증세법 제2조 제3항의 증여 개념에 부합하는지 여부에 관한 심리를 다하지 않음으로써 판결에 영향을 미친 잘못이 없다.
   4. 원고가 셀트리온의 지배주주에 해당하는지 여부(제3 상고이유)
      가. 원심은 구 상증세법 제45조의3 제1항 등이 ‘지배주주’의 의미를 직접 정의하고 있으므로 이를 반드시 상법상 ‘주주’의 의미 내에서 해석해야 한다고 볼 수 없고, 지배주주에 관하여 정하고 있는 위 규정의 문언에 비추어 수혜법인의 주식을 직접 보유하지 않고 간접적으로만 보유하는 자도 수혜법인의 지배주주에 포함되는 것으로 보아야 한다는 등 그 판시와 같은 이유로, 원고가 셀트리온의 주식을 직접 보유하지 않고 간접적으로만 보유하고 있더라도 셀트리온의 지배주주로서 구 상증세법 제45조의3 제1항에 따른 증여세 납세의무자에 해당한다고 판단하였다.
      나. 원심판결 이유를 관련 규정과 법리에 비추어 살펴보면, 원심의 이러한 판단에 상고이유 주장과 같이 지배주주의 의미에 관한 법리를 오해하거나 대법원 판례를 위반한 잘못이 없다.
   5. 이 사건 증여의제이익이 이른바 ‘자기증여’에 따른 것인지 여부 등(제2, 4 상고이유)
      가. 구 상증세법 제45조의3 제1항은 수혜법인의 매출액 중에서 일정한 비율을 초과하는 매출액이 ‘특수관계법인’과의 거래에서 발생한 경우 ‘수혜법인의 지배주주 등’이 일정한 이익을 증여받은 것으로 보아 ‘수혜법인의 지배주주 등’에게 증여세를 부과하도록 정하고 있다. 따라서 위 규정에 따른 증여세의 경우 증여자는 특수관계법인으로, 수증자는 증여세 납부의무자인 수혜법인의 지배주주 등으로 보아야 한다. 그런데 증여자인 특수관계법인은 그 주주와 구별되는 별개의 법적 주체이므로, 수증자인 수혜법인의 지배주주 등이 동시에 특수관계법인의 주주이더라도 증여자와 수증자가 같다고 할 수 없다. 더욱이 특수관계법인은 수혜법인과의 거래로 인하여 손실을 입는 것이 아니므로, 수혜법인의 지배주주 등이 동시에 특수관계법인의 주주이더라도, 그 거래로 인한 이익과 손실이 함께 수혜법인의 지배주주 등에게 귀속되어 그 재산가치가 실질적으로 증가하지 않는다고 평가할 수도 없다. 따라서 이 사건 증여의제이익이 위 규정에서 정한 증여세 과세대상에서 제외된다고 볼 수 없다. 이는 2014. 2. 21. 「상속세 및 증여세법 시행령」이 개정되면서 제34조의2 제12항 제3호에서 ‘수혜법인이 특수관계법인과 거래한 매출액에 지배주주 등의 그 특수관계법인에 대한 주식보유비율을 곱한 금액’을 과세제외 매출액에 포함하도록 정하는 등 증여의제이익 계산방법을 종전과 달리 정하였더라도 다르지 않다.
      나. 원심은 그 판시와 같은 이유로, 원고가 이 사건 거래로 실질적인 이익을 얻은 바가 없고, 실질적인 이익을 얻었다고 가정하더라도 이는 자기증여에 따른 것이므로 이 사건 처분은 구 상증세법 제45조의3 제1항의 과세요건을 충족하지 못하여 위법하다는 취지의 원고 주장을 배척하였다. 아울러 원심은 2014년 개정 전 상증세법 시행령 제34조의2 제10항 제1호가 수혜법인의 지배주주가 특수관계법인의 주식을 보유하더라도 그 100%를 보유하지 않은 경우에는 원고가 주장하는 자기증여 부분을 증여의제이익에서 공제하지 않더라도 구 상증세법 제45조의3 제1항의 위임범위나 한계를 벗어나 무효라고 볼 수 없다고 판단하였다.
      다. 원심판결 이유를 앞서 본 규정과 법리에 비추어 살펴보면, 원심의 이러한 판단에 상고이유 주장과 같이 2014년 개정 전 상증세법 시행령 제34조의2 제10항 제1호의 효력에 관한 법리 오해, 이유 모순 및 심리미진 등의 잘못이 없다.
   6. 구 상증세법 제45조의3에 따른 과세에 일감몰아주기를 통한 기여, 변칙증여의 의도 등 별도의 과세요건이 필요한지 여부(제2 상고이유)
      가. 원심은 그 판시와 같은 이유로 ‘이 사건 거래에는 일감몰아주기를 통한 기여가 존재하지 않고, 헬스케어가 셀트리온으로부터 의약품을 구매하지 않거나 거래처를 다변화할 것을 기대할 수 없었으며, 원고의 셀트리온에 대한 지분율이 헬스케어에 대한 지분율보다 낮아 변칙증여의 의도가 없었음이 명백하므로 구 상증세법 제45조의3 제1항에 따라 증여세를 과세할 수 없다.’는 원고의 주장을 배척하였다.
      나. 조세법률주의의 원칙과 구 상증세법 제45조의3의 문언 등에 비추어 위 규정에 의한 과세에 위 규정에서 정한 요건 외에 다른 요건이 추가로 필요하다고 보기는 어려운 점, 구 상증세법 제45조의3은 전형적인 일감몰아주기, 즉 특수관계법인이 수혜법인에 일방적으로 이익을 주는 거래만을 과세대상으로 규정하고 있지 않고, 다른 일부 증여의제 규정과 달리 주관적 의도 내지 목적을 과세요건으로 요구하지도 않는 점 등에 비추어 보면, 원심의 이러한 판단은 정당하고, 거기에 상고이유 주장과 같이 구 상증세법 제45조의3의 과세요건, 과세대상 및 적용법조에 관한 법리를 오해한 잘못이 없다.
   7. 2014년 개정 전 상증세법 시행령 제34조의2 제4항 등이 무효인지 여부(제4 상고이유)
      가. 원심은 구 상증세법 제45조의3 제1항이 정상거래비율을 대통령령으로 정하도록 위임하면서 수혜법인의 ‘업종 등’을 고려하도록 하였을 뿐 그 ‘업종 등’에 따라 정상거래비율을 달리 정하도록 강제하거나 업종 외의 다른 요소는 고려할 수 없도록 제한하고 있지 않다는 등 그 판시와 같은 이유로, 2014년 개정 전 상증세법 시행령 제34조의2 제4항이 정상거래비율을 ‘업종 등’에 관계없이 30%의 단일비율로 정하고, 2015년 개정 전 상증세법 시행령 제34조의2 제5항이 정상거래비율을 업종이 아닌 수혜법인의 규모에 따라 구분하여 정하였더라도 구 상증세법 제45조의3 제1항의 문언이나 입법 취지에 반하여 무효라고 볼 수 없다고 판단하였다.
      나. 원심판결 이유를 관련 규정과 법리에 비추어 살펴보면, 원심의 이러한 판단에 상고이유 주장과 같이 2014년 개정 전 상증세법 시행령 제34조의2 제4항 등의 효력에 관한 법리를 오해한 잘못이 없다.
   8. 결론
      그러므로 상고를 기각하고 상고비용은 패소자가 부담하도록 하여, 관여 대법관의 일치된 의견으로 주문과 같이 판결한다.`, style_class: "textareabox",
          },
        ],
        // metas_tr: [
        //   { poi_key: "판시사항", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "판결요지", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "참조조문", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "참조판례", poi_value: "", style_class: "textareabox", },
        //   { poi_key: "원고", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "피고", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "원심판결", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "주문", poi_value: "", style_class: "inputbox", },
        //   { poi_key: "이유", poi_value: "", style_class: "textareabox", },
        // ],
      },
      //       doc_basic1: `- 문서명: 대여금[지적장애를 가진 사람의 의사능력이 문제된 사건]
      // - 문서번호: 2019다213344
      // - 법원 종류: 대법원
      // - 일자: 2022. 5. 26.
      // - 법관: 대법관 노정희(재판장) 김재형(주심) 안철상 이흥구
      // `,
    };
  },
  components: {
    Header
  },
  methods: {
  },
  mounted: async function () {
    this.no = this.$route.params.no;
  }
};
</script>



<style>
.textareabox2 {
  border: 1px solid #d3d9dd;
  background-color: #f3f6f9;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 200px;
}
</style>