<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div style="float: right">
          <router-link :to="{ path: '/mypage/' }" class="btn bg2">목록으로</router-link>
        </div>
        <h2 class="h_title">비식별화 작업 할당</h2>
        <!-- <span style="color:blue"> 최종 트리플 수정이 완료된 POI만 할당할 수 있습니다.</span> -->

        <h2 class="h_title2 mgT50">비식별화 작업자</h2>

        <div class="btn_box mgT10">
          <router-link class="btn bg" :to="{ path: '/blur_day_table/' }">일자별 작업량 보기</router-link>&nbsp;
          &nbsp;
          <a v-if="my_mno == 1 || my_mno == 2" class="btn bg" @click="csv_download();">CSV로 내려받기</a>
        </div>
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="6%" />
              <col width="33%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">선택</th>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">소속</th>
                <th scope="col">할당 관리</th>
                <th scope="col">할당량</th>
                <th scope="col">수정전</th>
                <th scope="col">수정완료</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="5">
                  지역타입별 할당된 수량 보기 →
                  <select id="request_type" v-model="now_type">
                    <option value="" selected disabled>선택</option>
                    <option v-for="t in request_type_list" :key="t" :value="t">
                      {{ t }}</option>
                  </select>
                  &nbsp;
                  <select id="request_region" v-model="now_region">
                    <option value="" selected disabled>선택</option>
                    <option v-for="r in request_region_list" :key="r" :value="r">
                      {{ r }}</option>
                  </select>
                  &nbsp;
                  <a @click="assign_num_check()" class="btn size1">보기</a>&nbsp;&nbsp;
                </td>
                <th>할당가능 : {{ assign_num }}</th>
                <th>{{ total_assign }}</th>
                <th>{{ total_not_work }}</th>
                <th>{{ total_work }}</th>
              </tr>
              <tr v-if="member_list.length == 0">
                <td colspan="11">
                  <span>배정된 작업자가 없습니다.</span>
                </td>
              </tr>
              <tr v-for="(m, index) in member_list" :key="m.no">
                <td>
                  <input type="checkbox" />
                </td>
                <td>{{ member_numbering(index) }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>
                <td>{{ m.class_name }}</td>
                <td>
                  <select v-if="region_type_list.length > 0" v-bind:id="'selected_type' + m.no">
                    <option :value="['전체', '전체', assign_num]" selected>전체({{ assign_num }})</option>
                    <option v-for="rt in region_type_list" :key="rt" :value="[rt.poi_type, rt.poi_region, rt.cnt]">
                      {{ rt.poi_type }}/{{ rt.poi_region }}({{ rt.cnt }})</option>
                  </select>
                  &nbsp;
                  <input type="number" v-bind:id="'member' + m.no" />&nbsp;&nbsp;&nbsp;
                  <template v-if="(assigned == 0 && canceled == 0)">
                    <a @click="assign(m.no)" class="btn size1">할당하기</a>&nbsp;&nbsp;
                    <a @click="assign_cancel(m.no)" class="btn size1 bg3">할당취소</a>
                  </template>
                  <span v-else-if="assigned == 1">할당중입니다...</span>
                  <span v-else-if="canceled == 1">취소중입니다...</span>
                </td>
                <td>{{ m.assign_num }}</td>
                <td>{{ m.not_work_num }}</td>
                <td>{{ m.work_num }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="5"></th>
                <th>합계</th>
                <th>{{ total_assign }}</th>
                <th>{{ total_not_work }}</th>
                <th>{{ total_work }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- //table_type -->

        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
          &nbsp;
          <!-- <span>페이지 이동 후 약 5초 정도의 로딩시간이 있습니다.</span> -->
        </div>
      </div>
      <!-- //contents -->
    </div>
</div>
</template>

<script>


import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "BlurAssign",
  data: function () {
    return {
      member_list: [],
      region_type_list: [],
      assign_num: 0,
      total_assign: 0,
      total_not_work: 0,
      total_work: 0,

      worker_nums: 1,
      page: 1,
      page_total: 1,
      limit: 20,

      my_mno: 0,

      request_region_list: [
        "서울", "인천", "경기", "제주",
        "강원", "경북", "대구", "울산", "경남", "부산",
        "충북", "충남", "대전", "전북", "광주", "전남",
        "기타"
      ],
      request_type_list: ["관광지", "레포츠", "문화시설", "쇼핑", "숙박", "음식점", "편의오락"],
      now_region: "",
      now_type: "",

      assigned: 0,
      canceled: 0,
    };
  },
  components: {
    Header,
  },
  methods: {
    paging(count, to = false) {
      // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.member_list_load();
    },
    member_list_load() {
      axios({
        url: config.apiurl + "?part=blur&mode=blur_assign_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          page: this.page,
          limit: this.limit,
        },
      })
        .then((response) => {
          var d = response.data;
          console.log(d);
          this.member_list = d.list;
          this.assign_num = d.assign_num;
          this.total_assign = d.total_assign;
          this.total_not_work = d.total_not_work;
          this.total_work = d.total_work;
          this.worker_nums = d.worker_nums;
          this.page_total = d.page_total;
          this.region_type_list = d.region_type_list;
          this.my_mno = d.my_mno;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    member_numbering(num) {
      // 작업자 목록 번호 세팅
      if (this.page < 1) {
        return this.worker_nums - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.worker_nums - (this.page - 1) * this.limit - num;
      } else {
        return this.worker_nums - (this.page_total - 1) * this.limit - num;
      }
    },
    assign(mno) {
      // 할당하기
      var numid = document.getElementById("member" + mno.toString());
      var num = Number(numid.value);

      var region = ''; var type = ''; var anum = '';
      var rtid = document.getElementById("selected_type" + mno.toString());
      rtid = rtid.value.split(",");

      type = rtid[0];
      region = rtid[1];
      anum = rtid[2];

      if (num >= 1 && num <= anum) {
        this.assigned = 1;
        axios({
          url: config.apiurl + "?part=blur&mode=blur_assign",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            mno: mno,
            num: num,
            type: type,
            region: region,
          },
        }).then((response) => {
          console.log(response.data);
          alert(num + "개가 할당되었습니다.");
          this.assigned = 0;
          this.member_list_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        });
      } else {
        alert("1개부터 " + anum + "개까지 할당할 수 있습니다.");
      }
    },
    assign_cancel(mno) {
      // 할당하기
      var numid = document.getElementById("member" + mno.toString());
      var num = Number(numid.value);

      var region = ''; var type = '';
      var rtid = document.getElementById("selected_type" + mno.toString());
      rtid = rtid.value.split(",");

      type = rtid[0];
      region = rtid[1];

      // 할당취소
      if (num < 1) {
        alert("취소할 수량을 1 이상 입력해주세요.");
      } else {
        if (confirm(`${type}, ${region}, ${num}개 만큼 할당 취소하시겠습니까? 이 작업은 돌이킬 수 없습니다`)) {
          this.canceled = 1;
          axios({
            url: config.apiurl + "?part=blur&mode=blur_assign_cancel",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              mno: mno,
              num: num,
              type: type,
              region: region,
            },
          }).then((response) => {
            let d = response.data;
            // console.log(d);
            this.canceled = 0;
            alert(d.message);
            this.member_list_load();
          }).catch(function (error) {
            alert("오류가 발생했습니다...(4)");
            console.log("에러야!");
            console.log(error);
          });
        }
      }
    },

    csv_download() {
      if (confirm("비식별화작업완료_지역타입별 csv\n위 파일이 다운로드됩니다. 서버상태에 따라 시간이 걸릴 수 있습니다.")) {
        axios({
          // 비식별화작업완료_지역타입별 csv
          url: config.apiurl + "?part=util&mode=csv_download5",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
          },
        }).then((response) => {
          let d = response.data;
          console.log(d)

          let csvContent = "data:text/csv;charset=utf-8," + "\ufeff" + d.result_data.map(e => e.join(",")).join("\n");
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", d.file_name);
          document.body.appendChild(link); // Required for FF

          link.click(); // This will download the data file named "my_data.csv".
        }).catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
    assign_num_check() {
      console.log(this.now_region, this.now_type);
      axios({
        // 비식별화 작업전 할당된수량
        url: config.apiurl + "?part=blur&mode=blur_request_assign_num",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          region: this.now_region,
          type: this.now_type,
        },
      }).then((response) => {
        let d = response.data;
        // console.log(d)

        let csvContent = "data:text/csv;charset=utf-8," + "\ufeff" + d.result_data.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", d.file_name);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    }
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 83);
    this.member_list_load();
  },
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>