<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title">국문</h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="30%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_ko" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div v-if="is_start('주소', meta.poi_key)" style="width:100%">
                        <textarea class="textareabox" style="height: 72px;" v-model="meta.poi_value"
                          readonly></textarea>
                      </div>
                      <div v-else style="width:100%"><input class="inputbox" v-model="meta.poi_value" readonly>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
          <div class="list_box">
            <h2 class="h_title">
              <template v-if="language == 'en'">영문</template>
              <template v-else-if="language == 'cht'">중국어(번체)</template>
              <template v-else-if="language == 'chs'">중국어(간체)</template>
              <template v-else-if="language == 'ja'">일본어</template>
              <template v-else>번역언어</template>
            </h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="30%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_tr" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div
                        v-if="is_start('Address', meta.poi_key) || is_start('地址', meta.poi_key) || is_start('住所', meta.poi_key)"
                        style="width:100%">
                        <textarea class="textareabox" style="height:72px;" v-model="meta.poi_value"></textarea>
                      </div>
                      <div v-else style="width:100%">
                        <input class="inputbox" v-model="meta.poi_value">
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <!-- <a @click="submit()" class="btn bg2">제출</a> -->
          <router-link :to="{ path: '/trans_viewer_list' }" class="btn bg2">목록</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'TransViewerView',
  data() {
    return {
      metas_info: {},
      translate: "",
      language: "",
      comment: "",
      confirm: 1,
      role: 0,
      checked: 0,
    };
  },
  components: {
    Header
  },
  methods: {
    is_combine(s, str) { // 단어 포함 체크
      if (str.includes(s)) return true;
      else return false;
    },
    is_start(s, str) { // 단어 시작 체크
      return str.startsWith(s);
    },
    trans_load() { // 번역 정보 불러오기
      axios({
        url: config.apiurl + "?part=trans&mode=trans_viewer_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          tno: this.$route.params.tno,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d);
        this.metas_info = d;
        this.translate = d.translate;
        this.language = d.language;
        this.comment = d.comment;
        this.role = d.role;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 62);
    this.trans_load();
  }
};
</script>