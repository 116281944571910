<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">Q&A 작업현황</h2>
        <!-- <router-link :to="{ path: '/image_day_table/' }" class="btn bg">
          워커별 작업량 보기
        </router-link> -->
        <div class="btn_box mgT10">
          <a v-for="t in type_filter" :key="t" v-bind:class="{ 'btn bg4': t.value, 'btn bg': !t.value }"
            style="margin-right:8px;" @click="toggle_type(t.type)">
            {{ t.type }}
          </a>&nbsp;
          <span v-if="loading">로딩중입니다...</span>
        </div>
        <!-- //table_type -->
        <div class="table_type6 mgT10">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col width="9%" />
              <col width="9%" />
            </colgroup>

            <thead>
              <tr>
                <th scope="col">권역 구분</th>
                <th scope="col">지역명</th>
                <th scope="col">플랫폼 상 주소 표기</th>
                <th scope="col">질문승인</th>
                <th scope="col">답변승인</th>
              </tr>
            </thead>

            <tbody>
              <template v-if="region_data.length == 0">
                <tr>
                  <td colspan="8" style="text-align: center;"></td>
                </tr>
              </template>
              <template v-else>
                <template v-for="r1 in region_data" :key="r1">
                  <tr v-for="(r2, index) in r1.data" :key="r2">
                    <th v-if="index == 0" :rowspan="r1.data.length">{{ r1.area }}</th>
                    <th>{{ r2.region_name }}</th>
                    <th>{{ r2.region_mark }}</th>
                    <td class="txR">{{ r2.nums.poi_count1 }}</td>
                    <td class="txR">{{ r2.nums.poi_count2 }}</td>
                  </tr>
                </template>
              </template>
            </tbody>

          </table>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "QnAStatus",
  data: function () {
    return {
      region_data: [],
      type_filter: [
        { type: "전체", value: true },
        { type: "관광지", value: false },
        { type: "문화시설", value: false },
        { type: "쇼핑", value: false },
        { type: "레포츠", value: false },
        { type: "편의오락", value: false },
        { type: "음식점", value: false },
        { type: "숙박", value: false },
      ],
      now_type: "전체",
      loading: false,
    };
  },
  components: {
    Header
  },
  methods: {
    toggle_type(t) {
      this.type_filter.forEach(filter => {
        filter.value = false;
      });
      this.type_filter.forEach(filter => {
        if (filter.type == t) {
          filter.value = true;
          this.now_type = t;
        }
      });

      this.region_status_load();
    },
    region_status_load() {
      this.loading = true;
      axios({
        url: config.apiurl + "?part=util&mode=qna_status",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          poi_type: this.now_type,
        },
      }).then((response) => {
        this.loading = false;
        let d = response.data;
        console.log(d)
        this.region_data = d.region_data;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 51);
    this.region_status_load();
  },
};
</script>
