<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div style="float: right">
          <router-link :to="{ path: '/mypage/' }" class="btn bg2">목록으로</router-link>
        </div>
        <h2 class="h_title">영어 번역 할당</h2>

        <h2 class="h_title2 mgT50">영어 번역 작업자</h2>

        <div class="btn_box mgT10">
          <router-link class="btn bg" :to="{ path: '/m_trans_assign_day_table/' }">일자별 작업량 보기</router-link>&nbsp;
        </div>
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="30%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">선택</th>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">소속</th>
                <th scope="col">할당 관리</th>
                <th scope="col">할당량</th>
                <th scope="col">수정중</th>
                <th scope="col">수정완료</th>
                <th scope="col">검수완료</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="5"></td>
                <th>할당가능 : {{ assign_num }}</th>
                <th>{{ total_assign }}</th>
                <th>{{ total_not_work }}</th>
                <th>{{ total_work }}</th>
                <th>{{ total_request }}</th>
              </tr>
              <tr v-if="member_list.length == 0">
                <td colspan="11">
                  <span>배정된 작업자가 없습니다.</span>
                </td>
              </tr>
              <tr v-for="(m, index) in member_list" :key="m.no">
                <td>
                  <input type="checkbox" />
                </td>
                <td>{{ member_numbering(index) }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>
                <td>{{ m.class_name }}</td>
                <td>
                  <input type="number" v-bind:id="'member' + m.no" />&nbsp;&nbsp;&nbsp;
                  <a @click="assign(m.no)" class="btn size1">할당하기</a>&nbsp;&nbsp;
                  <a @click="assign_cancel(m.no)" class="btn size1 bg3">할당취소</a>
                </td>
                <td>{{ m.assign_num }}</td>
                <td>{{ m.not_work_num }}</td>
                <td>{{ m.work_num }}</td>
                <td>{{ m.request_num }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="5"></th>
                <th>합계</th>
                <th>{{ total_assign }}</th>
                <th>{{ total_not_work }}</th>
                <th>{{ total_work }}</th>
                <th>{{ total_request }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- //table_type -->

        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
          <!-- &nbsp;
          <span>페이지 이동 후 약 5초 정도의 로딩시간이 있습니다.</span> -->
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Header from "../../components/Header.vue";
// import common from "../common/common.js";
// import config from "../common/config.js";

export default {
  name: "MTransAssignList",
  data: function () {
    return {
      member_list: [
        {
          no: "1",
          userid: "trans01",
          username: "영어번역자1",
          class_name: "영어번역1",
          assign_num: "100",
          not_work_num: "20",
          work_num: "50",
          request_num: "30"
        },
        {
          no: "2",
          userid: "trans02",
          username: "영어번역자2",
          class_name: "영어번역1",
          assign_num: "200",
          not_work_num: "40",
          work_num: "100",
          request_num: "60"
        }
      ],
      assign_num: 1500,
      total_assign: 300,
      total_not_work: 60,
      total_work: 150,
      total_request: 90,

      worker_nums: 1,
      page: 1,
      page_total: 1,
      limit: 20,
    };
  },
  components: {
    Header,
  },
  methods: {
    // paging(count, to = false) {
    //   // 리스트 페이징
    //   var c = 1;
    //   if (to) {
    //     c = Number(count.target.value);
    //   } else {
    //     c = this.page + count;
    //   }

    //   if (c >= 1 && c <= this.page_total) {
    //     this.page = c;
    //   }
    //   this.member_list_load();
    // },
    // member_list_load() {
    //   axios({
    //     url: config.apiurl + "?part=poi&mode=poi_assign_list",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //     method: "POST",
    //     data: {
    //       token: this.$store.state.token,
    //       page: this.page,
    //       limit: this.limit,
    //     },
    //   })
    //     .then((response) => {
    //       var d = response.data;
    //       console.log(d);
    //       this.member_list = d.list;
    //       this.assign_num = d.assign_num;
    //       this.total_assign = d.total_assign;
    //       this.total_not_work = d.total_not_work;
    //       this.total_work = d.total_work;
    //       this.total_request = d.total_request;
    //       this.total_super_request = d.total_super_request;
    //       this.worker_nums = d.worker_nums;
    //       this.page_total = d.page_total;
    //     })
    //     .catch(function (error) {
    //       alert("오류가 발생했습니다...(1)");
    //       console.log("에러야!");
    //       console.log(error);
    //     });
    // },
    member_numbering(num) {
      // 작업자 목록 번호 세팅
      if (this.page < 1) {
        return this.worker_nums - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.worker_nums - (this.page - 1) * this.limit - num;
      } else {
        return this.worker_nums - (this.page_total - 1) * this.limit - num;
      }
    },
    // assign(mno) {
    //   // 할당하기
    //   var numid = document.getElementById("member" + mno.toString());
    //   var num = Number(numid.value);

    //   if (num >= 1 && num <= this.assign_num) {
    //     axios({
    //       url: config.apiurl + "?part=poi&mode=poi_assign",
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //       method: "POST",
    //       data: {
    //         token: this.$store.state.token,
    //         mno: mno,
    //         num: num,
    //       },
    //     }).then((response) => {
    //       console.log(response.data);
    //       alert(num + "개가 할당되었습니다.");
    //       this.member_list_load();
    //     }).catch(function (error) {
    //       alert("오류가 발생했습니다...(3)");
    //       console.log("에러야!");
    //       console.log(error);
    //     });
    //   } else {
    //     alert("1개부터 " + this.assign_num + "개까지 할당할 수 있습니다.");
    //   }
    // },
    // assign_cancel(mno) {
    //   // 할당취소
    //   if (confirm("정말로 할당된 작업을 반환하시겠습니까? 이 작업은 돌이킬 수 없습니다")) {
    //     axios({
    //       url: config.apiurl + "?part=poi&mode=poi_assign_cancel",
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //       method: "POST",
    //       data: {
    //         token: this.$store.state.token,
    //         mno: mno,
    //       },
    //     }).then((response) => {
    //       let d = response.data;
    //       console.log(d);
    //       alert(d.cancel_count + "개의 작업물이 반환되었습니다.");
    //       this.member_list_load();
    //     }).catch(function (error) {
    //       alert("오류가 발생했습니다...(4)");
    //       console.log("에러야!");
    //       console.log(error);
    //     });
    //   }
    // },
  },
  mounted: async function () {
    // await common.check_perm(this.$store.state.token, 12);
    // this.member_list_load();
  },
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>