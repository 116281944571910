<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          <router-link :to="{ path: '/redeem_list/' }">POI 데이터보완 목록</router-link>
        </h2>
        <span style="color:blue;">
          비고 : 가급적 PC에서의 작업을 권장합니다.
          <br>
          만약 바운딩박스 정보가 보이지 않거나 로딩이 안된 것 같다면 [가져오기] 버튼을 눌러주세요.
        </span>
        <!-- table_type -->
        <!-- <div class="table_type1 mgT30">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <th scope="col">이미지</th>
              <th scope="col">이미지1</th>
              <th scope="col">이미지2</th>
              <th scope="col">이미지3</th>
              <th scope="col">이미지4</th>
              <th scope="col">이미지5</th>
            </thead>
            <tbody>
              <tr>
                <th>이동</th>
                <th v-for="(i, index) in img_list" :key="i">
                  <a v-if="i.blur_state == 0" @click="page(index + 1, true)" class="btn line">작업 전</a>
                  <a v-else-if="i.blur_state == 1" @click="page(index + 1, true)" class="btn line2">작업 완료</a>
                </th>
              </tr>
            </tbody>
          </table>
        </div> -->
        <!-- //table_type -->

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <router-link :to="{ path: '/redeem_list/' }" class="btn bg">목록으로</router-link>
        </div>
        <div class="fx-5">

          <div class="list_box">
            <h2 class="h_title">이미지{{ img_page }} 비식별화 작업</h2>
            <div class="qna_img_box">
              <div class="img_wrapper">
                <div class="img_wrap" style="height:100%;">
                  <img :id="'imgsrc' + img_page" v-on:click="add_box($event)" style="height:100%; box-sizing: border-box;"
                    v-if="img_list.length > 0" :src="'http://' + img_list[img_page - 1].img_url" alt="" />
                  <!-- <div v-html="drawing_boxs"></div> -->
                  <div v-if="img_list.length > 0">
                    <div class="rect" v-for="rect in img_list[img_page - 1].blur_info" :key="rect" :style="{
                      top: get_rect_top(rect.top),
                      left: get_rect_left(rect.left),
                      width: get_rect_width(rect.width),
                      height: get_rect_height(rect.height)
                    }"></div>
                    <!-- <div v-for="rect in rects" :key="rect" :style="`display: block; top: ${get_rect_top(rect.top)}`">{{ rect.top }}</div> -->
                  </div>
                </div>
              </div>

              <div class="btn_box">
                <a @click="page(-1)" class="prev">
                  <i class="fa-solid fa-circle-chevron-left"></i>
                </a>
                <a @click="page(1)" class="next">
                  <i class="fa-solid fa-circle-chevron-right"></i>
                </a>
              </div>
              <div class="tool_box mgT20">
                <input type="file" accept="image/*;capture=camera" @change="image_submit(img_page)" name="imgfile"
                  :id="'imgfile' + (img_page)" style="display:none" />
                <label :for="'imgfile' + (img_page)" class="btn size1"> 이미지업로드{{ img_page }} </label>
                &nbsp;
                <a class="btn size1" @click="position_reset()">바운딩박스 초기화</a>
                &nbsp;
                <a class="btn size1" @click="position_undo()">Undo</a>
              </div>
            </div>
            <!-- table_type -->

            <div class="table_type2">
              <table>
                <colgroup>
                  <col width="8%" />
                  <col />
                  <col width="12%" />
                </colgroup>
                <tbody v-if="img_list.length > 0">
                  <tr>
                    <th>이미지{{ img_page }} 원본 해상도
                      <a class="btn size1" @click="size_load();">가져오기</a>
                    </th>
                    <td colspan="2">
                      {{ nWidth }} x {{ nHeight }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <th>캡션1</th>
                    <td colspan="2">
                      <input class="inputbox" id="caption1" v-model="img_list[img_page - 1].caption1" />
                    </td>
                  </tr>
                  <tr>
                    <th>캡션2</th>
                    <td colspan="2">
                      <textarea class="textareabox" id="caption2" v-model="img_list[img_page - 1].caption2"></textarea>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>

            <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
              <a @click="submit()" class="btn bg2">작업 등록</a>
            </div>
          </div>
        </div>

        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title">POI정보</h2>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr v-for="info in poi_info" :key="info">
                    <th scope="row">{{ info[0] }}</th>
                    <td style="word-break: break-all">{{ info[1] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
import ExifReader from 'exifreader';
// import QA_Reject from "../components/QA_Reject.vue";

export default {
  name: "RedeemView2",
  data() {
    return {
      endpoint: "kvqa.crowdbank.co.kr",
      poi_info: "",
      img_list: [],
      img_page: 1,
      current_image: "",
      is_submit: false,
      can_request: 0,

      blur_info: [],
      temp_box: [],

      cWidth: 0,
      cHeight: 0,

      nWidth: 0,
      nHeight: 0,
    };
  },
  components: {
    Header,
    // QA_Reject
  },
  computed: {
  },
  methods: {
    size_load() {
      return new Promise((resolv) => {
        let imgsrc = document.getElementById(`imgsrc` + this.img_page);
        if (imgsrc != null) {
          this.cWidth = imgsrc.clientWidth;
          this.cHeight = imgsrc.clientHeight;
          this.nWidth = imgsrc.naturalWidth;
          this.nHeight = imgsrc.naturalHeight;
        }
        resolv(1)
      })
    },
    get_rect_left(x) {
      this.size_load();
      return Number(x) / 100 * this.cWidth + "px";
    },
    get_rect_top(x) {
      this.size_load();
      return Number(x) / 100 * this.cHeight + "px";
    },
    get_rect_width(x) {
      this.size_load();
      return Number(x) / 100 * this.cWidth + "px";
    },
    get_rect_height(x) {
      this.size_load();
      return Number(x) / 100 * this.cHeight + "px";
    },
    drawing_boxs() {
      if (this.img_list.length > 0) {
        if (this.img_list[this.img_page - 1].blur_info.length > 0) {
          let imgsrc = document.getElementById(`imgsrc` + this.img_page);
          if (imgsrc != null) {
            this.cWidth = imgsrc.clientWidth;
            this.cHeight = imgsrc.clientHeight;
            this.nWidth = imgsrc.naturalWidth;
            this.nHeight = imgsrc.naturalHeight;
          }
        }
      }
    },
    position_undo() { // 초기화
      this.temp_box = [];
      this.img_list[this.img_page - 1].blur_info.pop();
    },
    position_reset() { // undo
      this.temp_box = [];
      this.img_list[this.img_page - 1].blur_info = [];
    },
    add_box(e) { // 이벤트 정보 얻기
      let x = e.offsetX;
      let y = e.offsetY;

      let imgsrc = document.getElementById(`imgsrc` + this.img_page);
      let cWidth = imgsrc.clientWidth;
      let cHeight = imgsrc.clientHeight;

      // 소수점 둘째자리까지 자르고 삽입
      let xy = { x: (x / cWidth * 100).toFixed(2), y: (y / cHeight * 100).toFixed(2) };
      this.temp_box.push(xy);

      if (this.temp_box.length == 2) {
        let width = this.temp_box[1].x - this.temp_box[0].x;
        let height = this.temp_box[1].y - this.temp_box[0].y;
        let left = Number(this.temp_box[0].x);
        let top = Number(this.temp_box[0].y);

        this.img_list[this.img_page - 1].blur_info.push({ width: width.toFixed(2), height: height.toFixed(2), left: left.toFixed(2), top: top.toFixed(2) });

        this.temp_box = [];
      }

      // console.log(this.img_list[this.img_page - 1].blur_info);
    },
    all_load() {
      // 전체 정보 불러오기 : poi, image, qna
      axios({
        url: config.apiurl + "?part=redeem&mode=redeem_view2",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          no: this.$route.params.no,
        },
      })
        .then((response) => {
          let d = response.data;
          console.log(d);
          this.is_edit = d.is_edit;
          this.poi_info = d.poi_info;
          this.img_list = d.image_list;
          this.can_request = d.can_request;
          setTimeout(() => {
            this.drawing_boxs();
          }, 500);
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    page(count, to = false) {
      // 이미지 이동, to를 주면 바로 감
      if (to) {
        this.img_page = count;
      } else {
        var c = this.img_page + count;
        if (c <= 1) {
          c = 1;
        } else if (c >= 5) {
          c = 5;
        }

        this.img_page = c;

        this.drawing_boxs();
      }
    },
    submit() {
      // 답변 등록
      let img_no = this.img_list[this.img_page - 1].no;
      let blur_info = this.img_list[this.img_page - 1].blur_info;

      if (confirm("현재 이미지의 비식별화 작업 내용을 등록하시겠습니까?")) {
        axios({
          url: config.apiurl + "?part=redeem&mode=redeem_edit2",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            img_no: img_no,
            blur_info: blur_info,
            no: this.$route.params.no,
          },
        })
          .then((response) => {
            let d = response.data;
            console.log(d);

            this.all_load();
            if (this.img_page < 5) {
              this.page(1);
            }
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(2)");
            console.log("에러야!");
            console.log(error);
          });
      }
    },
    get_exif(img) { // 사진 gps정보 받기
      return new Promise((resolv) => {
        (async () => {
          try {
            const tags = await ExifReader.load(img);
            console.log(tags['Image Height'].value);
            console.log(tags['Image Width'].value);
            if (!Object.prototype.hasOwnProperty.call(tags, 'GPSLatitude') && !Object.prototype.hasOwnProperty.call(tags, 'GPSLatitude')) {
              resolv({ lat: 0, lng: 0, height: tags['Image Height'].value, width: tags['Image Width'].value })
            } else {
              resolv({
                lat: tags.GPSLatitude.description, lng: tags.GPSLongitude.description,
                height: tags['Image Height'].value, width: tags['Image Width'].value
              })
            }
          } catch (e) {
            console.log(e);
            resolv({ lat: 0, lng: 0, height: 0, width: 0 })
          }
        })();
      });
    },
    image_submit(num) { // 이미지업로드
      var file = document.querySelector('#imgfile' + num);

      var reader = new FileReader();
      let base64conv = "";
      reader.readAsDataURL(file.files[0]);
      reader.onload = async () => {
        var img = new Image();
        img.src = reader.result;

        let img_info = await this.get_exif(img.src); // 사진 gps 및 해상도 정보 받기

        if (img_info.width < 3840 || img_info.height < 2160) {
          alert("4k 이상의 이미지만 업로드할 수 있습니다.");
        } else {
          base64conv = reader.result;

          var filePath = file.value;
          var filePathSplit = filePath.split('\\');
          var fileName = filePathSplit.slice(-1)[0];

          this.image_uplaod(fileName, base64conv, { lat: img_info['lat'], lng: img_info['lng'] }, num);
        }
      }
    },
    image_uplaod(filename, base, latlng, num) { // 이미지 서버 업로드
      axios({
        url: config.apiurl + "?part=image&mode=image_upload",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          ino: this.img_list[num - 1].ino,
          filename: filename,
          base: base,
          endpoint: this.endpoint,
          itemno: this.img_list[num - 1].no,
          latlng: latlng,
        },
      }).then((response) => {
        console.log(response)
        this.all_load();
      }).catch(function (error) {
        alert("오류가 발생했습니다...(2)");
        console.log("에러야!");
        console.log(error);
      });
    },


  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 17);
    this.all_load();
  },
};
</script>


<style>
.img_wrapper {
  height: 640px;
  box-sizing: border-box;
  position: relative;
}

.img_wrap {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box
}

.rect {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  border: 2px red solid;
}
</style>