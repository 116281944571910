<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <div style="width:500px; display: flex; justify-content: space-between">
          <p class="h_title">POI 번호 : {{ poi_no }}</p>
        </div>

        <h3 class="h_title">오류내용</h3>
        <textarea class="textareabox" v-model="comments" readonly></textarea>

        <div class="fx-5 mgT30">
          <div class="list_box">
            <div></div>
            <h2 class="h_title">국문</h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="30%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_ko" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div v-if="is_start('주소', meta.poi_key)" style="width:100%">
                        <textarea class="textareabox" style="height: 72px;" v-model="meta.poi_value"></textarea>
                      </div>
                      <div v-else style="width:100%"><input class="inputbox" v-model="meta.poi_value">
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
          <div class="list_box">
            <h2 class="h_title">
              <template v-if="language == 'en'">영문</template>
              <template v-else-if="language == 'cht'">중국어(번체)</template>
              <template v-else-if="language == 'chs'">중국어(간체)</template>
              <template v-else-if="language == 'ja'">일본어</template>
              <template v-else>번역언어</template>
            </h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="30%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_tr" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div
                        v-if="is_start('Address', meta.poi_key) || is_start('地址', meta.poi_key) || is_start('住所', meta.poi_key)"
                        style="width:100%">
                        <textarea class="textareabox" :class="language == 'ja' ? 'ja_font' : ''" style="height:72px;"
                          v-model="meta.poi_value"></textarea>
                      </div>
                      <div v-else style="width:100%">
                        <input class="inputbox" :class="language == 'ja' ? 'ja_font' : ''" v-model="meta.poi_value">
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <template v-if="checked == 0"> <a class="btn bg" @click="submit()">수정</a></template>
          <template v-else> <span>등록중입니다...</span></template>
          <router-link :to="{ path: '/redeem2_list/' }" class="btn bg2">목록</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'Redeem2View',
  data() {
    return {
      metas_info: {},
      language: "",
      poi_no: 0,
      comments: [],
      checked: 0,
    };
  },
  components: {
    Header
  },
  methods: {
    is_start(s, str) { // 단어 시작 체크
      return str.startsWith(s);
      // if (str.startsWith(s)) return true;
      // else return false;
    },
    trans_load() { // 번역 정보 불러오기
      axios({
        url: config.apiurl + "?part=redeem2&mode=redeem2_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          r2no: this.$route.params.no,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d);
        this.metas_info = d;
        this.language = d.language;
        this.poi_no = d.poi_no;
        this.comments = d.comments;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    submit() { // 번역완료
      if (confirm("수정하시겠습니까?")) {
        this.checked = 1;
        axios({
          url: config.apiurl + "?part=redeem2&mode=redeem2_edit",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            metas_ko: this.metas_info.metas_ko,
            metas_tr: this.metas_info.metas_tr,
            r2no: this.$route.params.no,
          },
        }).then((response) => {
          console.log(response.data);
          if (response.data.result == 1) {
            this.$router.push({
              name: "Redeem2List",
            });
          } else {
            alert("수정 업데이트에 실패했습니다... 관리자에게 문의해주세요.");
          }
        }).catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, [451]);
    this.trans_load();
  }
};
</script>



<style>
.ja_font {
  font-family: "Yu Gothic";
  font-weight: 500;
}
</style>